import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { portfolioApiEvents } from 'modules/api/portfolio'
import { $TSFixMe } from 'types/ts-migrate'

type ChatbotPortfolioState = {
  currentPortfolioId: string | null
  savedDialogs: {
    [key: string]: $TSFixMe
  }
  activationConfirmationOpen: boolean
}

const { actions: chatbotEvents, reducer: chatbotPortfolioReducer } = createSlice({
  name: 'chatbot/portfolio',
  initialState: {
    currentPortfolioId: null,
    savedDialogs: {},
    activationConfirmationOpen: false,
  } as ChatbotPortfolioState,
  reducers: {
    dialogEvent: (state, action: PayloadAction<{ dialog: string; event: PayloadAction<any> }>) => {},
    portfolioRedirect: (state, { payload }: PayloadAction<string>) => {},
    setGlobalVat: (state, { payload }: PayloadAction<boolean>) => {},
    setCurrentPortfolio: (state, { payload }: PayloadAction<$TSFixMe>) => {
      state.currentPortfolioId = payload.id
    },
    resetCurrentPortfolio: state => {
      state.currentPortfolioId = null
    },
    captureDialogState: (state, { payload }: PayloadAction<{ dialog: string; dialogState: $TSFixMe }>) => {
      state.savedDialogs[payload.dialog] = payload.dialogState
    },
    dialogStateChanged: (state, { payload }: PayloadAction<{ dialog: string; changes: $TSFixMe }>) => {},
    newPortfolioRequested: state => {},
    loadPortfolioRequested: (state, { payload }: PayloadAction<string>) => {},
    propertyConfirmationRequested: (state, action: PayloadAction<$TSFixMe>) => {},
    termValuesSubmitted: (state, action: PayloadAction<$TSFixMe>) => {},
    rentValuesSubmitted: (state, action: PayloadAction<$TSFixMe>) => {},
    settingsValuesSubmitted: (state, action: PayloadAction<$TSFixMe>) => {},
    commissionValuesSubmitted: (state, action: PayloadAction<$TSFixMe>) => {},
    invoiceValuesSubmitted: (state, action: PayloadAction<$TSFixMe>) => {},
    currentPortfolioActivationConfirmationTriggered: state => {
      state.activationConfirmationOpen = true
    },
    currentPortfolioActivationDeclined: state => {
      state.activationConfirmationOpen = false
    },
    currentPortfolioActivationRequested: () => {},

    /**
     * Onboarding
     * @todo migrate to feature module
     */
    agencyDetailsSubmitted: (state, action: PayloadAction<$TSFixMe>) => {},
    brandingDetailsSubmitted: (state, action: PayloadAction<$TSFixMe>) => {},
  },
  extraReducers: {
    // [portfolioApiEvents.approve_request]: state => {
    /** @todo remove hard coded event. It's causing some weird testing issues */
    'portfolioApi/approve_request': state => {
      state.activationConfirmationOpen = false
    },
  },
})

export { chatbotPortfolioReducer, chatbotEvents }
