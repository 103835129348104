import { createSelector } from 'reselect'
import { find, get } from 'lodash-es'
import { state, getPropertyDetailsDialog, getCurrentPortfolioId } from '../selectors'
import { propertyApiSelectors } from '../../../api/property'
import { createChatbotDialog } from './ChatbotDialog'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../api/portfolio'
import { chatbotEvents } from '../state'
import { searchPlaceDetails } from 'modules/api/property/state'
import { titleCase } from 'utils/string'

const initialState = {
  selectedProperty: null,
  selectedPlace: null,
  selectedLightstoneProperty: null,
  propertyDescription: '',
  buildingName: '',
  unitNumber: '',
  schemeNumber: '',
  schemeName: '',
}

export const { propertyDetailsDialog } = createChatbotDialog('propertyDetails')
  .withInitialState(initialState)
  .withEvents({
    googlePlaceSelected: (placeId, propertyDescription, buildingName, unitNumber, schemeNumber, schemeName) => ({
      placeId,
      propertyDescription,
      buildingName,
      unitNumber,
      schemeNumber,
      schemeName,
    }),
    propertySelected: (id, propertyDescription, buildingName, unitNumber, schemeNumber, schemeName) => ({
      id,
      propertyDescription,
      buildingName,
      unitNumber,
      schemeNumber,
      schemeName,
    }),
    lightstonePropertySelected: (
      lightstoneId,
      propertyDescription,
      buildingName,
      unitNumber,
      schemeNumber,
      schemeName,
    ) => ({
      lightstoneId,
      propertyDescription,
      buildingName,
      unitNumber,
      schemeNumber,
      schemeName,
    }),
    clearSelection: () => ({}),
  })
  .reduce({
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.amendProperty_success]: ({
      payload: { propertyId, buildingName, unitNumber, schemeNumber, schemeName },
    }: any) => ({
      $merge: {
        selectedProperty: propertyId,
        selectedPlace: null,
        buildingName,
        unitNumber,
        schemeNumber,
        schemeName,
      },
    }),
    // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [portfolioApiEvents.amendMetaData_success]: ({ payload: { propertyDescription } }: any) => ({
      $merge: {
        propertyDescription,
      },
    }),
    // @ts-expect-error
    [chatbotEvents.setCurrentPortfolio]: ({ payload }: any) => ({
      $merge: {
        selectedProperty: get(payload, 'propertyId', null),
      },
    }),
    // @ts-expect-error
    [chatbotEvents.resetCurrentPortfolio]: () => ({
      $merge: initialState,
    }),
  })
  .when('clearSelection', () => ({
    $merge: {
      selectedProperty: null,
      selectedPlace: null,
      selectedLightstoneProperty: null,
      propertyDescription: '',
      buildingName: '',
      unitNumber: '',
      schemeNumber: '',
      schemeName: '',
    },
  }))
  .when(
    'googlePlaceSelected',
    ({ payload: { id, placeId, propertyDescription, buildingName, unitNumber, schemeNumber, schemeName } }: any) => ({
      $merge: {
        selectedProperty: null,
        selectedPlace: placeId !== undefined && id === undefined ? placeId : null,
        selectedLightstoneProperty: null,
        propertyDescription,
        buildingName,
        unitNumber,
        schemeNumber,
        schemeName,
      },
    }),
  )
  .when(
    'propertySelected',
    ({ payload: { id, propertyDescription, buildingName, unitNumber, schemeNumber, schemeName } }: any) => ({
      $merge: {
        selectedProperty: id,
        selectedPlace: null,
        propertyDescription,
        buildingName,
        unitNumber,
        schemeNumber,
        schemeName,
      },
    }),
  )
  .when(
    'lightstonePropertySelected',
    ({ payload: { lightstoneId, propertyDescription, buildingName, unitNumber, schemeNumber, schemeName } }: any) => ({
      $merge: {
        selectedProperty: null,
        selectedPlace: null,
        selectedLightstoneProperty: lightstoneId,
        propertyDescription,
        buildingName,
        unitNumber,
        schemeNumber,
        schemeName,
      },
    }),
  )
  .setup()

export const getSelectedPlace = createSelector(getPropertyDetailsDialog, dialog => dialog.selectedPlace)
export const getSelectedProperty = createSelector(getPropertyDetailsDialog, dialog => dialog.selectedProperty)
export const getSelectedLightstoneProperty = createSelector(
  getPropertyDetailsDialog,
  dialog => dialog.selectedLightstoneProperty,
)
export const getPropertyDescription = createSelector(getPropertyDetailsDialog, dialog => dialog.propertyDescription)
export const getBuildingName = createSelector(getPropertyDetailsDialog, dialog => dialog.buildingName)
export const getUnitNumber = createSelector(getPropertyDetailsDialog, dialog => dialog.unitNumber)
export const getSchemeNumber = createSelector(getPropertyDetailsDialog, dialog => dialog.schemeNumber)
export const getSchemeName = createSelector(getPropertyDetailsDialog, dialog => dialog.schemeName)

export const isPlaceSelected = createSelector(getSelectedPlace, place => !!place)
export const isPropertySelected = createSelector(getPropertyDetailsDialog, dialog => !!dialog.selectedProperty)
export const isLightstonePropertySelected = createSelector(
  getPropertyDetailsDialog,
  dialog => !!dialog.selectedLightstoneProperty,
)

export const getPropertyInitialValues = createSelector(state, s => {
  const dialog = getPropertyDetailsDialog(s)
  const empty = {
    placeId: '',
    propertyId: '',
    propertyDescription: '',
    buildingName: '',
    unitNumber: '',
    schemeNumber: '',
    schemeName: '',
    streetAddress: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
  }

  const placeDetails = propertyApiSelectors.getGooglePlaceById(s)(dialog.selectedPlace)
  const fullProperty = propertyApiSelectors.getPropertyById(s)(dialog.selectedProperty)
  const lightstoneProperty = propertyApiSelectors.getLightstonePropertyById(s)(dialog.selectedLightstoneProperty)

  const portfolioId = getCurrentPortfolioId(s)
  const portfolioMeta = portfolioApiSelectors.getPortfolioMetaData(s)(portfolioId)

  const values = (details: any) => {
    const streetNumber = searchPlaceDetails(details, 'StreetNumber')
    const streetName = searchPlaceDetails(details, 'StreetName')
    const streetType = searchPlaceDetails(details, 'StreetType')
    const sublocality = searchPlaceDetails(details, 'Sublocality')
    const streetAddress = `${streetNumber} ${streetName} ${streetType}, ${sublocality}`
    const city = searchPlaceDetails(details, 'Locality')
    const province = searchPlaceDetails(details, 'AdministrativeArea', 1, undefined)
    const postalCode = searchPlaceDetails(details, 'PostalCode')
    const country = searchPlaceDetails(details, 'Country')
    const propertyDescription = portfolioMeta || getPropertyDescription(s)
    const buildingName = searchPlaceDetails(details, 'BuildingName', 1, undefined) || getBuildingName(s)
    const unitNumber = searchPlaceDetails(details, 'UnitNumber', 1, undefined) || getUnitNumber(s)
    const schemeNumber = searchPlaceDetails(details, 'SchemeNumber') || getSchemeNumber(s)
    const schemeName = searchPlaceDetails(details, 'SchemeName') || getSchemeName(s)

    return {
      placeId: details.placeId || '',
      propertyDescription,
      buildingName: buildingName ? titleCase(buildingName.replace('SS', '')).trim() : '',
      unitNumber: unitNumber === '0' ? '' : unitNumber,
      schemeNumber: schemeNumber === '0' ? '' : schemeNumber,
      schemeName: schemeName,
      streetAddress,
      city,
      province,
      postalCode,
      country,
    }
  }

  if (placeDetails !== undefined) {
    return values(placeDetails)
  }

  if (fullProperty !== undefined) {
    return values(fullProperty)
  }

  if (lightstoneProperty !== undefined) {
    return values(lightstoneProperty)
  }

  return empty
})

export const getChatbotSelector = createSelector(
  state,
  getPropertyDetailsDialog,
  (s, { selectedProperty, selectedPlace }) => {
    const portfolioId = getCurrentPortfolioId(s)
    if (!selectedProperty && !selectedPlace && !portfolioId) {
      return {
        messages: ['Property address'],
        actions: [],
      }
    }
    return {
      actions: [],
      messages: ['Does this address look good?'],
    }
  },
)
