import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'
import { get } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'
import pluralize from 'pluralize'
import Label from '../../../atoms/Label/Label'
import { animationConfig } from 'views/constants'
import { GetStarted, OnboardingProvider, onboardingSelectors } from '../../../../../modules/onboarding'
import { SupportStaffButton, SupportStaffProvider } from '../../../../../modules/supportStaff'
import Account from './Account/Account'
import Updates from './Updates/Updates'
import styles from './RobinDrawer.module.scss'
import { DimmerLoader } from '../../..'
import { walletApiEvents, walletApiSelectors } from '../../../../../modules/api/wallet'
import { invoiceApiEvents, invoiceApiSelectors } from '../../../../../modules/api/invoice'
import { reconApiEvents, reconApiSelectors } from '../../../../../modules/api/recon'
import { userApiSelectors } from '../../../../../modules/api/user'
import { agencyApiSelectors } from '../../../../../modules/api/agency'
import { uiEvents } from '../../../../../modules/ui'
import { ROUTES } from 'constants/routes'
import { apiStateSelectors } from '../../../../../modules/api/apiState'

type TProps = {
  getAgencyBalance: (agencyId: string) => void
  currentAgencyId: string
  open: boolean
  redirect: (redirectUrl: string) => void
}

const RobinDrawer = ({ getAgencyBalance, currentAgencyId, open, redirect }: TProps) => {
  const dispatch = useDispatch()

  const style = useSpring({
    reverse: !open,
    config: animationConfig,
    to: { transform: 'translateX(0)' },
    from: { transform: 'translateX(110%)' },
  })

  const [unreadCount, setUnreadCount] = useState(0)

  const accountBalance = useSelector(state => walletApiSelectors.getGlobalBalance(state)(currentAgencyId))
  const agencyBankDetails = useSelector(agencyApiSelectors.getCurrentAgencyBankDetails)
  const globalBalance = useSelector(state => walletApiSelectors.getGlobalBalanceAsset(state)(currentAgencyId))

  const readyInvoiceCount = useSelector(state => invoiceApiSelectors.getNotificationCountByName(state)('Ready'))
  const notSetInvoiceCount = useSelector(state => invoiceApiSelectors.getNotificationCountByName(state)('Not Set'))
  const readyReconCount = useSelector(state => reconApiSelectors.getNotificationCountByName(state)('Ready'))
  const unallocatedReconCount = useSelector(state => reconApiSelectors.getNotificationCountByName(state)('Not Set'))
  const shortReconCount = useSelector(state => reconApiSelectors.getNotificationCountByName(state)('Short'))
  const overdueReconCount = useSelector(state => reconApiSelectors.getNotificationCountByName(state)('Overdue'))
  const isOnboardingComplete = useSelector(onboardingSelectors.isOnboardingComplete)
  const isReadOnly = useSelector(userApiSelectors.isReadOnlyRole)

  const getRobinDrawerNotifications = useCallback(() => {
    dispatch(reconApiEvents.notifications_request())
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    dispatch(invoiceApiEvents.notifications_request())
  }, [dispatch])

  const account = {
    balance: accountBalance,
    bankName: get(agencyBankDetails, 'bank', ''),
    number: get(agencyBankDetails, 'accountNumber', ''),
    canPayout: accountBalance && accountBalance > 0 && !isReadOnly,
    handlePayout: () => {
      const asset = get(globalBalance, 'name')
      const amount = get(globalBalance, 'balance')
      if (asset && amount) {
        dispatch(walletApiEvents.payout_request({ party: currentAgencyId, asset, amount }))
      }
    },
    openStatement: () => dispatch(uiEvents.redirect(ROUTES.commissionStatement)),
  }

  const invoiceUpdates = [
    {
      count: notSetInvoiceCount,
      color: 'orange',
      title: `Draft ${pluralize('invoice', notSetInvoiceCount)} ${pluralize(
        'is',
        notSetInvoiceCount,
      )} not set and ${pluralize('need', notSetInvoiceCount)} your attention before sending`,
      onClick: () => dispatch(redirect('/invoices/drafts?filter=notset')),
    },
    {
      count: readyInvoiceCount,
      color: 'green',
      title: `Draft ${pluralize('invoice', readyInvoiceCount)} ${pluralize('is', readyInvoiceCount)} ready to be sent`,
      onClick: () => dispatch(redirect('/invoices/drafts?filter=ready')),
    },
  ]

  const paymentUpdates = [
    {
      count: overdueReconCount,
      color: 'red',
      title: `${pluralize('Invoice', overdueReconCount)} ${pluralize(
        'is',
        overdueReconCount,
      )} overdue and may require your attention.`,
      onClick: () => dispatch(redirect('/invoices/active?filter=overdue')),
    },
    {
      count: unallocatedReconCount,
      color: 'orange',
      title: `${pluralize('Invoice', unallocatedReconCount)} ${pluralize(
        'require',
        unallocatedReconCount,
      )} allocation and payments approval.`,
      onClick: () => dispatch(redirect('/invoices/active?filter=unallocated')),
    },
    {
      count: shortReconCount,
      color: 'yellow',
      title: `${pluralize('Invoice', shortReconCount)} ${pluralize(
        'has',
        shortReconCount,
      )} insufficient funds to allocate fully.`,
      onClick: () => dispatch(redirect('/invoices/active?filter=short')),
    },

    {
      count: readyReconCount,
      color: 'green',
      title: `${pluralize('Payment', readyReconCount)} ${pluralize(
        'is',
        readyReconCount,
      )} ready to be approved, right away.`,
      onClick: () => dispatch(redirect('/invoices/active?filter=ready')),
    },
  ]

  const userEmail = useSelector(userApiSelectors.getUserEmail)
  const agencyName = useSelector(agencyApiSelectors.getCurrentAgencyName)

  const { REACT_APP_URL } = process.env

  const getNotifications = getRobinDrawerNotifications

  useEffect(() => {
    window.Intercom('onUnreadCountChange', function (unreadCount: any) {
      setUnreadCount(unreadCount)
    })
  }, [])

  useEffect(() => {
    if (open) {
      getAgencyBalance(currentAgencyId)
      getNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const isAccountLoading = useSelector(state => apiStateSelectors.isLoading(state)([walletApiEvents.balance_request]))
  const isUpdatesLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([invoiceApiEvents.notifications_request, reconApiEvents.notifications_request]),
  )

  return (
    <animated.div className={styles.root} style={style}>
      <div className={styles.help}>
        <SupportStaffProvider>
          <SupportStaffButton />
        </SupportStaffProvider>
        <button id="intercom-help">
          Help Centre
          <Label circular size="sm">
            {unreadCount}
          </Label>
        </button>
      </div>
      {isOnboardingComplete ? (
        <>
          <DimmerLoader
            className={styles['commission-account']}
            loading={isAccountLoading}
            fadeStyle="gradient"
            bgColor={'transparent'}
          >
            <Account {...account} />
          </DimmerLoader>
          <h2>To Do List</h2>
          <DimmerLoader loading={isUpdatesLoading} fadeStyle="gradient" bgColor={'transparent'}>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ count: any; color: string; title: string; ... Remove this comment to see the full error message */}
            <Updates invoiceUpdates={invoiceUpdates} paymentUpdates={paymentUpdates} />
          </DimmerLoader>
        </>
      ) : (
        <OnboardingProvider>
          <GetStarted />
        </OnboardingProvider>
      )}
    </animated.div>
  )
}

export default RobinDrawer
