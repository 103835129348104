// import * as agencyMocks from './api/agencyMocks'
// import * as invoiceMocks from './api/invoiceMocks'
// import * as partyMocks from './api/partyMocks'
// import * as portfolioMocks from './api/portfolioMocks'
import * as propertyMocks from './api/propertyMocks'
// import * as reconMocks from './api/reconMocks'
// import * as userMocks from './api/userMocks'
// import * as walletMocks from './api/walletMocks'

export const handlers = [
  // propertyMocks.searchProperties.success,
  // propertyMocks.propertyResponse.success,
  // propertyMocks.createProperty.success,
  // propertyMocks.placeResponse.success,
  // invoiceMocks.bulkSendInvoices.partialErrors,
  // walletMocks.payout.success,
  // agencyMocks.agencyListing.success
  // walletMocks.refund.errors.unknownError
  // partyMocks.getSuspenseAccountFuzzyMatches.success
  // portfolioMocks.addInvoiceTemplate.error409
  // portfolioMocks.applications.getPortfolioWithApplications,
  // portfolioMocks.applications.acceptApplication,
  // portfolioMocks.applications.deleteApplications,
  // portfolioMocks.applications.getPortfolioWithoutApplications,
  // portfolioMocks.applications.createApplication,
  // walletMocks.bulkWalletTransfer.success,
]
