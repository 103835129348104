import { ofType } from 'redux-observable'
import { of, timer, zip } from 'rxjs'
import { concatMap, delayWhen, filter, map, mapTo, mergeMap, pluck, retryWhen, tap } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { get, omit, uniqBy } from 'lodash-es'
import { flatten, isEmpty, keys, map as Rmap, path, pathOr, pipe, prop, uniq } from 'ramda'
import omitIfEmpty from '../../../utils/omitIfEmpty'
import { propertyApiEvents } from '../property'
import { partyApiEpics, partyApiEvents, partyApiSelectors } from '../party'
import { ENDPOINTS, leaseTypes, SEARCH_TAGS } from './constants'
import { events } from './state'
import * as selectors from './selectors'
import * as transformers from './transformers'
import { portfolioApiSelectors, portfolioApiUtils } from '.'
import { events as reconApiEvents } from '../recon/state'
import { multiUserOperators } from '../../multiUser/state'
import { forkEpic } from 'utils/epics'
import { userApiSelectors } from '../user'
import { $TSFixMe } from 'types/ts-migrate'
import { AnyAction } from 'redux'
import { TApplicationsResponse } from './types'
import { PayloadAction } from '@reduxjs/toolkit'
import * as invoiceApiConstants from '../invoice/constants'
import { events as notificationEvents } from 'modules/notificationCenter/state'

export const apiCreatePortfolio = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.createPortfolio_request),
    mergeMap((action: { payload }) => {
      return post(ENDPOINTS.BASE_V2, state$, action.payload).pipe(
        pluck('response'),
        map(res => events.createPortfolio_success(transformers.transformGetPortfolioResponse(res))),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiCreatePortfolio'),
  )

export const getStatus = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.getStatus_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.GET_STATUS, state$, action.payload).pipe(
        pluck('response'),
        map(events.getStatus_success),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/getStatus'),
  )

export const apiGetStats = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.portfolioStats_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.GET_STATS_PORTFOLIO, state$, action.payload).pipe(
        pluck('response'),
        map(res => events.portfolioStats_success(res)),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiGetStats'),
  )

export const apiFetchEntity = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.portfolio_request),
    mergeMap((action: AnyAction) => {
      return get(ENDPOINTS.FETCH_PORTFOLIO, state$, { id: action.payload }).pipe(
        pluck('response'),
        map(res => {
          return events.portfolio_success(transformers.transformGetPortfolioResponse(res))
        }),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiFetchEntity'),
  )

export const apiFetchList = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.portfolios_request),
    multiUserOperators.filterCurrentAgencyId(state$),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.BASE, state$, action.payload).pipe(
        pluck('response'),
        map(res => events.portfolios_success(transformers.transformGetListResponse(res))),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiFetchList'),
  )

export const apiFetchSummaries = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.portfolioSummaries_request),
    multiUserOperators.filterCurrentAgencyId(state$),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.SUMMARIES, state$, action.payload).pipe(
        pluck('response'),
        map(res => events.portfolioSummaries_success(transformers.transformGetSummariesResponse(res))),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiFetchSummaries'),
  )

export const apiSearchPortfolios = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.searchResults_request),
    mergeMap((action: AnyAction) => {
      const params = action.payload
      if (!params.tags) {
        params.tags = SEARCH_TAGS.join(',')
      }
      return get(ENDPOINTS.SEARCH, state$, params).pipe(
        pluck('response'),
        mergeMap(response =>
          zip(
            of(response).pipe(
              map(({ portfolios }: $TSFixMe) =>
                portfolios
                  .map((p: $TSFixMe) => {
                    const { propertyId } = p.portfolioSummary
                    if (propertyId) {
                      return propertyApiEvents.property_request({ id: propertyId })
                    }
                    return undefined
                  })
                  .filter((e: $TSFixMe) => e !== undefined),
              ),
            ),
            of(response).pipe(
              map(response =>
                events.searchResults_success({
                  results: response,
                  query: params.query,
                }),
              ),
            ),
          ).pipe(mergeMap(([propertyRequests, searchResultsFetched]) => [...propertyRequests, searchResultsFetched])),
        ),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiSearchPortfolios'),
  )

export const apiAmendProperty = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.amendProperty_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.AMEND_PROPERTY, state$, body, params).pipe(
        pluck('response'),
        map(res => {
          const previousPropertyId = path(
            ['propertyId'],
            portfolioApiSelectors.getPortfolioById(state$.value)(params.id),
          )
          return events.amendProperty_success(transformers.transformGetPortfolioResponse(res), {
            previousPropertyId,
          })
        }),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiAmendProperty'),
  )

export const apiAmendTerms = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.amendTerms_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.AMEND_TERMS, state$, body, params).pipe(
        pluck('response'),
        map(res => events.amendTerms_success(transformers.transformGetPortfolioResponse(res))),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiAmendTerms'),
  )

export const apiAmendRentAndFees = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.amendRentAndFees_request),
    mergeMap((action: AnyAction) => {
      let { body, params } = action.payload
      body = {
        ...body,
        value: {
          ...body.value,
          terms:
            body.type === leaseTypes.managed
              ? {
                  ...omit(body.value.terms, ['firstMonthRentAmount', 'leaseFee', 'applicationFee']),
                  ...omitIfEmpty('value.terms.firstMonthRentAmount', body, (v: number) => v > 0),
                  ...omitIfEmpty('value.terms.leaseFee', body, (v: $TSFixMe) => v.netAmount > 0),
                  ...omitIfEmpty('value.terms.applicationFee', body, (v: $TSFixMe) => v.netAmount > 0),
                }
              : body.value.terms,
        },
      }

      const requestBody =
        body.type === leaseTypes.managed
          ? transformers.transformAmendManagedContractRequest(body)
          : transformers.transformAmendUnmanagedContractRequest(body)

      return post(ENDPOINTS.AMEND_CONTRACT, state$, requestBody, params).pipe(
        pluck('response'),
        map(response => {
          const portfolioId: string | undefined = path(['id'], params)
          if (!portfolioId) {
            return []
          }

          return events.amendRentAndFees_success(transformers.transformGetPortfolioResponse(response))
        }),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiAmendRentAndFees'),
  )

export const apiAmendSettings = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.amendSettings_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.AMEND_SETTINGS, state$, body, params).pipe(
        pluck('response'),
        map(res => events.amendSettings_success(transformers.transformGetPortfolioResponse(res))),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiAmendSettings'),
  )

export const apiAmendCommission = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.amendCommission_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.AMEND_COMMISSION, state$, transformers.transformAmendCommissionRequest(body), params).pipe(
        pluck('response'),
        map(response => events.amendCommission_success(transformers.transformGetPortfolioResponse(response))),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiAmendCommission'),
  )

export const apiAmendMetaData = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.amendMetaData_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.AMEND_META_DATA, state$, body, params).pipe(
        pluck('response'),
        map(res => events.amendMetaData_success(transformers.transformGetPortfolioResponse(res))),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiAmendMetaData'),
  )

export const apiAddInvoiceTemplate = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.addInvoiceTemplate_request),
    mergeMap((action: AnyAction) => {
      const { body, params }: { body: $TSFixMe; params: { id: string } } = action.payload
      return post(ENDPOINTS.CREATE_INVOICE_TEMPLATE, state$, transformers.transformInvoiceTemplate(body), params).pipe(
        pluck('response'),
        map((response: $TSFixMe) =>
          events.addInvoiceTemplate_success(transformers.transformGetPortfolioResponse(response), {
            portfolioId: params.id,
          }),
        ),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiAddInvoiceTemplate'),
  )

export const apiUpdateInvoiceTemplate = (action$: $TSFixMe, state$: $TSFixMe, { put, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.updateInvoiceTemplate_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return put(ENDPOINTS.UPDATE_INVOICE_TEMPLATE, state$, transformers.transformInvoiceTemplate(body), params).pipe(
        pluck('response'),
        map(res =>
          events.updateInvoiceTemplate_success(transformers.transformGetPortfolioResponse(res), {
            portfolioId: params.id,
          }),
        ),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiUpdateInvoiceTemplate'),
  )

export const apiRemoveInvoiceTemplate = (action$: $TSFixMe, state$: $TSFixMe, { remove, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.removeInvoiceTemplate_request),
    mergeMap((action: AnyAction) =>
      remove(ENDPOINTS.REMOVE_INVOICE_TEMPLATE, state$, action.payload).pipe(
        pluck('response'),
        map(res => events.removeInvoiceTemplate_success(transformers.transformGetPortfolioResponse(res))),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiRemoveInvoiceTemplate'),
  )

export const apiFetchInvoiceSchedules = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.invoiceSchedules_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.INVOICE_SCHEDULES, state$, { id: action.payload }).pipe(
        pluck('response'),
        map(response => events.invoiceSchedules_success({ response, params: { id: action.payload } })),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiFetchInvoiceSchedules'),
  )

export const apiRequestApproval = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.approvalRequest_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.REQUEST_APPROVAL, state$, body, params).pipe(
        pluck('response'),
        map(events.approvalRequest_success),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiRequestApproval'),
  )

export const apiApprove = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.approve_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.APPROVE, state$, body, params).pipe(
        pluck('response'),
        map(res => events.approve_success(transformers.transformGetPortfolioResponse(res))),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiApprove'),
  )

export const prefetchPortfolioTenantsAndLandlords = (action$: $TSFixMe, state$: $TSFixMe) =>
  action$.pipe(
    ofType(events.portfolio_success),
    mergeMap(({ payload }) => {
      const parties = selectors.selectLandordAndTenantIdsFromPortfolio(state$.value)(payload.id)
      return uniqBy(parties, 'partyId')
    }),
    map((p: $TSFixMe) => p.partyId),
    filter(p => p !== undefined),
    map((partyId: string) => partyApiEvents.party_request(partyId)),
    tag('portfolio/epic/prefetchPortfolioTenantsAndLandlords'),
  )

export const prefetchPartiesFromCommission = (action$: $TSFixMe, state$: $TSFixMe) =>
  action$.pipe(
    ofType(events.portfolio_success),
    mergeMap(({ payload }) => get(payload, 'commission.managementFee.splits', []).map((s: $TSFixMe) => s.agentPartyId)),
    map((partyId: string) => partyApiEvents.party_request(partyId)),
    tag('portfolio/epic/prefetchPartiesFromCommission'),
  )

export const prefetchPartiesFromInvoiceTemplates = (action$: $TSFixMe, state$: $TSFixMe) =>
  action$.pipe(
    ofType(events.portfolio_success),
    mergeMap(({ payload }) => {
      const events: $TSFixMe[] = []
      const templates: $TSFixMe = pathOr([], ['invoiceTemplates'], payload)
      const partyIds = pipe(
        Rmap(prop('paymentRules')),
        flatten,
        Rmap(pathOr('', ['beneficiary', 'value', 'partyId'])),
        uniq,
      )(templates)

      // Fetch agency party. Used in invoice templates default values
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const agencyParty = partyApiSelectors.getAgencyParty(state$.value)
      if (!agencyParty) {
        partyIds.push(currentAgencyId)
      }

      const easyPayReferences = pipe(
        Rmap(prop('paymentRules')),
        flatten,
        Rmap(path(['beneficiary', 'value', 'easyPayReference'])),
        uniq,
      )(templates)
      if (!isEmpty(partyIds)) {
        events.push(partyApiEvents.parties_request(partyIds.filter((id: $TSFixMe) => id !== '')))
      }

      if (!isEmpty(easyPayReferences)) {
        easyPayReferences
          .filter((ref: $TSFixMe) => ref !== undefined)
          .forEach((reference: $TSFixMe) => {
            events.push(reconApiEvents.easypayData_request({ reference }))
          })
      }
      return events
    }),
    tag('portfolio/epic/prefetchPartiesFromInvoiceTemplates'),
  )

/**
 * @todo move this to UI epic
 */
export const prefetchProperty = (action$: $TSFixMe, state$: $TSFixMe) =>
  action$.pipe(
    ofType(events.portfolio_success),
    map((action: AnyAction) => get(action.payload, 'propertyId')),
    filter(p => p !== undefined && p !== null),
    map((id: string) => propertyApiEvents.property_request({ id })),
    tag('portfolio/epic/prefetchProperty'),
  )

export const apiFetchTerminationReasons = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.terminationReasons_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.FETCH_TERMINATION_REASONS, state$).pipe(
        pluck('response'),
        map(events.terminationReasons_success),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiFetchTerminationReasons'),
  )

export const apiTerminateApprovedLease = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.terminateApprovedLease_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.TERMINATE_APPROVED_LEASE, state$, body, params).pipe(
        pluck('response'),
        map(res => events.terminateApprovedLease_success(transformers.transformGetPortfolioResponse(res))),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiTerminateApprovedLease'),
  )

export const apiDeleteDraftLease = (action$: $TSFixMe, state$: $TSFixMe, { remove, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.deleteDraftLease_request),
    mergeMap((action: AnyAction) =>
      remove(ENDPOINTS.DELETE_DRAFT_LEASE, state$, { id: action.payload }).pipe(
        pluck('response'),
        map(res => events.deleteDraftLease_success(transformers.transformGetPortfolioResponse(res))),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiDeleteDraftLease'),
  )

export const apiRenew = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.renewal_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.RENEW, state$, transformers.transformRenewRequest(body), params).pipe(
        pluck('response'),
        map(res => events.renewal_success(transformers.transformGetPortfolioResponse(res), params)),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiRenew'),
  )

export const apiCancelRenewal = (action$: $TSFixMe, state$: $TSFixMe, { remove, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.cancelRenewal_request),
    mergeMap((action: AnyAction) =>
      remove(ENDPOINTS.RENEW, state$, action.payload).pipe(
        pluck('response'),
        map(() => events.cancelRenewal_success(action.payload)),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiRenew'),
  )

export const apiAmendSegments = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.amendSegments_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.AMEND_SEGMENTS, state$, body, params).pipe(
        pluck('response'),
        map(() => events.amendSegments_success({ ...body, ...params })),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiAmendSegments'),
  )

export const apiUpdateOwnerParties = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.updateOwnerParties_request),
    mergeMap((action: AnyAction) => {
      const { params, body } = action.payload
      return post(ENDPOINTS.UPDATE_OWNER_PARTIES, state$, body, params).pipe(
        pluck('response'),
        map(res => events.updateOwnerParties_success(transformers.transformGetPortfolioResponse(res), params)),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiUpdateOwnerParties'),
  )

export const apiUpdateTenantParties = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.updateTenantParties_request),
    mergeMap((action: AnyAction) => {
      const { params, body } = action.payload
      return post(ENDPOINTS.UPDATE_TENANT_PARTIES, state$, body, params).pipe(
        pluck('response'),
        map(res => events.updateTenantParties_success(transformers.transformGetPortfolioResponse(res), params)),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiUpdateTenantParties'),
  )

export const apiAddPartyAndLinkToLease = (action$: $TSFixMe, state$: $TSFixMe, dependencies: $TSFixMe) =>
  action$.pipe(
    ofType(events.addPartyAndLinkToLease_request),
    mergeMap((action: $TSFixMe) => {
      const {
        payload: { id, values, type },
      } = action
      const addTenantOrOwner = (partyId: $TSFixMe) => {
        if (type === 'landlord') {
          const owners = portfolioApiSelectors.getOwnersByPortfolioId(state$.value)(id)
          return events.updateOwnerParties_request({
            params: { id },
            body: portfolioApiUtils.addParty(owners, partyId),
          })
        } else {
          const tenants = portfolioApiSelectors.getTenantsByPortfolioId(state$.value)(id)
          return events.updateTenantParties_request({
            params: { id },
            body: portfolioApiUtils.addParty(tenants, partyId),
          })
        }
      }

      if (values[keys(values)[0]].id) {
        const partyId = values[keys(values)[0]].id
        return [addTenantOrOwner(partyId)]
      } else {
        return forkEpic(
          partyApiEpics.apiCreateParty,
          dependencies,
          state$,
          partyApiEvents.createParty_request(values),
        ).pipe(
          concatMap(res =>
            of(res).pipe(
              pluck('payload'),
              mergeMap((payload: $TSFixMe) => {
                const partyId = payload[keys(values)[0]]?.id
                return partyId ? [partyApiEvents.createParty_success(payload), addTenantOrOwner(partyId)] : []
              }),
            ),
          ),
        )
      }
    }),
    tag('portfolio/epics/apiAddPartyAndLinkToLease'),
  )

export const getApplications = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.getApplications_request),
    mergeMap((action: AnyAction) => {
      return get(ENDPOINTS.LEASE_APPLICATIONS, state$, action.payload).pipe(
        pluck('response'),
        map((res: TApplicationsResponse) =>
          transformers.transformApplicationsResponse(events.getApplications_success(res)),
        ),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/getApplications'),
  )

export const apiAddApplication = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.addApplication_request),
    mergeMap((action: AnyAction) => {
      const { params, body } = action.payload
      return post(ENDPOINTS.LEASE_APPLICATIONS, state$, transformers.transformAddApplicationRequest(body), params).pipe(
        pluck('response'),
        map((res: TApplicationsResponse) =>
          transformers.transformApplicationsResponse(events.addApplication_success(res)),
        ),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiAddApplication'),
  )

export const apiAddApplicationAndLinkToLease = (action$: $TSFixMe, state$: $TSFixMe, dependencies: $TSFixMe) =>
  action$.pipe(
    ofType(events.addApplicationAndLinkToLease_request),
    mergeMap((action: $TSFixMe) => {
      const {
        payload: { id, values, applicationFee = 0 },
      } = action

      // Merge new application with existing applications
      const addApplication = (application: { partyId: string; applicationFee: number }) => {
        return events.addApplication_request({ params: { id }, body: application })
      }

      // if the party exists, create application with existing party
      if ((values?.person || values?.company)?.id) {
        const partyId = (values?.person || values?.company)?.id
        const isDuplicateApplication = selectors.getApplicationByPartyId(state$.value)(id, partyId)
        return isDuplicateApplication
          ? [
              notificationEvents.addNotification(
                { message: 'Application already exists', type: 'error' },
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
                { autoDismiss: true },
              ),
            ]
          : [addApplication({ partyId, applicationFee })]
      } else {
        // if the party doesn't exist, create party and application
        return forkEpic(
          partyApiEpics.apiCreateParty,
          dependencies,
          state$,
          partyApiEvents.createParty_request(values),
        ).pipe(
          concatMap(res =>
            of(res).pipe(
              pluck('payload'),
              mergeMap((payload: $TSFixMe) => {
                const partyId = payload[keys(values)[0]]?.id
                return partyId
                  ? [partyApiEvents.createParty_success(payload), addApplication({ partyId, applicationFee })]
                  : []
              }),
            ),
          ),
        )
      }
    }),
    tag('portfolio/epics/apiAddApplicationAndLinkToLease'),
  )

export const apiUpdateApplication = (action$: $TSFixMe, state$: $TSFixMe, { put, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.updateApplication_request),
    mergeMap(
      (
        action: PayloadAction<{
          id: string
          values: { applicationFee: number; vat: boolean; partyId: string }
          applicationId: string
        }>,
      ) => {
        const { id, values, applicationId } = action.payload
        return put(ENDPOINTS.UPDATE_APPLICATION, state$, transformers.transformUpdateApplicationRequest(values), {
          id,
          applicationId,
        }).pipe(
          pluck('response'),
          mergeMap(res => [
            events.updateApplication_success(transformers.transformGetPortfolioResponse(res)),
            events.getApplications_request({ id: action.payload.id }),
          ]),
          catchRestError(action),
        )
      },
    ),
    tag('portfolio/epics/apiUpdateApplication'),
  )

export const apiDeleteApplication = (action$: $TSFixMe, state$: $TSFixMe, { remove, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.deleteApplication_request),
    mergeMap((action: AnyAction) =>
      remove(ENDPOINTS.DELETE_APPLICATION, state$, action.payload).pipe(
        pluck('response'),
        map((res: TApplicationsResponse) =>
          transformers.transformApplicationsResponse(events.deleteApplication_success(res)),
        ),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiDeleteApplication'),
  )

export const apiAcceptApplication = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.acceptApplication_request),
    mergeMap((action: PayloadAction<{ id: string }>) => {
      return post(ENDPOINTS.ACCEPT_APPLICATION, state$, null, action.payload).pipe(
        pluck('response'),
        mergeMap(res => [
          events.acceptApplication_success(transformers.transformGetPortfolioResponse(res)),
          events.getApplications_request({ id: action.payload.id }),
        ]),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/apiAcceptApplication'),
  )

export const apiGetBulkInvoicesCsvTemplate = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(events.bulkInvoicesCsvTemplate_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.BULK_INVOICES_CSV_TEMPLATE, state$, action.payload).pipe(
        pluck('response'),
        map(events.bulkInvoicesCsvTemplate_success),
        catchRestError(action),
      ),
    ),
    tag('portfolio/epics/apiGetBulkInvoicesCsvTemplate'),
  )

export const sendApplicationInvoice = (action$: any, state$: any, { post, get, catchRestError }: any) =>
  action$.pipe(
    ofType(events.sendApplicationInvoice_request),
    mergeMap((action: PayloadAction<{ portfolioId: string; applicationId: string }>) => {
      return get(invoiceApiConstants.ENDPOINTS.INVOICE_ENTITY, state$, { id: action.payload.applicationId }).pipe(
        retryWhen(errors => {
          return errors.pipe(delayWhen(() => timer(1000)))
        }),
        map(() => action),
      )
    }),
    mergeMap((action: PayloadAction<{ portfolioId: string; applicationId: string }>) => {
      const { portfolioId, applicationId } = action.payload
      const requestPayload = {
        invoiceIds: [applicationId],
      }
      return post(invoiceApiConstants.ENDPOINTS.SEND_INVOICES, state$, requestPayload).pipe(
        pluck('response', 'invoiceResults'),
        mergeMap(() => [
          events.sendApplicationInvoice_success({
            portfolioId,
            applicationId,
            invoiceId: applicationId,
            status: 'InvoiceSent',
          }),
          notificationEvents.addNotification(
            {
              type: 'success',
              message: 'Application Fee invoice sent successfully',
            },
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
            { autoDismiss: true },
          ),
        ]),
        catchRestError(action),
      )
    }),
    tag('portfolio/epics/sendApplicationInvoice'),
  )
