import React from 'react'
import { subscribe } from 'react-contextual'
import { ListingTemplate } from '../../../views/components'
import { useEffectOnce } from 'react-use'
import { useDispatch } from 'react-redux'
import { SuspenseAccountsSearchFilter, SuspenseAccountsTable } from '..'
import SuspenseAccountsProvider from '../SuspenseAccountsProvider'
import { walletApiEvents } from '../../api/wallet'
import { partyApiEvents } from '../../api/party'

function SuspenseAccountsContent({ isLoading }: any) {
  const dispatch = useDispatch()
  useEffectOnce(() => {
    dispatch(walletApiEvents.unallocatedSuspenseAccountss_request())
    dispatch(walletApiEvents.allocatedSuspenseAccounts_request())
    // remove once API is depoloyed - temp fetches all parties so mocks can fake data
    dispatch(partyApiEvents.quickSearch_request({ query: '', tags: '' }))
  })

  return (
    <ListingTemplate loading={isLoading} Header={<SuspenseAccountsSearchFilter />} Body={<SuspenseAccountsTable />} />
  )
}

export default subscribe([SuspenseAccountsProvider], ({ isLoading }: any) => ({
  isLoading,
}))(SuspenseAccountsContent)
