import { prop, sort, either, comparator, gt, reduce, map, reverse, head, sortBy, match } from 'ramda'
import { compose } from 'redux'

export const insert = (index: number, item: any, array: any) => [...array.slice(0, index), item, ...array.slice(index)]

export const removeByIndex = (index: number, array: any) => array.filter((b: any, i: any) => i !== index)

export const sortByProps = (props: any, array: any, order = 'asc') => {
  const firstTruthy = ([head, ...tail]) => reduce(either, head, tail)
  // @ts-expect-error
  const makeComparator = (propName: any) => comparator((a: any, b: any) => gt(prop(propName, b), prop(propName, a)))

  const sortedArray =
    order === 'asc'
      ? // @ts-expect-error
        sort(firstTruthy(map(makeComparator, props)), array)
      : // @ts-expect-error
        reverse(sort(firstTruthy(map(makeComparator, props)), array))

  return sortedArray
}

export const sortByMainTextUnitNumber = (a: any, b: any) => {
  const aMatch = a.mainText.match(/^(\d+)/)
  const bMatch = b.mainText.match(/^(\d+)/)

  if (aMatch && bMatch) {
    const aNum = parseInt(aMatch[1], 10)
    const bNum = parseInt(bMatch[1], 10)
    if (aNum !== bNum) {
      return aNum - bNum
    }
  } else if (aMatch) {
    return -1 // Numbers come before non-numbers
  } else if (bMatch) {
    return 1 // Numbers come before non-numbers
  }

  // If no numbers or numbers are equal, sort alphabetically
  return a.mainText.localeCompare(b.mainText, undefined, { numeric: true, sensitivity: 'base' })
}
