import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { $TSFixMe } from 'types/ts-migrate'
import { NAMESPACE } from './constants'
import { getTaggablePartyId } from './utils'

const initialState: $TSFixMe = {
  isSearching: false,
  query: '',
  entities: {
    bySearchTerms: {},
    parties: {},
    partyTags: [],
    suspenseAccountFuzzyMatches: {}, // indexed by reference
  },
  bulkImportResult: null,
}

// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
const resolveId = (payload: any): $TSFixMe => Object.values(payload)[0].id

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    createParty_request: (state, { payload }) => {},
    createParty_success: (state, { payload }) => {
      state.entities.parties[resolveId(payload)] = payload
    },
    party_request: (state, { payload }: { payload: string }) => {},
    party_success: (state, { payload }) => {
      state.entities.parties[resolveId(payload)] = payload
    },
    updateParty_request: (state, { payload }) => {},
    updateParty_success: (state, { payload }) => {
      state.entities.parties[resolveId(payload)] = payload
    },
    partyTags_request: state => {},
    partyTags_success: (state, { payload }) => {
      state.entities.partyTags = payload.partyTagSummaries.map((tag: any) => ({
        value: tag.name,
        label: tag.value,
      }))
    },
    parties_request: (state, { payload }: { payload: string[] }) => {},
    quickSearch_request: {
      reducer: () => {},
      prepare: ({ query = '', tags = '', offset = 0, limit = 1000 }) => ({ payload: { query, tags, offset, limit } }),
    },
    quickSearch_success: (state, { payload: { results, params } }) => {
      state.entities.bySearchTerms[params.query] = results.map((r: any) => {
        return getTaggablePartyId(r)
      })
      state.entities.parties = {
        ...state.entities.parties,
        ...results.reduce((acc: any, party: any) => {
          const id = getTaggablePartyId(party)
          // @ts-expect-error
          acc[id] = { id, ...party }
          return acc
        }, {}),
      }
    },
    accounts_request: (state, { payload }) => {},
    accounts_success: (state, { payload }) => {},
    suspenseAccountFuzzyMatches_request: (state, { payload }) => {},
    suspenseAccountFuzzyMatches_success: {
      reducer: (state, { payload, meta }) => {
        if (meta?.reference) {
          state.entities.suspenseAccountFuzzyMatches[meta.reference] = payload
        }
      },
      prepare: (payload, meta) => ({ payload, meta, error: null }),
    },
    bulkImport_request: (state, { payload }: PayloadAction<{ base64: string }>) => {},
    bulkImport_success: (state, { payload }: PayloadAction<{ errors: $TSFixMe[]; parties: $TSFixMe[] }>) => {
      state.bulkImportResult = payload
    },
    clearBulkImportResult: state => {
      state.bulkImportResult = null
    },
  },
})

const { reducer, actions: events } = slice

export { events, reducer }
