import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, CurrencyText, TableProvider } from '../../../../views/components'
// import { partyApiEvents } from '../../../api/party'
import styles from './ViewSuspenseAccount.module.scss'
import AllocationModal from '../Modal/AllocationModal/AllocationModal'

const ViewUnallocatedReference = ({ currentReference }: any): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <TableProvider>
      <div>
        <div className={styles['title-text']}>
          <h2>Reference used: {currentReference?.referenceUsed}</h2>
          <span>
            {' '}
            <strong>Transaction ID:</strong> {currentReference?.txId.substr(0, 9)}...
          </span>
          <span>
            <strong>Amount:</strong> <CurrencyText>{currentReference?.amount || 0}</CurrencyText>
          </span>
        </div>
        {/* <p className={styles.info}>{fuzzyMatches.length === 0
          ? 'There are no bad fuzzy matches on this suspense account.'
          : 'Click on a fuzzy match in the table below to allocate or click the button for manual allocation'}
        </p> */}

        <Button size="sm" onClick={() => setIsOpen(true)}>
          Allocate Suspense Account
        </Button>
        {/* {fuzzyMatches.length !== 0 && <AggregateTable
          className={styles.table}
          columns={columns}
          data={styledFuzzyMatches}
          rowHeight={80}
        />
        } */}
      </div>
      <AllocationModal isOpen={isOpen} onClose={() => setIsOpen(false)} currentReference={currentReference} />
    </TableProvider>
  )
}

ViewUnallocatedReference.propTypes = {
  currentReference: PropTypes.shape({
    txId: PropTypes.string,
    referenceUsed: PropTypes.string,
    amount: PropTypes.number,
    dateReceived: PropTypes.string,
  }),
}

export default ViewUnallocatedReference
