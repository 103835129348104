import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CurrencyText, DimmerLoader, InfoList, Modal } from '../../../../../views/components'
import styles from './ConfirmationAllocationModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { walletApiEvents, walletApiSelectors } from '../../../../api/wallet'
import { apiStateSelectors } from '../../../../api/apiState'
import { agencyApiEvents, agencyApiSelectors } from '../../../../api/agency'
import { $TSFixMe } from 'types/ts-migrate'

const ConfirmationAllocationModal = ({
  isOpen,
  onClose,
  onConfirm,
  currentReference,
  allocatedToRef,
  clickupRef,
  notes,
}: any): React.ReactElement => {
  const dispatch = useDispatch()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (allocatedToRef) {
      dispatch(walletApiEvents.referenceLookup_request({ reference: allocatedToRef }))
      dispatch(agencyApiEvents.agencyListing_request())
    }
  }, [dispatch, allocatedToRef])

  const correctReferenceLookup: $TSFixMe = useSelector(state =>
    walletApiSelectors.getReferenceLookupByReference(state)(allocatedToRef),
  )
  const agencyId = correctReferenceLookup?.agencyId
  const partyId = correctReferenceLookup?.partyId
  const agencyFromListing = useSelector(state => agencyApiSelectors.getAgencyFromListingById(state)(agencyId))
  const agencyName = (
    <>
      <strong>{agencyFromListing?.agencyName}</strong> - Trading as <strong>{agencyFromListing?.tradingAs}</strong>
    </>
  )

  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)([walletApiEvents.referenceLookup_request]))

  const accountType = correctReferenceLookup?.accountType
  const propertyAddress = correctReferenceLookup?.propertyAddress

  const handleConfirm = (): void => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (currentReference) {
      const { txId } = currentReference
      dispatch(
        walletApiEvents.allocateSuspenseAccount_request({
          params: { txId },
          body: {
            correctReference: allocatedToRef,
            notes,
            clickupReference: clickupRef,
          },
        }),
      )
      if (typeof onConfirm === 'function') {
        onConfirm()
      }
    }
  }

  return (
    <Modal className={styles.root} isOpen={isOpen} padding="sm" width="lg" theme="dark" showClose={false}>
      <Modal.Header title="Are you sure?" />
      <Modal.Body
        actions={[
          { label: 'Yes, allocate', onClick: handleConfirm },
          { label: 'Cancel', onClick: onClose },
        ]}
      >
        <DimmerLoader loading={isLoading} bgColor="dark">
          <>
            <div className={styles['sub-header']}>
              {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
              You are about to allocate{' '}
              <span>
                <CurrencyText>{currentReference?.amount || 0}</CurrencyText>
              </span>{' '}
              from suspense account <span>{currentReference?.referenceUsed}</span> to reference{' '}
              <span>{allocatedToRef}</span>
            </div>
            {/* @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component. */}
            <InfoList
              className={styles.info}
              spacing="sm"
              items={[
                {
                  label: 'Account Type:',
                  value: accountType,
                },
                {
                  label: 'Party ID:',
                  value: partyId,
                },
                {
                  label: 'Agency:',
                  value: agencyName,
                },
                {
                  label: 'Property:',
                  value: propertyAddress,
                },
                {
                  label: 'ClickUp Ref:',
                  value: (
                    <a href={clickupRef} target="_blank" rel="noopener noreferrer">
                      {clickupRef}
                    </a>
                  ),
                },
                {
                  label: 'Notes:',
                  value: notes,
                },
              ]}
            />
          </>
        </DimmerLoader>
      </Modal.Body>
    </Modal>
  )
}

ConfirmationAllocationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  currentReference: PropTypes.shape({
    txId: PropTypes.string,
    referenceUsed: PropTypes.string,
    amount: PropTypes.number,
    dateReceived: PropTypes.string,
  }),
  allocatedToRef: PropTypes.string,
  clickupRef: PropTypes.string,
  notes: PropTypes.string,
}

export default ConfirmationAllocationModal
