import React, { useCallback, useState } from 'react'
import { isEmpty, pathOr } from 'ramda'
import { FormErrors, Tabs } from '../../..'
import SearchProperties from './SearchProperties'
import { withFormik } from 'formik'
import PropertyPreview from './PropertyPreview'
import { useSelector } from 'react-redux'
import { apiStateSelectors } from '../../../../../modules/api/apiState'
import { propertyApiEvents } from '../../../../../modules/api/property'
import { getSelectedPlace, getSelectedProperty } from 'modules/ui/ChatBot/Dialogs/PropertyDetails'
import styles from './PropertyForm.module.scss'
import RadioGroup from '../../../molecules/RadioGroup/RadioGroup'
import Radio from '../../../atoms/Radio/Radio'
import { getCurrentLeaseType } from 'modules/ui/ChatBot/Dialogs/LeaseTypeSelection'
import { leaseTypes } from 'modules/api/portfolio/constants'
import { portfolioApiEvents } from '../../../../../modules/api/portfolio'

type requestPayload = {
  placeId: string | null
  propertyId: string | null
  propertyDescription: string
  unitNumber: string
  buildingName: string
  schemeNumber: string
  schemeName: string
}

const PropertyForm = ({
  initialValues,
  currentPortfolioId,
  handleConfirmation,
  isReadonly,
  clearSelection,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  isActivePortfolio,
  handleManagedSelected,
  handleUnmanagedSelected,
}: any) => {
  const [requestPayload, setRequestPayload] = useState<requestPayload | null>(null)

  const placeId = useSelector(getSelectedPlace)
  const propertyId = useSelector(getSelectedProperty)
  const leaseType = useSelector(state => getCurrentLeaseType(state))

  const _handleConfirmation = useCallback(
    (selectedPlace, selectedProperty, propertyDescription, buildingName, unitNumber, schemeNumber, schemeName) => {
      setRequestPayload({
        placeId: selectedPlace || placeId,
        propertyId: selectedProperty || propertyId,
        propertyDescription,
        unitNumber,
        buildingName,
        schemeNumber,
        schemeName,
      })
      handleConfirmation({ propertyDescription, buildingName, unitNumber, schemeNumber, schemeName })
    },
    [placeId, handleConfirmation],
  )

  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  // TODO: fix field errors. Uses different event now.
  const createPropertyEvent = propertyApiEvents.createProperty_request(requestPayload)
  const generalErrors = requestPayload === null ? [] : getGeneralFormErrorsByEvent(createPropertyEvent)
  const fieldErrors = requestPayload === null ? {} : getFormFieldErrorsByEvent(createPropertyEvent)

  const createPortfolioErrors = useSelector(state =>
    apiStateSelectors.getGeneralFormErrorsByEvent(state)(portfolioApiEvents.createPortfolio_request),
  )

  const onLeaseTypeChange = (type: string) => {
    type === leaseTypes.managed ? handleManagedSelected() : handleUnmanagedSelected()
  }

  return (
    <span>
      {!currentPortfolioId && (
        <RadioGroup
          name="leaseType"
          className={styles['radio-group']}
          stackRadios={false}
          label="Lease Type"
          defaultSelected={leaseType}
          onChange={e => onLeaseTypeChange(e.target.value)}
        >
          <Radio name="managed" value={leaseTypes.managed} label="Managed" allowUncheck={false} />
          <Radio name="unmanaged" value={leaseTypes.unmanaged} label="Unmanaged" allowUncheck={false} />
        </RadioGroup>
      )}
      {generalErrors.length === 0 && isEmpty(fieldErrors) && values.streetAddress ? (
        <>
          <FormErrors errors={createPortfolioErrors} />
          <PropertyPreview
            initialValues={initialValues}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            clearSelection={clearSelection}
            handleConfirmation={(e: any) => {
              e.preventDefault()
              const placeId = pathOr('', ['selectedPlace', 'placeId'], values)
              const propertyId = pathOr('', ['selectedProperty', 'propertyId'], values)
              _handleConfirmation(
                placeId,
                propertyId,
                values.propertyDescription,
                values.buildingName,
                values.unitNumber,
                values.schemeNumber,
                values.schemeName,
              )
            }}
            isActivePortfolio={isActivePortfolio}
          />
        </>
      ) : (
        <>
          {/* @ts-expect-error */}
          <SearchProperties isReadonly={isReadonly} />
        </>
      )}
    </span>
  )
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ enableReinitialize: true; mapP... Remove this comment to see the full error message
export default withFormik({
  enableReinitialize: true,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  mapPropsToValues: props => ({ ...props.initialValues }),
})(PropertyForm)
