import React from 'react'
import { SearchFilterProvider } from '../../../views/providers'
import { SuspenseAccountsPage, SuspenseAccountsProvider } from '..'
import { SupportStaffProvider } from '../../supportStaff'

const SegmentsRoute = (props: any) => (
  <SuspenseAccountsProvider {...props}>
    <SupportStaffProvider>
      <SearchFilterProvider location={props.location}>
        <SuspenseAccountsPage />
      </SearchFilterProvider>
    </SupportStaffProvider>
  </SuspenseAccountsProvider>
)

export default SegmentsRoute
