import { createSlice } from '@reduxjs/toolkit'
import { NAMESPACE } from '../constants'

const suspenseAccountsSlice = createSlice({
  name: NAMESPACE,
  initialState: {},
  /** @todo add actions and reducers */
  reducers: {
    unallocatedSuspenseAccounts_request: (state, { payload }) => {},
    unallocatedSuspenseAccounts_success: (state, { payload }) => {},
  },
})

/**
 * Exports
 */
const { reducer, actions: events } = suspenseAccountsSlice

export { events as suspenseAccountsEvents, reducer as suspenseAccountsReducer }
