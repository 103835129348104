import React from 'react'
import { subscribe } from 'react-contextual'
import { sumBy } from 'lodash-es'
import { generatePath } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CurrencyText, Segment } from '../../../../../../views/components'
import { uiEvents } from '../../../../../ui'
import { ROUTES } from 'constants/routes'
import ActiveInvoicesProvider from './ActiveInvoicesProvider'
import styles from './ActiveInvoices.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { isEmpty } from 'ramda'

const ActiveInvoices = ({ getCustomerSummary, getCustomerActiveInvoicesTotal, invoiceTypes, activeInvoices }: any) => {
  const dispatch = useDispatch()
  const openInvoice = (id: string, invoiceStatus: string) => {
    dispatch(
      uiEvents.redirect(
        generatePath(ROUTES.invoiceView, { type: 'active', id }) +
          `?filter=${generateInvoiceStatusFilter(invoiceStatus)}`,
      ),
    )
  }

  const getAvailableFunds = () => {
    const summary = getCustomerSummary
    return <CurrencyText>{!isEmpty(summary) ? summary.availableFunds : 0}</CurrencyText>
  }

  const getClosingBalance = (): React.ReactElement => {
    return <CurrencyText>{getCustomerActiveInvoicesTotal - getAvailableFunds().props.children}</CurrencyText>
  }

  const matchInvoiceDescriptionToType = (invoice: $TSFixMe) => {
    const invoiceType = invoiceTypes.find((type: $TSFixMe) => type.value === invoice.description)
    return invoiceType ? invoiceType.name : ''
  }

  const generateInvoiceStatusFilter = (status: string) => {
    switch (status) {
      case 'Out':
        return 'open'
      case 'Overdue':
        return 'overdue'
      case 'NotSet':
        return 'unallocated'
      case 'Short':
        return 'short'
      case 'Ready':
        return 'ready'
      default:
        return 'ready'
    }
  }

  return (
    activeInvoices.length > 0 && (
      <section className={styles.root}>
        <header>
          <span>Active Invoices</span>
          <span>
            <CurrencyText>{sumBy(activeInvoices, (inv: any) => inv.balance)}</CurrencyText>
          </span>
        </header>
        <ul>
          {activeInvoices.map((inv: any, i: any) => {
            return (
              <li key={i} className={styles[`status-${inv.invoiceStatus.replace(' ', '')}`]}>
                <button onClick={() => openInvoice(inv.invoiceId, inv.invoiceStatus)}>
                  <span className={styles.description}>{matchInvoiceDescriptionToType(inv)}</span>
                  <span className={styles.amount}>
                    <CurrencyText>{inv.balance}</CurrencyText>
                  </span>
                </button>
              </li>
            )
          })}
        </ul>
        <Segment state={getClosingBalance().props.children <= 0 ? 'default' : 'danger'}>
          <div className={styles['closing-balance']}>
            <Segment.Label text="Closing Balance" />
            <span className={styles['amount']}>{getClosingBalance()}</span>
          </div>
        </Segment>
      </section>
    )
  )
}

export default subscribe(
  ActiveInvoicesProvider,
  ({ getCustomerSummary, getCustomerActiveInvoicesTotal, fetchInvoiceTypes, invoiceTypes, activeInvoices }: any) => ({
    getCustomerSummary,
    getCustomerActiveInvoicesTotal,
    fetchInvoiceTypes,
    invoiceTypes,
    activeInvoices,
  }),
)(ActiveInvoices)
