import { createSlice } from '@reduxjs/toolkit'
import { propertyApiEvents } from 'modules/api/property'

const { actions, reducer } = createSlice({
  name: 'ui/propertySearch',
  initialState: {
    isSearching: false,
    query: '',
  },
  reducers: {
    propertySearchQuery: (state, { payload }) => {
      state.isSearching = true
      state.query = payload
    },
    propertyCombinedSearchQuery: (state, { payload }) => {
      state.isSearching = true
      state.query = payload
    },
  },
  extraReducers: {
    // @ts-expect-error
    [(propertyApiEvents.search_success, propertyApiEvents.combinedSearch_success)]: state => {
      state.isSearching = false
    },
  },
})

export { actions as propertySearchEvents, reducer as propertySearchReducer }
