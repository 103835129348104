import React, { useState } from 'react'
import { stringUtils } from '../../../../../../utils'
import Button from '../../../../atoms/Button/Button'
import styles from './Account.module.scss'
import WithdrawFundsModal from '../../../Modals/WithdrawFundsModal/WithdrawFundsModal'
import CurrencyText from '../../../../atoms/Typography/CurrencyText/CurrencyText'

type TProps = {
  balance: number
  bankName: string
  number: number
  canPayout: boolean
  openStatement: () => void
  handlePayout: () => void
}

const Account = ({ balance, bankName, number, canPayout, openStatement, handlePayout }: TProps): React.ReactElement => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [hasPayoutRequested, setHasPayoutRequested] = useState(false)

  const toggleConfirmation = () => {
    if (canPayout || !hasPayoutRequested) {
      setIsConfirmationOpen(!isConfirmationOpen)
    }
  }

  const onPayout = () => {
    setHasPayoutRequested(true)
    handlePayout()
    closeConfirmation()
  }

  const closeConfirmation = () => setIsConfirmationOpen(false)

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <h2>Commission Wallet</h2>
        <span>
          <CurrencyText>{hasPayoutRequested ? 0 : balance}</CurrencyText>
        </span>
      </div>
      <div className={styles.wallet}>
        <div className={styles.account}>
          <span className={styles.label}>Linked account</span> <br />
          <span>
            {bankName}
            {stringUtils.obfuscate(number.toString(), 4)}
          </span>
        </div>
        <Button size="sm" onClick={toggleConfirmation} disabled={!canPayout || hasPayoutRequested}>
          Withdraw Funds
        </Button>
      </div>
      <div className={styles['statement-download']}>
        <Button pill tertiary size="sm" onClick={openStatement}>
          View statement
        </Button>
      </div>
      <WithdrawFundsModal
        isOpen={isConfirmationOpen}
        balance={balance}
        bankName={bankName}
        number={number}
        onClose={closeConfirmation}
        onCancel={toggleConfirmation}
        onConfirm={onPayout}
      />
    </div>
  )
}

export default Account
