import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import createSelector from 'selectorator'
import { path, pathOr, identity, find, map, prop, sort, sortBy, compose, concat, findLast } from 'ramda'
import { NAMESPACE } from './constants'
import { $TSFixMe } from 'types/ts-migrate'
import { sortByMainTextUnitNumber } from 'utils/array'

/**
 * Seems to be a ciruclar import somewhere when importing from root
 * Importing events directly for now
 */
// import { portfolioEvents } from '../portfolio'
// import { events as portfolioEvents } from '../portfolio/state'

const initialState: $TSFixMe = {
  googlePlaces: {}, // fetched google place entities
  properties: {}, // fetched property entities
  lightstoneProperties: {}, // fetched lightstone property entities
  summaries: {}, // GET properties response
  searchResults: {
    entities: {},
    byQuery: {},
  },
  portfolios: {}, // portfolios index by property ID
}

const propertyApiSlice = createSlice({
  name: 'propertyApi',
  initialState,
  reducers: {
    createProperty_request: (state, payload) => {},
    createProperty_success: (state, { payload }) => {
      state.properties[payload.id] = payload
    },
    updateProperty_request: (state, payload) => {},
    updateProperty_success: (state, { payload }) => {
      state.properties[payload.id] = payload
    },
    properties_request: state => {},
    properties_success: (state, { payload }) => {
      payload.forEach((p: any) => {
        state.summaries[p.propertyId] = p
      })
    },
    search_request: (state, payload) => {},
    search_success: (state, { payload: { response, query } }) => {
      state.searchResults.entities[query] = {
        properties: response,
      }
      state.searchResults.byQuery[query] = {
        results: response.map(({ id, placeId, lightstoneId }) => {
          if (id) {
            return { id }
          }
          if (placeId) {
            return { placeId }
          }
          if (lightstoneId) {
            return { lightstoneId }
          }
        }),
      }
    },
    combinedSearch_request: (state, payload) => {},
    combinedSearch_success: (state, { payload: { response, query } }) => {
      const { googleProperties } = response
      state.searchResults.entities[query] = {
        places: googleProperties,
      }
      state.searchResults.byQuery[query] = {
        places: googleProperties.map((p: any) => p.placeId),
      }
    },
    place_request: (state, payload) => {},
    place_success: (state, { payload: { placeId, response } }) => {
      state.googlePlaces[placeId] = response
    },
    property_request: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string
      }>,
    ) => {},
    property_success: (state, { payload }) => {
      state.properties[payload.id] = payload
    },
    lightstoneProperty_request: (
      state,
      {
        payload,
      }: PayloadAction<{
        lightstoneId: string
      }>,
    ) => {},
    lightstoneProperty_success: (state, { payload }) => {
      state.lightstoneProperties[payload.lightstoneId] = payload
    },
    propertyPortfolios_request: (state, payload) => {},
    propertyPortfolios_success: (state, { payload: { response, propertyId } }) => {
      state.portfolios[propertyId] = state.portfolios[propertyId] || []
      response.forEach((p: any) => {
        !state.portfolios[propertyId].includes(p.id) && state.portfolios[propertyId].push(p.id)
      })
    },
  },
  extraReducers: {
    /** @todo fix circular dependency, temporarily hardcode event name. */
    // [portfolioEvents.amendProperty_success]: (state, { payload, meta }) => {
    'portfolioApi/amendProperty_success': (state, { payload, meta }) => {
      const { id, propertyId } = payload
      const { previousPropertyId } = meta
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state.portfolios[previousPropertyId]
      state.portfolios[propertyId] = state.portfolios[propertyId] || []
      state.portfolios[propertyId].push(id)
    },
  },
})

/**
 * Selector Helpers
 */
const getState = (state: any): $TSFixMe => state
const getSlice = (key: string): $TSFixMe => createSelector([`${NAMESPACE}.${key}`], identity)
const getEntityById = (entitiesSelector: any): $TSFixMe =>
  createSelector([entitiesSelector], entities => (id: any) => path([id], entities))

/**
 * Selectors
 */
export const searchPlaceDetails = (placeDetails: any, detail: any, detailIndex?: any, value?: any) => {
  const comp =
    detailIndex === 1
      ? findLast((component: any) => {
          const valueCheck = (v: any) => value === undefined || value(v)
          return find((type: any) => type.label === detail && valueCheck(type.value), component.types) !== undefined
        }, placeDetails.addressComponents)
      : find((component: any) => {
          const valueCheck = (v: any) => value === undefined || value(v)
          return find((type: any) => type.label === detail && valueCheck(type.value), component.types) !== undefined
        }, placeDetails.addressComponents)
  return comp === undefined ? '' : comp.longName
}

const selectors: $TSFixMe = {}
selectors.getGooglePlaces = getSlice('googlePlaces')
selectors.getGooglePlaceById = getEntityById(selectors.getGooglePlaces)

selectors.getProperties = getSlice('properties')
selectors.getPropertyById = getEntityById(selectors.getProperties)

selectors.getLightstoneProperties = getSlice('lightstoneProperties')
selectors.getLightstonePropertyById = getEntityById(selectors.getLightstoneProperties)

const getPropertyFieldById = createSelector(
  [getState],
  state => (id: any, field: any) => path([field], selectors.getPropertyById(state)(id)),
)
selectors.getPropertyFieldById = getPropertyFieldById
selectors.getPropertyOwnerId = createSelector(
  [getState],
  state => (id: any) => getPropertyFieldById(state)(id, 'owner'),
)
selectors.getPropertyPlaceId = createSelector(
  [getState],
  state => (id: any) => getPropertyFieldById(state)(id, 'placeId'),
)
selectors.getPropertyLightstoneId = createSelector(
  [getState],
  state => (id: any) => getPropertyFieldById(state)(id, 'lightstoneId'),
)
const getAddressComponentValueByType = (entity: any, type: any, level?: $TSFixMe): $TSFixMe => {
  const addressComponents = pathOr([], ['addressComponents'], entity)
  // @ts-expect-error
  const component = find((comp: any) => map(prop('label'), comp.types).includes(type), addressComponents)
  return pathOr('', ['longName'], component)
}
selectors.getPropertyAddressComponentValueByType = createSelector(
  [getState],
  state => (id: any, type: any, predicate?: (v: any) => any) => {
    const property = selectors.getPropertyById(state)(id)
    return property ? searchPlaceDetails(property, type, predicate) : null
  },
)
selectors.getPlaceAddressComponentValueByType = createSelector([getState], state => (id: any, type: any) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'getGooglePlaceById' does not exist on ty... Remove this comment to see the full error message
  return getAddressComponentValueByType(place, type)
})
selectors.getBuildingNameById = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'BuildingName', 1),
)
selectors.getUnitNumberById = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'UnitNumber', 1),
)
selectors.getSchemeNumberById = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'SchemeNumber'),
)
selectors.getSchemeNameById = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'SchemeName'),
)
selectors.getPropertyStreetNumber = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'StreetNumber'),
)
selectors.getPropertyStreetName = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'StreetName'),
)
selectors.getPropertyStreetType = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'StreetType'),
)
selectors.getPropertySublocality = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'Sublocality'),
)
selectors.getPropertyStreetAddress = createSelector([getState], state => (id: any) => {
  const streetNumber: string = selectors.getPropertyStreetNumber(state)(id)
  const streetName: string = selectors.getPropertyStreetName(state)(id)
  const streetType: string = selectors.getPropertyStreetType(state)(id)
  const sublocality: string = selectors.getPropertySublocality(state)(id)
  return `${streetNumber} ${streetName} ${streetType}, ${sublocality}`
})
selectors.getPlaceStreetAddress = createSelector([getState], state => (id: any) => {
  const streetNumber: string = selectors.getPlaceAddressComponentValueByType(state)(id, 'StreetNumber')
  const streetName: string = selectors.getPlaceAddressComponentValueByType(state)(id, 'StreetName')
  const streetType: string = selectors.getPlaceAddressComponentValueByType(state)(id, 'StreetType')
  const sublocality: string = selectors.getPlaceAddressComponentValueByType(state)(id, 'Sublocality')
  return streetNumber && streetName && streetType && sublocality
    ? `${streetNumber} ${streetName} ${streetType}, ${sublocality}`
    : streetName && streetType && sublocality
    ? `${streetName} ${streetType}, ${sublocality}`
    : `${streetName} ${streetType}`
})
selectors.getPropertyBuildingAddress = createSelector([getState], state => (id: any) => {
  const unitNumber: string = selectors.getUnitNumberById(state)(id)
  const buildingName: string = selectors.getBuildingNameById(state)(id)
  const streetName: string = selectors.getPropertyStreetName(state)(id)
  const streetType: string = selectors.getPropertyStreetType(state)(id)
  const sublocality: string = selectors.getPropertySublocality(state)(id)
  return unitNumber && buildingName ? `${unitNumber} ${buildingName}, ${streetName} ${streetType}, ${sublocality}` : ''
})
selectors.getPropertyCity = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'Locality'),
)
selectors.getPropertyProvince = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'AdministrativeArea', 1),
)
selectors.getPropertyPostalCode = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'PostalCode'),
)
selectors.getPropertyCountry = createSelector(
  [getState],
  state => (id: any) => selectors.getPropertyAddressComponentValueByType(state)(id, 'Country'),
)
selectors.getPortfolios = getSlice('portfolios')

selectors.getSummaries = getSlice('summaries')
selectors.getSummaryById = getEntityById(selectors.getSummaries)

selectors.getSearchResults = getSlice('searchResults')
selectors.getSearchResultsByQuery = createSelector([selectors.getSearchResults], results => (query: any) => {
  return pathOr([], ['entities', query, 'properties'], results).map((r: any) => ({
    ...r,
    text: r.mainText,
  }))
})
selectors.getCombinedSearchResultsByQuery = createSelector([selectors.getSearchResults], results => (query: any) => {
  return pathOr([], ['entities', query, 'places'], results).map((r: any) => ({
    ...r,
    text: r.mainText,
  }))
})
selectors.getSearchResultsSortedByMainTextUnitNumber = createSelector(
  [selectors.getSearchResults],
  properties => (query: any) => {
    const originalResults = pathOr([], ['entities', query, 'properties'], properties).map((r: any) => ({
      ...r,
      text: r.mainText,
    }))
    const sortedResults = sort(sortByMainTextUnitNumber, originalResults)
    const sortByExistingWithId = sortedResults.filter((r: any) => r.id)
    const sortByExistingWithoutId = sortedResults.filter((r: any) => !r.id)
    return concat(sortByExistingWithId, sortByExistingWithoutId)
  },
)

/**
 * Exports
 * ---------
 */

const { reducer, actions: events } = propertyApiSlice

export { events, initialState, reducer, selectors }
