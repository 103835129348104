import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { moduleContext } from 'react-contextual'
import { path, pathOr, findIndex } from 'ramda'
import history from '../../state/history'
import { uiEvents } from '../ui'
import { chatbotSelectors, chatbotEvents } from '../ui/ChatBot'
import { layoutEvents, layoutSelectors } from '../Layout/state'
import { NAMESPACE } from './constants'
import { portfolioSelectors } from './state'
import { propertyApiSelectors, propertyApiEvents } from '../api/property'
import { portfolioApiEvents, portfolioApiSelectors, portfolioApiUtils } from '../api/portfolio'
import { invoiceApiEvents } from '../api/invoice'
import { reconApiEvents } from '../api/recon'
import { apiStateSelectors } from '../api/apiState'
import { partyApiEvents, partyApiSelectors } from '../api/party'
import { getCurrentLeaseType } from '../ui/ChatBot/Dialogs/LeaseTypeSelection'
import { userApiSelectors } from '../api/user'
import { $TSFixMe } from 'types/ts-migrate'

const PortfoliosProvider = moduleContext()(({ context, children, ...props }: $TSFixMe) => {
  const store = useStore()
  const state = store.getState()
  const dispatch = useDispatch()

  const { match } = props
  const { params } = match
  const id = pathOr('', ['id'], params)
  const location = useSelector(state => pathOr('', ['router.location'], state))
  const portfolio = useSelector(state => portfolioApiSelectors.getPortfolioById(state)(id))
  const portfolioPropertyId: string | undefined = path(['propertyId'], portfolio)
  const propertyPortfolios = useSelector(state =>
    portfolioApiSelectors.getPortfoliosByPropertyId(state)(path(['propertyId'], portfolio)),
  )

  const resetLayout = () => {
    dispatch(layoutEvents.closePrimaryPanel(NAMESPACE))
    dispatch(layoutEvents.openSidebar('global'))
  }

  /**
   * @todo move this into focus mode/chatbot specific provider
   */
  const currentPortfolioId = useSelector(chatbotSelectors.getCurrentPortfolioId)
  const currentProperty = useSelector(state => propertyApiSelectors.getPropertyById(state)(portfolioPropertyId))
  const selectedPortfolio = useMemo(() => {
    return findIndex((portfolio: any) => portfolio.id === id, propertyPortfolios)
  }, [id, propertyPortfolios])

  const tenants = useSelector(state => portfolioApiSelectors.getTenantsByPortfolioId(state)(currentPortfolioId))
  const owners = useSelector(state => portfolioApiSelectors.getOwnersByPortfolioId(state)(currentPortfolioId))
  const applications = useSelector(state =>
    portfolioApiSelectors.getApplicationsByPortfolioId(state)(currentPortfolioId),
  )

  const portfoliosStore = {
    match,
    location,
    isReadOnly: useSelector(userApiSelectors.isReadOnlyRole),
    viewData: useSelector(state => portfolioSelectors.getPortfolioViewData(state)(id)),
    filters: useSelector(portfolioSelectors.getPortfolioFilters),
    propertyFilters: useSelector(portfolioSelectors.getPropertyFilters),
    /** @todo rename once chatbot stuff is moved out */
    portfolioViewId: id,
    portfolioStatus: useSelector(state => portfolioApiSelectors.getPortfolioStatusLabel(state)(id)),
    currentPortfolioId,
    currentLeaseType: useSelector(getCurrentLeaseType),
    currentProperty,
    initialValuesForEdit: useSelector(state => portfolioSelectors.getInitialValuesForEdit(state)(portfolioPropertyId)),
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'router' does not exist on type 'DefaultR... Remove this comment to see the full error message
    pathname: useSelector(state => state.router.location.pathname),
    primaryPanel: useSelector(state => layoutSelectors.getPrimaryPanel(state)(NAMESPACE)),
    PortfolioWizardSidebarItems: useSelector(chatbotSelectors.getPortfolioNavbarItems),
    isPortfolioViewLoading: useSelector(state =>
      apiStateSelectors.isLoading(state)([
        portfolioApiEvents.portfolio_request,
        partyApiEvents.party_request,
        portfolioApiEvents.getApplications_request,
      ]),
    ),
    rentInvoiceTemplateSchedule: useSelector(state =>
      portfolioApiSelectors.getRentTemplateScheduleByPortfolioId(state)(currentPortfolioId),
    ),
    propertyDescription: useSelector(state => portfolioApiSelectors.getPortfolioMetaData(state)(id)),
    streetAddress: useSelector(state => propertyApiSelectors.getPropertyStreetAddress(state)(portfolioPropertyId)),
    buildingAddress: useSelector(state => propertyApiSelectors.getPropertyBuildingAddress(state)(portfolioPropertyId)),
    tenants,
    owners,
    applications,

    // Portfolio status selectors
    getPortfolioStatusLabel: (portfolioId: any, useSummary: any) =>
      portfolioApiSelectors.getPortfolioStatusLabel(state)(portfolioId, useSummary),
    getPortfolioStatusDescription: (portfolioId: any, useSummary: any) =>
      portfolioApiSelectors.getPortfolioStatusDescription(state)(portfolioId, useSummary),
    getTerminatedPortfolioVacateDate: (portfolioId: any, useSummary: any) =>
      portfolioApiSelectors.getTerminatedPortfolioVacateDate(state)(portfolioId, useSummary),
    getTerminatedPortfolioTerminatedReason: (portfolioId: any, useSummary: any) =>
      portfolioApiSelectors.getTerminatedPortfolioTerminatedReason(state)(portfolioId, useSummary),
    getTerminatedPortfolioTerminatedDate: (portfolioId: any, useSummary: any) =>
      portfolioApiSelectors.getTerminatedPortfolioTerminatedDate(state)(portfolioId, useSummary),
    isPortfolioExpired: (portfolioId: any, useSummary: any) =>
      portfolioApiSelectors.isPortfolioExpired(state)(portfolioId, useSummary),
    isPortfolioExpiring: (portfolioId: any, useSummary: any) =>
      portfolioApiSelectors.isPortfolioExpiring(state)(portfolioId, useSummary),
    isDraftPortfolio: (portfolioId: any, useSummary: any) =>
      portfolioApiSelectors.isDraftPortfolio(state)(portfolioId, useSummary),
    isActivePortfolio: (portfolioId: any, useSummary: any) =>
      portfolioApiSelectors.isActivePortfolio(state)(portfolioId, useSummary),

    // Current property view
    selectedPortfolio,

    resetLayout,
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    /** @todo move to general app provider */
    goBack: () => history.goBack(),
    openEditPortfolio: (id: any) => {
      dispatch(uiEvents.redirect(`/leases/${id}/edit/property`))
    },
    openRenewLease: (id: any) => {
      dispatch(uiEvents.redirect(`/leases/${id}/edit/renewal`))
    },
    openLeaseTermination: (id: any) => {
      dispatch(uiEvents.redirect(`/leases/${id}/edit/termination`))
    },
    openEditProperty: (id: any) => {
      dispatch(uiEvents.redirect(`/leases/${id}/property/edit`))
    },
    openPortfolio: (id: any) => {
      dispatch(layoutEvents.closeSidebar('global'))
      dispatch(layoutEvents.openPrimaryPanel(NAMESPACE))
      dispatch(uiEvents.redirect(`/leases/${id}`))
    },
    openPortfolioStatement: () => {
      dispatch(uiEvents.redirect(`/leases/${id}/statement`))
    },
    closeProperty: () => {
      dispatch(uiEvents.redirect('/leases'))
      resetLayout()
    },
    fetchCurrentProperty: () => {
      portfolioPropertyId && dispatch(propertyApiEvents.property_request({ id: portfolioPropertyId }))
    },
    fetchPropertyPortfolios: () => {
      portfolioPropertyId && dispatch(propertyApiEvents.propertyPortfolios_request({ id: portfolioPropertyId }))
    },
    updateCurrentProperty: (values: any) => {
      dispatch(propertyApiEvents.updateProperty_request({ id: portfolioPropertyId, values }))
    },
    onQueryChange: (e: any) => {},
    setCurrentPortfolioId: (newId: any, currentId: any) => {
      if (newId === 'new' || newId === undefined || newId === null) {
        return
      }
      if (newId === currentId) {
        return
      }
      dispatch(chatbotEvents.loadPortfolioRequested(newId))
    },
    fetchInvoiceSchedules: () => dispatch(portfolioApiEvents.invoiceSchedules_request(currentPortfolioId)),
    resetCurrentPortfolio: () => dispatch(chatbotEvents.resetCurrentPortfolio()),
    activateCurrentPortfolio: () => dispatch(chatbotEvents.currentPortfolioActivationRequested()),
    triggerActivationConfirmation: () => dispatch(chatbotEvents.currentPortfolioActivationConfirmationTriggered()),
    declineActivationConfirmation: () => dispatch(chatbotEvents.currentPortfolioActivationDeclined()),
    deleteDraftLease: (id: any) => dispatch(portfolioApiEvents.deleteDraftLease_request(id)),
    fetchDraftInvoices: () => dispatch(invoiceApiEvents.draftInvoices_request({ id })),
    fetchActiveInvoices: () => dispatch(reconApiEvents.invoicesActive_request({ id })),
    openActiveInvoices: () => {
      dispatch(uiEvents.redirect(`/invoices/active?filter=overdue&portfolioId=${id}`))
      dispatch(layoutEvents.openSidebar('global'))
    },
    openDraftInvoices: () => {
      dispatch(uiEvents.redirect(`/invoices/drafts?filter=notset&portfolioId=${id}`))
      dispatch(layoutEvents.openSidebar('global'))
    },
    makeLandlordPrimary: useCallback(
      partyId => {
        dispatch(
          portfolioApiEvents.updateOwnerParties_request({
            params: { id },
            body: portfolioApiUtils.setPrimaryParty(owners, partyId),
          }),
        )
      },
      [dispatch, id, owners],
    ),
    makeTenantPrimary: useCallback(
      partyId => {
        dispatch(
          portfolioApiEvents.updateTenantParties_request({
            params: { id },
            body: portfolioApiUtils.setPrimaryParty(tenants, partyId),
          }),
        )
      },
      [dispatch, id, tenants],
    ),
    removeLandlordFromLease: useCallback(
      partyId => {
        dispatch(
          portfolioApiEvents.updateOwnerParties_request({
            params: { id },
            body: portfolioApiUtils.removeSecondaryParty(owners, partyId),
          }),
        )
      },
      [dispatch, id, owners],
    ),
    removeTenantFromLease: useCallback(
      partyId => {
        dispatch(
          portfolioApiEvents.updateTenantParties_request({
            params: { id },
            body: portfolioApiUtils.removeSecondaryParty(tenants, partyId),
          }),
        )
      },
      [dispatch, id, tenants],
    ),
    addLandlordToLease: useCallback(
      values => {
        dispatch(portfolioApiEvents.addPartyAndLinkToLease_request({ id, values, type: 'landlord' }))
      },
      [dispatch, id],
    ),
    addTenantToLease: useCallback(
      values => {
        dispatch(portfolioApiEvents.addPartyAndLinkToLease_request({ id, values, type: 'tenant' }))
      },
      [dispatch, id],
    ),
    addApplicationToLease: useCallback(
      (values, applicationFee) => {
        dispatch(portfolioApiEvents.addApplicationAndLinkToLease_request({ id, values, applicationFee }))
      },
      [dispatch, id],
    ),
    ...props,
  }
  return <context.Provider value={portfoliosStore}>{children}</context.Provider>
})

export default PortfoliosProvider
