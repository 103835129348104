import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import { isEqual, isEmpty, get } from 'lodash-es'
import { InvoicesProvider } from '../../../../../providers'
import {
  // Button,
  Segment,
  CurrencyText,
} from '../../../../../components'
import Close from '../../../../atoms/Svgs/Close'
import styles from './Snapshot.module.scss'
import { LayoutContext } from 'modules/Layout/LayoutProvider'
import { $TSFixMe } from 'types/ts-migrate'
import { TAccount } from 'views/components/molecules/AccountSelector/AccountSelector'
import ActiveInvoicesProvider from './ActiveInvoicesProvider'
import ActiveInvoices from './ActiveInvoices'
import { splitCamelCaseIntoClass } from 'utils/string'
import { addHours, format } from 'date-fns'
import { prop, sortBy } from 'ramda'

const propTypes = {
  redirect: PropTypes.func,
}

const defaultProps = {}

@subscribe(
  [InvoicesProvider, LayoutContext],
  (
    {
      currentInvoice,
      fetchInvoiceActivity,
      fetchInvoiceParty,
      fetchPortfolio,
      getCustomerSummary,
      getCustomerRecentActivity,
      getCustomerActiveInvoicesTotal,
      getPartyById,
      getPartyEmailById,
      getPartyTelNumberById,
      openContact,
    },
    { secondaryPanel },
  ) => ({
    currentInvoice,
    fetchInvoiceActivity,
    fetchInvoiceParty,
    fetchPortfolio,
    getCustomerSummary,
    getCustomerRecentActivity,
    getCustomerActiveInvoicesTotal,
    getPartyById,
    getPartyEmailById,
    getPartyTelNumberById,
    openContact,
    secondaryPanel,
  }),
)
class Snapshot extends Component {
  componentDidUpdate(prevProps: any): void {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentInvoice' does not exist on type '... Remove this comment to see the full error message
    const { currentInvoice, fetchInvoiceActivity, fetchInvoiceParty, fetchPortfolio } = this.props
    if (currentInvoice && !isEqual(prevProps.currentInvoice, currentInvoice)) {
      fetchInvoiceActivity(currentInvoice.customerId, currentInvoice.accountId)
      fetchInvoiceParty(currentInvoice.customerId)
      fetchPortfolio(currentInvoice.portfolioId)
    }
  }

  getCustomerSummary = (): $TSFixMe => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentInvoice' does not exist on type '... Remove this comment to see the full error message
    const { currentInvoice, getCustomerSummary } = this.props
    return getCustomerSummary(currentInvoice.customerId, currentInvoice.accountId)
  }

  getCustomerName = (): React.ReactElement => {
    const summary = this.getCustomerSummary()
    return <span>{!isEmpty(summary) ? summary.customerName : ''}</span>
  }

  getAvailableFunds = (): React.ReactElement => {
    const summary = this.getCustomerSummary()
    return <CurrencyText>{!isEmpty(summary) ? summary.availableFunds : 0}</CurrencyText>
  }

  getAmountOwing = (): React.ReactElement => {
    const summary = this.getCustomerSummary()
    return <CurrencyText>{!isEmpty(summary) ? summary.amountOutstanding : 0}</CurrencyText>
  }

  getClosingBalance = (): React.ReactElement => {
    return (
      <CurrencyText>{this.getOpenInvoiceTotal().props.children - this.getAvailableFunds().props.children}</CurrencyText>
    )
  }

  getOpenInvoiceTotal = (): React.ReactElement => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentInvoice' does not exist on type '... Remove this comment to see the full error message
    const { currentInvoice, getCustomerActiveInvoicesTotal } = this.props
    return (
      <CurrencyText>
        {!currentInvoice ? 0 : getCustomerActiveInvoicesTotal(currentInvoice.customerId, currentInvoice.accountId)}
      </CurrencyText>
    )
  }

  render(): null | React.ReactElement {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentInvoice' does not exist on type '... Remove this comment to see the full error message
      currentInvoice: invoice,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getCustomerRecentActivity' does not exis... Remove this comment to see the full error message
      getCustomerRecentActivity,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getPartyById' does not exis... Remove this comment to see the full error message
      getPartyById,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getPartyEmailById' does not exist on typ... Remove this comment to see the full error message
      getPartyEmailById,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getPartyTelNumberById' does not exist on... Remove this comment to see the full error message
      getPartyTelNumberById,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'secondaryPanel' does not exist on type '... Remove this comment to see the full error message
      secondaryPanel,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'openContact' does not exist on type '... Remove this comment to see the full error message
      openContact,
    } = this.props

    if (!invoice) {
      return null
    }

    const customerEmail = getPartyEmailById(invoice.customerId)
    const customerTelNumber = getPartyTelNumberById(invoice.customerId)

    const mapAccountFields = (account: $TSFixMe): TAccount => ({
      id: account.accountId,
      value: account.accountId,
      label: account.propertyAddress ? account.propertyAddress : 'No attached lease',
      tag: account.tag,
      portfolioId: account?.portfolioId,
      paymentReference: account.paymentReference,
    })

    const currentParty = getPartyById(invoice.customerId)
    const accounts = Object.keys(currentParty).includes('company')
      ? get(currentParty, 'company.accounts', []).map(mapAccountFields)
      : get(currentParty, 'person.accounts', []).map(mapAccountFields)
    const account = accounts?.find(acc => acc.id === invoice.accountId)

    const customerPaymentReference = get(account, 'paymentReference', '')

    return (
      <div className={styles.root}>
        <header>
          <div className={styles.controls}>
            {customerPaymentReference && <span className={styles.paymentReference}>{customerPaymentReference}</span>}
            <button className={styles.close} onClick={secondaryPanel.close}>
              <Close />
            </button>
          </div>

          <div className={styles.customer}>
            <h2>
              <button onClick={() => openContact(invoice.customerId)}>{this.getCustomerName()}</button>
            </h2>
            <p>
              {customerEmail} <br />
              {/** @todo create formatPhone util */}
              {customerTelNumber && `${customerTelNumber as string}`}
            </p>
          </div>
        </header>

        <Segment.Group>
          {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; hortizontal: true; }' i... Remove this comment to see the full error message */}
          <Segment hortizontal>
            <div className={styles['available-funds']}>
              <Segment.Label text="Available Funds" />
              <span className={styles.amount}>{this.getAvailableFunds()}</span>
            </div>
          </Segment>

          <Segment>
            <div className={styles['open-invoices']}>
              <header>
                <Segment.Label text="Active Invoices" />
                <span className={styles.amount}>{this.getOpenInvoiceTotal()}</span>
              </header>
              <ActiveInvoicesProvider customerId={invoice.customerId} accountId={invoice.accountId}>
                <ActiveInvoices />
              </ActiveInvoicesProvider>
            </div>
          </Segment>

          <Segment state={this.getClosingBalance().props.children <= 0 ? 'default' : 'danger'}>
            <div className={styles['closing-balance']}>
              <Segment.Label text="Closing Balance" />
              <span className={styles['amount']}>{this.getClosingBalance()}</span>
            </div>
          </Segment>
        </Segment.Group>

        <Segment.Group>
          <Segment>
            <React.Fragment>
              <Segment.Label text="Wallet Activity" />
              <ul className={styles['recent-activity']}>
                {getCustomerRecentActivity(invoice.customerId, invoice.accountId).map((ra: any) => (
                  <li
                    key={ra.sourceId}
                    className={
                      ra.description === 'Payment Reversed'
                        ? styles['payment-reversed']
                        : styles[splitCamelCaseIntoClass(ra.sourceType)]
                    }
                  >
                    <div className={styles.description}>
                      <strong>{ra.description === 'Funds received' ? 'Funds Received' : ra.description}</strong>
                      <time>
                        {ra.description === 'Funds received'
                          ? format(new Date(ra.date), 'yyyy-MM-dd')
                          : format(addHours(new Date(ra.date), 2), 'yyyy-MM-dd HH:mm')}
                      </time>
                    </div>
                    <span className={styles.amount}>
                      <CurrencyText>{ra.amount}</CurrencyText>
                    </span>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          </Segment>
        </Segment.Group>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Snapshot.propTypes = propTypes
// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Snapshot.defaultProps = defaultProps

export default Snapshot
