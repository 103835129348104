import { path } from 'ramda'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { $TSFixMe } from 'types/ts-migrate'
import { portfolioApiEvents, portfolioApiSelectors } from '.'
import { propertyApiEvents, propertyApiSelectors } from '../property'

/**
 * Ensures the portfolio and property is fetched and returns useful property fields
 *
 * @param {string} portfolioId Portfolio UUID
 */
export const usePortfolioProperty = (portfolioId: any): $TSFixMe => {
  const dispatch = useDispatch()
  const portfolio = useSelector(state => portfolioApiSelectors.getPortfolioById(state)(portfolioId)) as $TSFixMe
  const propertyId: string | undefined = path(['propertyId'], portfolio)
  const propertyStreetAddress = useSelector(state => propertyApiSelectors.getPropertyStreetAddress(state)(propertyId))

  useEffectOnce(() => {
    if (!portfolio && portfolioId) {
      dispatch(portfolioApiEvents.portfolio_request(portfolioId))
    }
  })

  useEffect(() => {
    if (!propertyStreetAddress && propertyId) {
      dispatch(propertyApiEvents.property_request({ id: propertyId }))
    }
  }, [propertyStreetAddress, dispatch, propertyId])

  return {
    propertyId,
    propertyStreetAddress: portfolio && propertyStreetAddress,
  }
}
