import React from 'react'
import { PropertySearch } from '../../../../containers'
import { Grid, Column } from '../../..'
import styles from './PropertyForm.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

const SearchProperties = ({ isReadonly }: $TSFixMe): React.ReactNode => {
  return (
    <div className={styles.root}>
      <div className={styles.form}>
        <div className={styles.body}>
          <Grid>
            <Column xs={12} md={8} flexOptions="padding-r-1">
              <PropertySearch isReadonly={isReadonly} />
            </Column>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default SearchProperties
