import React from 'react'
import { subscribe } from 'react-contextual'
import { addHours, format } from 'date-fns'
import { Button, CurrencyText } from '../../../../../../views/components'
import ContactsProvider from '../../../../state/ContactsProvider'
import RecentActivityProvider from './RecentActivityProvider'
import styles from './RecentActivity.module.scss'
import { splitCamelCaseIntoClass } from 'utils/string'

const RecentActivity = ({ recentActivity }: any) => {
  const limitedRecentActivity = recentActivity.slice(0, 10)
  return (
    <>
      {limitedRecentActivity.length > 0 && (
        <section className={styles.root}>
          <header>
            <span>Recent Wallet Activity</span>
          </header>
          <ul className={styles['recent-activity']}>
            {limitedRecentActivity.map((ra: any, i: any) => (
              <li
                key={i}
                className={
                  ra.description === 'Payment Reversed'
                    ? styles['payment-reversed']
                    : styles[splitCamelCaseIntoClass(ra.sourceType)]
                }
              >
                <div className={styles.description}>
                  <strong>{ra.description === 'Funds received' ? 'Funds Received' : ra.description}</strong>
                  <time>
                    {ra.description === 'Funds received'
                      ? format(new Date(ra.date), 'yyyy-MM-dd')
                      : format(addHours(new Date(ra.date), 2), 'yyyy-MM-dd HH:mm')}
                  </time>
                </div>
                <span className={styles.amount}>
                  <CurrencyText>{ra.amount}</CurrencyText>
                </span>
              </li>
            ))}
          </ul>
        </section>
      )}
    </>
  )
}

export default subscribe(RecentActivityProvider, ({ recentActivity }: any) => ({
  recentActivity,
}))(RecentActivity)
