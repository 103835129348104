import React from 'react'
import styles from './Shortcut.module.scss'

type TProps = {
  icon: React.ReactNode
  title: string
  subtitle: string
  onClick: () => void
}

const Shortcut = ({ icon, title, subtitle, onClick }: TProps) => (
  <div className={styles.root}>
    <button onClick={onClick}>
      <span className={styles.icon}>{icon}</span>
      <div className={styles.text}>
        <h4>{title}</h4>
        <span>{subtitle}</span>
      </div>
    </button>
  </div>
)

export default Shortcut
