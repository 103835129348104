import createSelector from 'selectorator'
import { NAMESPACE } from './constants'
import { userApiSelectors } from '../user'
import { find, identity, path, pathOr } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'

/**
 * Get a slice of the agency API state
 * EG: getSlice('byId')
 *
 * @param {string} key Portfolios API state object key
 */
export const getSlice = (key: string): $TSFixMe => createSelector([`api.${NAMESPACE}.${key}`], identity)

export const agency = createSelector([`api.${NAMESPACE}`], identity)

export const byId = getSlice('byId')

export const allIds = getSlice('allIds')

export const invitations = getSlice('invitations')

export const getInvitationByToken = createSelector(
  [getSlice('invitationsByToken')],
  invitations => (token: any) => path([token], invitations),
)

export const signupInvite = getSlice('signupInvite')

export const getThemes = getSlice('themes')

export const getAgencies = getSlice('agencies')

export const getAgencyById = createSelector([byId], byId => (id: any) => path([id], byId))

export const getCurrentAgency = createSelector([identity], state => {
  const currentAgencyId = userApiSelectors.getCurrentAgencyId(state)
  return getAgencyById(state)(currentAgencyId)
})

const getCurrentAgencyField = createSelector([identity], state => (path: any, defaultVal = {}) => {
  const agency = getCurrentAgency(state)
  return pathOr(defaultVal, path, agency)
})

export const getCurrentAgencyDetails = createSelector([identity], state =>
  getCurrentAgencyField(state)(['agency', 'agencyDetails']),
)

export const getCurrentAgencyAddress = createSelector([identity], state =>
  getCurrentAgencyField(state)(['agency', 'agencyAddress']),
)

export const getCurrentAgencyBankDetails = createSelector([identity], state =>
  getCurrentAgencyField(state)(['agency', 'agencyBankDetails']),
)

export const getCurrentAgencyContactDetails = createSelector([identity], state =>
  getCurrentAgencyField(state)(['agency', 'agencyContactDetails']),
)

export const getCurrentAgencyTheme = createSelector([identity], state => getCurrentAgencyField(state)(['theme'], false))

export const getCurrentAgencyLogo = createSelector([identity], state => getCurrentAgencyField(state)(['logoUrl'], ''))

export const getAgencyLogo = createSelector([identity], state => (id: any) => {
  const agency = getAgencyById(state)(id)
  return pathOr('', ['logoUrl'], agency)
})

export const getAgencyTheme = createSelector([identity], state => (id: any) => {
  const agency = getAgencyById(state)(id)
  return pathOr(false, ['theme'], agency)
})

export const getAgencyName = createSelector([identity], state => (id: any) => {
  const agency = getAgencyById(state)(id)
  return pathOr('', ['agency', 'agencyDetails', 'companyName'], agency)
})

export const getAgencyTradingName = createSelector([identity], state => (id: any) => {
  const agency = getAgencyById(state)(id)
  return pathOr('', ['agency', 'agencyDetails', 'trading'], agency)
})

export const getCurrentAgencyName = createSelector([identity], state => {
  const agency = getCurrentAgency(state)
  return pathOr('', ['agency', 'agencyDetails', 'companyName'], agency)
})

export const getAgencyTradingOrCompanyName = createSelector([identity], state => (id: any) => {
  const agency = getAgencyById(state)(id)
  return (
    pathOr('', ['agency', 'agencyDetails', 'trading'], agency) ||
    pathOr('', ['agency', 'agencyDetails', 'companyName'], agency)
  )
})

export const getCurrentAgencyGlobalVatEnabled = createSelector([identity], state => {
  const agency = getCurrentAgency(state)
  return pathOr(false, ['agency', 'globalVatEnabled'], agency)
})

export const getCurrentAgencyIsTestAgency = createSelector([identity], state => {
  const agency = getCurrentAgency(state)
  return pathOr(false, ['agency', 'isTestAgency'], agency)
})

export const getAgencyListing = getSlice('agencyListing')

export const getAgencyFromListingById = createSelector(
  [getAgencyListing],
  listing => (id: any) => find(({ agencyId }: any) => agencyId === id)(listing),
)

/**
 * @todo move to UI state module
 */
export const getAgencyForSearchResult = createSelector([identity], state => (id: any) => {
  const agency = getAgencyById(state)(id)
  const companyName = pathOr('', ['agency', 'agencyDetails', 'companyName'], agency)
  return { id, text: companyName, partyTag: 'agency' }
})

export const getAgencyStats = getSlice('agencyStats')

export const getAgencyStatsChartData = createSelector([getAgencyStats], agencyStats =>
  pathOr([], ['chart', 'data'], agencyStats).reverse(),
)

export const getAgencyStatsChartDataForDays = getSlice('agencyStatsChartDataForDays')

export const getInvitableParties = getSlice('invitableParties')

export const getSegments = getSlice('segments')

export const getSegmentById = createSelector(
  [getSegments],
  segments => (id: any) => find((segment: any) => segment.id === id, segments),
)
