import { ofType } from 'redux-observable'
import { map, pluck, debounceTime } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { propertyApiEvents } from '../../api/property'
import { propertySearchEvents } from '.'
import { DEBOUNCE_TIME } from '../constants'

export const searchProperties = (action$: any, state$: any) =>
  action$.pipe(
    ofType(propertySearchEvents.propertySearchQuery),
    debounceTime(DEBOUNCE_TIME),
    pluck('payload'),
    map(query => propertyApiEvents.search_request({ query })),
    tag('PropertySearch/epics/searchProperties'),
  )

export const searchCombinedProperties = (action$: any, state$: any) =>
  action$.pipe(
    ofType(propertySearchEvents.propertyCombinedSearchQuery),
    debounceTime(DEBOUNCE_TIME),
    pluck('payload'),
    map(query => propertyApiEvents.combinedSearch_request({ query })),
    tag('PropertySearch/epics/searchCombinedProperties'),
  )
