import React from 'react'
import { useSelector } from 'react-redux'
import { $TSFixMe } from 'types/ts-migrate'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'
import { Button, Column, FormLoader, Grid, TextField, TextInput } from '../../..'
import { apiStateSelectors } from '../../../../../modules/api/apiState'
import { propertyApiEvents } from '../../../../../modules/api/property'
import styles from './PropertyForm.module.scss'
import { isEmpty } from 'ramda'

// eslint-disable-next-line react/display-name
const withButtonContainer =
  ({ clearSelection, isSubmitting }: $TSFixMe): $TSFixMe =>
  // eslint-disable-next-line react/display-name
  ({ children }: any): React.ReactElement =>
    (
      <div className={styles.footer}>
        {children}
        {!isSubmitting && (
          <Button style={{ marginLeft: '16px' }} size="sm" secondary onClick={clearSelection}>
            Change property address
          </Button>
        )}
        {/* <Button size='sm' secondary>Change property address</Button> */}
      </div>
    )

const PropertyPreview = ({
  initialValues,
  handleChange,
  handleBlur,
  values,
  clearSelection,
  handleConfirmation,
  isActivePortfolio,
}: any): React.ReactElement => {
  /**
   * @todo add portfolio creation here as well. Not available in apiState because of forkEpic
   * @link https://github.com/CastleOne/robin-client/blob/557e06304c5a211ebc7688757111b48bd7ae2505/src/modules/ui/ChatBot/portfolioEpics.js#L37
   */
  const isSubmitting = useSelector(state =>
    apiStateSelectors.isLoading(state)([propertyApiEvents.createProperty_request]),
  )
  const formLoaderState = isSubmitting && 'submitting'

  return (
    <div className={styles.root}>
      <FormLoader
        onSubmit={handleConfirmation}
        state={formLoaderState}
        buttonProps={{ children: isActivePortfolio ? 'Save' : 'Yes, looks good' }}
        ButtonContainer={withButtonContainer({ clearSelection, isSubmitting })}
      >
        <div className={styles.body}>
          <Grid>
            <Column xs={12} md={12} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="propertyDescription"
                    placeholder="e.g. Room 1, Granny Flat"
                    value={values.propertyDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={false}
                  />
                }
                label="Property Description (if Applicable)"
              />
            </Column>
            <Column xs={12} md={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="unitNumber"
                    placeholder="e.g. 4"
                    value={values.unitNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isEmpty(initialValues.placeId) && !initialValues.schemeNumber}
                  />
                }
                label="Unit Number (if Applicable)"
              />
            </Column>
            <Column xs={12} md={8} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="buildingName"
                    placeholder="e.g. Cardiff Castle"
                    value={values.buildingName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isEmpty(initialValues.placeId) && !initialValues.schemeName}
                  />
                }
                label="Building / Complex Name (if Applicable)"
              />
            </Column>
            <Column xs={12} md={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="schemeNumber"
                    value={values.schemeNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={true}
                  />
                }
                label="Sectional Scheme Number"
              />
            </Column>
            <Column xs={12} md={8} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="schemeName"
                    value={values.schemeName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={true}
                  />
                }
                label="Sectional Scheme Name"
              />
            </Column>
            <Column xs={12} md={8} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="streetAddress"
                    value={values.streetAddress}
                    disabled={!isEmpty(values.streetAddress)}
                  />
                }
                label="Street Address"
              />
            </Column>
            <Column xs={12} md={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="city"
                    value={values.city}
                    disabled={!isEmpty(values.city)}
                  />
                }
                label="City / Municipality"
              />
            </Column>
          </Grid>
          <Grid>
            <Column xs={12} md={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="province"
                    value={values.province}
                    disabled={!isEmpty(values.province)}
                  />
                }
                label="Province"
              />
            </Column>
            <Column xs={12} md={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="postalCode"
                    value={values.postalCode}
                    disabled={!isEmpty(values.postalCode)}
                  />
                }
                label="Postal Code"
              />
            </Column>
            <Column xs={12} md={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="country"
                    value={values.country}
                    disabled={!isEmpty(values.country)}
                  />
                }
                label="Country"
              />
            </Column>
          </Grid>
        </div>
      </FormLoader>
    </div>
  )
}

export default PropertyPreview
