import React from 'react'
import styles from './Updates.module.scss'
import { $TSFixMe } from 'types/ts-migrate'

type TUpdate = {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  update: {
    color: string
    count: number
    title: string
  }
  index: number
}

type TUpdateSection = {
  title: string
  updates: TUpdate[]
}

type TUpdatesSection = {
  count: number
  color: 'blue' | 'green' | 'yellow' | 'orange' | 'red'
  title: string
  onClick: () => void
}

type TUpdatesSections = {
  invoiceUpdates: TUpdatesSection[]
  paymentUpdates: TUpdatesSection[]
}

const Update = ({ handleClick, update, index }: TUpdate) => (
  <li key={index}>
    <button onClick={handleClick}>
      <span className={`${styles.count} ${styles[update.color]}`}>{update.count}</span>
      {update.title}
    </button>
  </li>
)

const UpdatesSection = ({ title, updates }: TUpdateSection) =>
  updates.length > 0 && (
    <div className={styles.root}>
      <h2>{title}</h2>
      <ul>
        {updates.map((u: $TSFixMe, i: number) => {
          const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
            e.preventDefault()
            u.onClick()
          }
          return <Update key={i} update={u} index={i} handleClick={handleClick} />
        })}
      </ul>
    </div>
  )

const Updates = ({ invoiceUpdates = [], paymentUpdates = [] }: TUpdatesSections) => (
  <>
    {/* @ts-expect-error ts-migrate(2786) FIXME: 'UpdatesSection' cannot be used as a JSX component... Remove this comment to see the full error message */}
    <UpdatesSection title="Draft Invoices" updates={invoiceUpdates} />
    {/* @ts-expect-error ts-migrate(2786) FIXME: 'UpdatesSection' cannot be used as a JSX component... Remove this comment to see the full error message */}
    <UpdatesSection title="Active Invoices" updates={paymentUpdates} />
  </>
)

export default Updates
