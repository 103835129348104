import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { connectRouter } from 'connected-react-router'
import history from './history'

// API modules
import apiReducer from '../modules/api/reducer'
import { agencyApiEpics } from '../modules/api/agency'
import { accountingApiEpics } from '../modules/api/accounting'
import { bankingApiEpics } from '../modules/api/banking'
import { emailApiEpics } from 'modules/api/email'
import { integrationsApiEpics } from 'modules/api/integrations'
import { invoiceApiEpics } from '../modules/api/invoice'
import { partyApiEpics } from '../modules/api/party'
import { propertyApiEpics } from '../modules/api/property'
import { portfolioApiEpics } from '../modules/api/portfolio'
import { reconApiEpics } from '../modules/api/recon'
import { reportingApiEpics } from '../modules/api/reporting'
import { userApiEpics, userApiEvents } from '../modules/api/user'
import { smsApiEpics } from '../modules/api/sms'
import { walletApiEpics } from '../modules/api/wallet'

// Feature modules
import { onboardingEpics } from '../modules/onboarding'
import { notificationEpics } from '../modules/notificationCenter'
import { creditNoteReducer } from '../modules/creditNote'
import { uiReducer, uiEpics } from '../modules/ui'

/**
 * @todo move to more specific feature modules
 */
import { uiInvoiceEpics } from '../modules/ui/invoices'
import { propertySearchEpics } from '../modules/ui/PropertySearch'
import { partySearchEpics } from '../modules/ui/PartySearch'
import { portfolioChatbotEpics, chatbotEpics, chatbotReducer } from '../modules/ui/ChatBot'
/**
 * not importing from index because components are export there
 * which loads containers prematurely?
 */
import * as contactsEpics from '../modules/contacts/state/epics'
import { portfolioEpics, portfolioReducer } from '../modules/portfolios/state'
import { supportStaffEpics } from '../modules/supportStaff/state'
import { bookSegmentsEpics, bookSegmentsReducer } from '../modules/bookSegments/state'
import { bulkImportInvoicesEpics, bulkImportInvoicesReducer } from '../modules/bulkImportInvoices/state'
import { communicationsApiEpics } from '../modules/communication/state'
import { walletTransferEpics, walletTransferReducer } from '../modules/wallets/walletTransfer/state'
import { adhocInvoiceReducer } from 'modules/adhocInvoice/state'
import { apiStateReducer } from 'modules/api/apiState'
import { suspenseAccountsReducer } from 'modules/suspenseAccounts/state'
import { contactsReducer } from 'modules/contacts/state'
import { layoutReducer } from 'modules/Layout/state'
import { notificationsReducer } from 'modules/notificationCenter/state'
import { reportingReducer } from 'modules/reporting/state'
import { supportStaffReducer } from 'modules/supportStaff/state'

const systemEpics = [
  accountingApiEpics,
  agencyApiEpics,
  bankingApiEpics,
  chatbotEpics,
  emailApiEpics,
  integrationsApiEpics,
  invoiceApiEpics,
  partyApiEpics,
  portfolioChatbotEpics,
  portfolioApiEpics,
  propertyApiEpics,
  reconApiEpics,
  reportingApiEpics,
  walletApiEpics,
  userApiEpics,
  uiInvoiceEpics,
  propertySearchEpics,
  partySearchEpics,
  contactsEpics,
  onboardingEpics,
  notificationEpics,
  smsApiEpics,
  uiEpics,
  portfolioEpics,
  supportStaffEpics,
  bookSegmentsEpics,
  bulkImportInvoicesEpics,
  communicationsApiEpics,
  walletTransferEpics,
].map(e => Object.values(e))

// @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
export const rootEpic = combineEpics(...[].concat(...systemEpics))

const appReducers = {
  router: connectRouter(history),
  api: apiReducer,
  chatbot: chatbotReducer,
  ui: uiReducer,
  creditNote: creditNoteReducer,
  walletTransfer: walletTransferReducer,
  adhocInvoice: adhocInvoiceReducer,
  apiState: apiStateReducer,
  suspenseAccounts: suspenseAccountsReducer,
  bookSegments: bookSegmentsReducer,
  bulkImportInvoices: bulkImportInvoicesReducer,
  contacts: contactsReducer,
  layout: layoutReducer,
  notificationCentre: notificationsReducer,
  portfolios: portfolioReducer,
  reporting: reportingReducer,
  supportStaff: supportStaffReducer,
}

export const createReducer = (state: any, action: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  if (action.type === userApiEvents.logout_success().type) {
    state = undefined
  }
  const rootReducer = combineReducers(appReducers)
  return rootReducer(state, action)
}
