import React from 'react'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { SuspenseAccountsContent } from '..'
import { superSupportAccessEmails } from 'constants/general'
import {
  TableProvider,
  LayoutProvider,
  Layout,
  LayoutBody,
  LayoutPrimaryPanel,
  LayoutContent,
} from '../../../views/components'
import { SupportStaffProvider } from '../../supportStaff'
import SuspenseAccountsProvider from '../SuspenseAccountsProvider'
import ViewSuspenseAccount from './ViewSuspenseAccount/ViewSuspenseAccount'

const SuspenseAccountsPage = ({ path, currentReference, isAccessAllowed }: any) => {
  return !isAccessAllowed(superSupportAccessEmails) ? (
    <Redirect
      to={{
        pathname: '/dashboard',
        state: { from: path },
      }}
    />
  ) : (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; stateKey: string; ini... Remove this comment to see the full error message
    <LayoutProvider
      stateKey="suspenseAccounts"
      initialProps={{
        sidebar: { open: false, width: 0 },
        primaryPanel: { open: currentReference !== undefined, width: 752 },
        secondaryPanel: { open: false, width: 375 },
      }}
    >
      <Helmet>
        <title>reOS | Admin | Suspense Account</title>
      </Helmet>
      <Layout>
        <LayoutBody>
          <LayoutPrimaryPanel>
            <ViewSuspenseAccount currentReference={currentReference} />
          </LayoutPrimaryPanel>
          <LayoutContent>
            <TableProvider>
              <SuspenseAccountsContent />
            </TableProvider>
          </LayoutContent>
        </LayoutBody>
      </Layout>
    </LayoutProvider>
  )
}

export default subscribe(
  [SuspenseAccountsProvider, SupportStaffProvider],
  ({ match, currentReference }: any, { isAccessAllowed }: any) => ({
    path: match.path,
    currentReference,
    isAccessAllowed,
  }),
)(SuspenseAccountsPage)
