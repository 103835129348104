import React, { useState } from 'react'
import cx from 'classnames'
import { get } from 'lodash-es'
import { Tooltip } from '../../..'
import styles from './Navigation.module.scss'

type TSubNavItem = {
  text: string
  route: string
  onClick?: () => void
  disabled?: boolean
}

type TNavSection = {
  text: string
  route?: string
  onClick?: () => void
  disabled?: boolean
  basepath?: string | undefined
  icon: React.ReactNode
  subNavItems?: TSubNavItem[]
}

type TProps = {
  redirect: (route: string) => void
  pathname: string
  navSections: TNavSection[][]
}

const Navigation = ({ redirect, pathname, navSections }: TProps) => {
  const [mouseOverItem, setMouseOverItem] = useState<string | null>(null)

  return (
    <div className={styles.navigation}>
      <nav>
        {navSections.map((section: TNavSection[], i: number) => (
          <ul key={i}>
            {section.map((item: TNavSection, index: number) => {
              let clickHandler = (): void => {}
              if (item.route) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                clickHandler = (): void => {
                  if (item.route) {
                    redirect(item.route)
                  }
                }
              } else if (typeof item.onClick === 'function') {
                clickHandler = item.onClick
              }
              const classes = cx({
                [styles.open]: pathname.includes(get(item, 'basepath', '')),
                [styles.active]: !item.subNavItems && item.route === pathname,
                [styles.disabled]: item.disabled,
              })

              const navItem = (
                <li
                  key={index}
                  className={classes}
                  onMouseOver={() => setMouseOverItem(item.text)}
                  onMouseLeave={() => setMouseOverItem(null)}
                >
                  <button onClick={item.disabled ? () => {} : clickHandler}>
                    {item.icon}
                    {item.text}
                  </button>
                  {item.subNavItems && item.subNavItems.length > 0 && (
                    <ul>
                      {item.subNavItems.map((subNavItem: TSubNavItem, sni: number) => (
                        <li key={sni}>
                          <button
                            onClick={() => !subNavItem.disabled && redirect(subNavItem.route)}
                            className={subNavItem.route === pathname ? styles.active : undefined}
                          >
                            {subNavItem.text}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )

              return !item.disabled ? (
                navItem
              ) : (
                <Tooltip
                  place="right"
                  body="Unavailable until you add your business details."
                  isOpen={mouseOverItem === item.text}
                >
                  {navItem}
                </Tooltip>
              )
            })}
          </ul>
        ))}
      </nav>
    </div>
  )
}

export default Navigation
