import { delay, map, mergeMap, pluck, switchMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { tag } from 'rxjs-spy/operators/tag'
import * as transformers from './transformers'
import { ENDPOINTS } from './constants'
import { multiUserOperators } from '../../multiUser/state'
import { walletApiEvents } from 'modules/api/wallet'
import { AnyAction } from 'redux'
import { $TSFixMe } from 'types/ts-migrate'
import { of } from 'rxjs'
import { downloadBase64 } from 'utils/fileUtils'

export const apiFetchBalance = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.balance_request),
    multiUserOperators.filterCurrentAgencyId(state$),
    mergeMap((action: $TSFixMe) =>
      get(ENDPOINTS.BALANCE, state$, action.payload).pipe(
        pluck('response'),
        map(res => walletApiEvents.balance_success(transformers.transformBalance(res), action.payload)),
        catchRestError(action),
      ),
    ),
    tag('wallet/epics/apiFetchBalance'),
  )

export const apiPayout = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.payout_request),
    switchMap((action: AnyAction) =>
      of(action).pipe(
        mergeMap(action =>
          post(ENDPOINTS.PAYOUT, state$, transformers.transformPayoutRequest(action.payload)).pipe(
            pluck('response'),
            map((response: $TSFixMe) =>
              walletApiEvents.payout_success(transformers.transformPayoutResponse({ ...response, ...action.payload })),
            ),
            catchRestError(action),
          ),
        ),
      ),
    ),
    tag('wallet/epics/apiPayout'),
  )

export const apiFetchPayments = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.payments_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.PAYMENTS, state$, action.payload).pipe(
        pluck('response'),
        map(res => walletApiEvents.payments_success(transformers.transformPayments(res))),
        catchRestError(action),
      ),
    ),
    tag('wallet/epics/apiFetchPayments'),
  )

export const apiDemandPayments = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.demandPayments_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.DEMAND, state$, action.payload).pipe(
        pluck('response'),
        map(() => walletApiEvents.demandPayments_success(action.payload)),
        catchRestError(action),
      ),
    ),
    tag('wallet/epics/apiDemandPayments'),
  )

export const apiReversePayments = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.reversePayments_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.REVERSE, state$, action.payload).pipe(
        pluck('response'),
        map(() => walletApiEvents.reversePayments_success(action.payload)),
        catchRestError(action),
      ),
    ),
    tag('wallet/epics/apiReversePayments'),
  )

export const apiTransferFunds = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.transfer_request),
    mergeMap((action: AnyAction) => {
      const { body, params, meta } = action.payload
      return post(ENDPOINTS.TRANSFER, state$, transformers.transformTransferRequest(body), params).pipe(
        pluck('response'),
        map(res => walletApiEvents.transfer_success(transformers.transformTransferResponse({ res, meta }))),
        catchRestError(action),
      )
    }),
    tag('wallet/epics/apiTransferFunds'),
  )

export const apiTransferWalletFunds = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.walletTransfer_request),
    mergeMap((action: AnyAction) => {
      return post(ENDPOINTS.WALLET_TRANSFER, state$, transformers.transformWalletTransferRequest(action.payload)).pipe(
        pluck('response'),
        map(walletApiEvents.walletTransfer_success),
        catchRestError(action),
      )
    }),
    tag('wallet/epics/apiTransferWalletFunds'),
  )

export const apiBulkTransferWalletFunds = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.bulkWalletTransfer_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.BULK_WALLET_TRANSFER, state$, body, params).pipe(
        pluck('response'),
        map((res: $TSFixMe) => {
          downloadBase64(res.csvBase64, 'csv', `BulkWalletTransfer-${action.payload.params.fromWalletRef}.csv`)
          return walletApiEvents.bulkWalletTransfer_success(res)
        }),
        catchRestError(action),
      )
    }),
    tag('wallet/epics/apiBulkTransferWalletFunds'),
  )

export const apiRefundAccount = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.refund_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return post(ENDPOINTS.REFUND, state$, transformers.transformRefundRequest(body), params).pipe(
        pluck('response'),
        map(res => walletApiEvents.refund_success(transformers.transformRefundResponse(res))),
        catchRestError(action),
      )
    }),
    tag('wallet/epics/apiRefundAccount'),
  )

export const apiSendPop = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.pop_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.POP, state$, action.payload).pipe(
        pluck('response'),
        map(res => walletApiEvents.pop_success(res)),
        catchRestError(action),
      ),
    ),
    tag('wallet/epics/apiSendPop'),
  )

export const apiCreateWallet = (action$: $TSFixMe, state$: $TSFixMe, { post, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.createWallet_request),
    mergeMap((action: AnyAction) =>
      post(ENDPOINTS.CREATE_WALLET, state$, action.payload).pipe(
        pluck('response'),
        map(res => walletApiEvents.createWallet_success(res)),
        catchRestError(action),
      ),
    ),
    tag('wallet/epics/apiCreateWallet'),
  )

export const apiGetUnallocatedSuspenseAccounts = (
  action$: $TSFixMe,
  state$: $TSFixMe,
  { get, catchRestError }: $TSFixMe,
) =>
  action$.pipe(
    ofType(walletApiEvents.unallocatedSuspenseAccountss_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.GET_UNALLOCATED_SUSPENSE_ACCOUNTS, state$, action.payload).pipe(
        pluck('response'),
        map(res =>
          walletApiEvents.unallocatedSuspenseAccountss_success(
            transformers.transformGetUnallocatedSuspenseAccountsResponse(res),
          ),
        ),
        catchRestError(action),
      ),
    ),
    tag('wallet/epics/apiGetUnallocatedSuspenseAccounts'),
  )

export const apiGetAllocatedSuspenseAccounts = (
  action$: $TSFixMe,
  state$: $TSFixMe,
  { get, catchRestError }: $TSFixMe,
) =>
  action$.pipe(
    ofType(walletApiEvents.allocatedSuspenseAccounts_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.GET_ALLOCATED_SUSPENSE_ACCOUNTS, state$, action.payload).pipe(
        pluck('response'),
        map(res =>
          walletApiEvents.allocatedSuspenseAccounts_success(
            transformers.transformGetAllocatedSuspenseAccountsResponse(res),
          ),
        ),
        catchRestError(action),
      ),
    ),
    tag('wallet/epics/apiGetAllocatedSuspenseAccount'),
  )

export const apiAllocateSuspenseAccount = (action$: $TSFixMe, state$: $TSFixMe, { put, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.allocateSuspenseAccount_request),
    mergeMap((action: AnyAction) => {
      const { body, params } = action.payload
      return put(ENDPOINTS.ALLOCATE_SUSPENSE_ACCOUNT, state$, body, params).pipe(
        pluck('response'),
        mergeMap(res => [
          walletApiEvents.allocateSuspenseAccount_success(res, params),
          walletApiEvents.allocatedSuspenseAccounts_request(),
        ]),
        catchRestError(action),
      )
    }),
    tag('wallet/epics/apiAllocateSuspenseAccount'),
  )

export const apiReferenceLookup = (action$: $TSFixMe, state$: $TSFixMe, { get, catchRestError }: $TSFixMe) =>
  action$.pipe(
    ofType(walletApiEvents.referenceLookup_request),
    mergeMap((action: AnyAction) =>
      get(ENDPOINTS.REFERENCE_LOOKUP, state$, action.payload).pipe(
        pluck('response'),
        map((res: $TSFixMe) => walletApiEvents.referenceLookup_success(res.result)),
        catchRestError(action),
      ),
    ),
    tag('wallet/epics/apiReferenceLookup'),
  )
