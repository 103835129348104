import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import { format, getUnixTime, isAfter, isBefore, subDays, subMonths, addMonths } from 'date-fns'
import { get, map } from 'lodash-es'
import { stringUtils } from '../../../../../../utils'
import { AggregateTable, DateRangePicker, Header, Label, NoContent } from '../../../..'
import { InvoicesProvider, SearchFilterProvider } from '../../../../../providers'
import styles from './DraftInvoicesTable.module.scss'
import CurrencyText from '../../../../atoms/Typography/CurrencyText/CurrencyText'
import { filter as rFilter, flatten, map as rMap, pipe, prop, reverse, sortBy } from 'ramda'
import { sortByProps } from 'utils/array'
import { $TSFixMe } from 'types/ts-migrate'
import SvgInvoiceFill from 'views/components/atoms/Icons/Doc/InvoiceFill'
import { dispatch } from 'rxjs/internal/observable/pairs'
import { uiEvents } from 'modules/ui'
import { useDispatch } from 'react-redux'

const statusColorMap = {
  Green: '#128269',
  Amber: '#FF6D21',
  Red: '#FF1900',
}

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
}

const DraftInvoicesTable = ({
  title = 'Draft Invoices',
  openInvoice,
  draftInvoiceFilters,
  searchQuery,
  activeFilterIndex,
  animations,
  failedSendInvoices,
  failedDeleteInvoices,
  currentInvoice,
}: any): React.ReactElement => {
  const dispatch = useDispatch()
  const [data, setData] = useState<$TSFixMe[]>([])
  const [startDate, setStartDate] = useState(subMonths(new Date(), 1))
  const [endDate, setEndDate] = useState(addMonths(new Date(), 2))
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const filter = get(draftInvoiceFilters, `[${[activeFilterIndex]}]`, {
    invoices: [],
  })

  useEffect(() => {
    const orderedTableData = pipe(
      rMap((inv: any) => ({
        dueDateSeconds: format(new Date(inv.dueDate), 't'),
        ...inv,
      })),
      (list: any) => sortByProps(['dueDateSeconds', 'invoiceTo', 'property'], list),
    )(filter.invoices)

    setData(
      orderedTableData.map((invoice: $TSFixMe) => ({
        ...invoice,
        meta: {
          invoiceTo: invoice.customer.name,
          property: invoice.property,
          type: invoice.invoiceType,
          dueDate: invoice.dueDate ? getUnixTime(new Date(invoice.dueDate)).toString() : '',
          amount: invoice.amount.toString(),
        },
      })),
    )
  }, [filter.invoices])

  const currentInvoiceId = get(currentInvoice, 'id')

  const status = get(filter, 'status', '')
  const filteredInvoices = useMemo(() => {
    return data.filter(inv => stringUtils.jsonStringSearch(searchQuery, inv))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, data])

  const columns = [
    {
      Header: '',
      accessor: 'status',
      style: {
        maxWidth: '10px',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
      },
    },
    {
      Header: 'Invoice to',
      accessor: 'invoiceTo',
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Property',
      accessor: 'property',
      style: {
        minWidth: '400px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Invoice Type',
      accessor: 'type',
      style: {
        maxWidth: '150px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Due date',
      accessor: 'dueDate',
      style: {
        maxWidth: '120px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Invoice Amount',
      accessor: 'amount',
      style: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '20px',
        maxWidth: '160px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
  ]

  const invoiceData = useMemo((): $TSFixMe[] => {
    const failedDeleteInvoiceIds = failedDeleteInvoices.map((item: $TSFixMe) => item.invoiceIds)
    const failedSendInvoiceIds = failedSendInvoices.map((item: $TSFixMe) => item.id)
    const failedInvoiceIds: string[] = flatten([failedDeleteInvoiceIds, failedSendInvoiceIds])

    const buildInvoiceObj = (inv: $TSFixMe): $TSFixMe => {
      const hasInvoiceActionFailed = !!failedInvoiceIds.includes(inv.id)

      return {
        id: inv.id,
        highlighted: currentInvoiceId === inv.id,
        meta: inv.meta, // used for sorting
        status: (
          <Label circular empty size="sm" style={{ backgroundColor: statusColorMap[status] }}>
            &nbsp;
          </Label>
        ),
        invoiceTo: inv.customer.name,
        property: inv.property,
        type: inv.invoiceTypeName,
        dueDate: inv.dueDate ? format(new Date(inv.dueDate), 'dd MMM yyyy') : '',
        amount: inv.amount ? <CurrencyText>{inv.amount}</CurrencyText> : <em>No amount set</em>,
        animateSuccess:
          !hasInvoiceActionFailed &&
          (animations?.bulkSend.includes(inv.id) ||
            animations?.removeFromSetMode.includes(inv.id) ||
            animations?.bulkRestore.includes(inv.id)),
        animateDanger: !hasInvoiceActionFailed && animations?.bulkDelete.includes(inv.id),
        hasError: hasInvoiceActionFailed,
      }
    }

    const tableData = searchQuery ? map(filteredInvoices, buildInvoiceObj) : map(data, buildInvoiceObj)

    const filteredTableData =
      filter.key === 'deleted'
        ? rFilter(
            (inv: any) =>
              isAfter(new Date(inv.dueDate), subDays(new Date(startDate), 1)) &&
              isBefore(new Date(inv.dueDate), new Date(endDate)),
            tableData,
          )
        : tableData

    // @ts-expect-error
    return failedInvoiceIds.length > 0
      ? pipe(
          // @ts-expect-error
          sortBy(prop('hasError')),
          reverse,
        )(filteredTableData)
      : filteredTableData
  }, [
    data,
    filteredInvoices,
    searchQuery,
    status,
    animations,
    failedSendInvoices,
    failedDeleteInvoices,
    currentInvoiceId,
    startDate,
    endDate,
    filter.key,
  ])

  return useMemo(
    () => (
      <div className={styles.root}>
        <div className={styles['header-wrapper']}>
          <Header icon={<SvgInvoiceFill />} text={title} />
          {filter.key === 'deleted' && (
            <span className={styles['datepicker-wrapper']}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChange={(date: any, isStartDate: any) => {
                  if (isStartDate) {
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    setStartDate(format(date, 'yyyy-MM-dd'))
                  } else {
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                    setEndDate(format(date, 'yyyy-MM-dd'))
                  }
                }}
                inline={false}
              />
            </span>
          )}
        </div>

        {invoiceData.length > 0 ? (
          <AggregateTable
            enableSelection
            columns={columns}
            data={invoiceData}
            onRowClick={data => {
              dispatch(uiEvents.redirect(`/invoices/drafts/${data.id}`))
            }}
          />
        ) : (
          <NoContent
            heading={
              filter.key === 'deleted'
                ? 'No invoices to display for selected date range.'
                : 'All your invoices have been taken care of.'
            }
          />
        )}
      </div>
    ),
    [dispatch, columns, invoiceData, title, startDate, endDate, filter.key],
  )
}

DraftInvoicesTable.propTypes = propTypes

export default subscribe(
  [InvoicesProvider, SearchFilterProvider],
  (
    { draftInvoiceFilters, openInvoice, animations, failedSendInvoices, failedDeleteInvoices, currentInvoice }: any,
    { searchQuery, activeFilterIndex }: any,
  ) => ({
    draftInvoiceFilters,
    openInvoice,
    animations,
    failedSendInvoices,
    failedDeleteInvoices,
    currentInvoice,
    // SearchFilterProvider props
    searchQuery,
    activeFilterIndex,
  }),
)(DraftInvoicesTable)
