import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { animated, useSpring } from 'react-spring'
import { get } from 'lodash-es'
import OptionsIcon from '../../atoms/Svgs/OptionsOff'
import Modal from '../../molecules/Modal/Modal'
import Button from '../../atoms/Button/Button'
import styles from './BulkActions.module.scss'
import { animationConfig } from 'views/constants'

type TAction = {
  text: string
  count: number
  danger?: boolean
  confirmation?: {
    title: string
    content: React.ReactNode
    onConfirm: () => void
  }
}

type TProps = {
  isOpen: boolean
  actions: TAction[]
}

function BulkActions({ isOpen, actions }: TProps) {
  const [confirmationModal, setConfirmationModal] = useState(-1)

  useEffect(() => {
    if (!isOpen) {
      setConfirmationModal(-1)
    }
  }, [isOpen])

  const confirmation = get(actions, `[${confirmationModal}].confirmation`, {})
  const { title = '', content = '', onConfirm = () => {} } = confirmation

  const style = useSpring({
    reverse: !isOpen,
    config: animationConfig,
    to: { transform: 'translateY(0px)' },
    from: { transform: 'translateY(200px)' },
  })

  return (
    <animated.div className={styles.root} style={style}>
      {actions.map((action: any, i: any) => {
        const classes = cx(styles.action, {
          [styles.danger]: action.danger,
        })

        return (
          <button key={i} className={classes} onClick={() => setConfirmationModal(i)}>
            {action.text}
            <span>{action.count}</span>
          </button>
        )
      })}

      <button className={styles.more}>
        <OptionsIcon />
      </button>

      <Modal isOpen={confirmationModal > -1} theme="dark" padding="sm" showClose={false}>
        <h3 className="Modal_Title">{title}</h3>
        <div className="Modal_Content">
          {content}
          <div className="Modal_Actions">
            <Button link onClick={() => setConfirmationModal(-1)}>
              Cancel
            </Button>
            <Button link onClick={onConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </animated.div>
  )
}

export default BulkActions
