import { morphism } from 'morphism'
import { $TSFixMe } from 'types/ts-migrate'
import { currencyUtils } from '../../../utils'

const { toCents, fromCents } = currencyUtils

/**
 * Transform wallet asset balance amounts
 * @param {string} direction `in` or `out`
 * @param {Array} assets wallet balance assets
 */
const transformBalanceAssets = (assets: any) =>
  assets.map((asset: any) => ({
    ...asset,
    balance: toCents(asset.balance).getAmount(),
  }))

/**
 * Wallet Balance In
 */
const balanceSchema = {
  globalBalance: ({ globalBalance }: any) => ({
    ...globalBalance,
    assets: transformBalanceAssets(globalBalance.assets),
  }),
  accountBalances: ({ accountBalances }: any) =>
    accountBalances.map((accBalance: any) => ({
      ...accBalance,
      assets: transformBalanceAssets(accBalance.assets),
    })),
}

export const transformBalance = (source: any) => morphism(balanceSchema, source)

/**
 * Payments
 */
const transformPaymentItems = (payments: any) =>
  payments.map((payment: any) => ({
    ...payment,
    amount: toCents(payment.amount).getAmount(),
    approvedAt: new Date(payment.approvedAt),
    payoutAt: new Date(payment.payoutAt),
  }))

const paymentsSchema = {
  payments: ({ payments }: any) => transformPaymentItems(payments),
  period: 'period',
}

export const transformPayments = (source: any) => morphism(paymentsSchema, source)

/**
 * Payout
 */
const payoutRequestSchema = {
  party: 'party',
  asset: 'asset',
  amount: (iteratee: any) => fromCents(iteratee.amount),
}

export const transformPayoutRequest = (source: any) => morphism(payoutRequestSchema, source)

const payoutResponseSchema = {
  party: 'party',
  asset: 'asset',
  txHash: 'txHash',
  amount: 'amount',
}

export const transformPayoutResponse = (source: any) => morphism(payoutResponseSchema, source)

/**
 * Deposit Transfer
 */
const transferRequestSchema = {
  account: 'account',
  transferReason: 'transferReason',
  asset: 'asset',
  amount: ({ amount }: any) => fromCents(amount),
}

export const transformTransferRequest = (source: any) => morphism(transferRequestSchema, source)

/**
 * Wallet transfer
 */
const walletTransferRequestSchema = {
  fromAccount: 'fromAccount',
  fromParty: 'fromParty',
  agency: 'agency',
  transfers: ({ transfers }: $TSFixMe) =>
    transfers.map((transfer: $TSFixMe) => ({
      toAccount: transfer.toAccount,
      toParty: transfer.toParty,
      asset: transfer.asset,
      amount: fromCents(transfer.amount),
      description: transfer.description,
    })),
}

export const transformWalletTransferRequest = (source: any) => morphism(walletTransferRequestSchema, source)

/** @todo remove this, not transforming anything? */
const transferResponseSchema = {
  txHash: 'res.txHash',
  meta: 'meta',
}

export const transformTransferResponse = (source: any) => morphism(transferResponseSchema, source)

/**
 * Refund
 */
const refundRequestSchema = {
  account: 'account',
  asset: 'asset',
  amount: ({ amount }: any) => fromCents(amount),
  reference: 'reference',
}

export const transformRefundRequest = (source: any) => morphism(refundRequestSchema, source)

const refundResponseSchema = {
  paymentId: 'paymentId',
}

export const transformRefundResponse = (source: any) => morphism(refundResponseSchema, source)

const getUnallocatedSuspenseAccountsSchema = {
  txId: 'txId',
  isAllocated: 'isAllocated',
  amount: ({ amount }: any) => toCents(amount).getAmount(),
  asset: 'asset',
  referenceUsed: 'referenceUsed',
  dateReceived: 'dateReceived',
  dateMonth: 'dateMonth',
  dateYear: 'dateYear',
}

export const transformGetUnallocatedSuspenseAccountsResponse = (source: any) =>
  morphism(getUnallocatedSuspenseAccountsSchema, source)

const getAllocatedSuspenseAccountsSchema = {
  txId: 'txId',
  isAllocated: 'isAllocated',
  walletRef: 'walletRef',
  notes: 'notes',
  clickUpRef: 'clickUpRef',
  amount: ({ amount }: any) => toCents(amount).getAmount(),
  asset: 'asset',
  referenceUsed: 'referenceUsed',
  allocatedBy: 'allocatedBy',
  allocatedAt: 'allocatedAt',
  allocatedToRef: 'allocatedToRef',
  dateReceived: 'dateReceived',
  dateMonth: 'dateMonth',
  dateYear: 'dateYear',
}

export const transformGetAllocatedSuspenseAccountsResponse = (source: any) =>
  morphism(getAllocatedSuspenseAccountsSchema, source)
