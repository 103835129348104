import React from 'react'
import { subscribe } from 'react-contextual'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styles from './ActiveInvoices.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import CurrencyText from 'views/components/atoms/Typography/CurrencyText/CurrencyText'
import ActiveInvoicesProvider from './ActiveInvoicesProvider'
import { InvoicesProvider } from 'views/providers'
import { LayoutContext } from 'modules/Layout/LayoutProvider'

const ActiveInvoices = ({
  invoiceTypes,
  activeInvoices,
  currentInvoice,
  closeInvoice,
  openInvoice,
  secondaryPanel,
}: any) => {
  const history = useHistory()

  const handleInvoiceSwitch = (invoiceId: string, invoiceType: string, invoiceStatus: string) => {
    const lowerCaseInvoiceStatus = generateInvoiceStatusFilter(invoiceStatus)
    closeInvoice(currentInvoice.id, 'active', false)
    openInvoice(invoiceId, 'active', false, generateInvoiceStatusFilter(invoiceType))
    secondaryPanel.open()
    if (invoiceStatus !== '') {
      history.push({
        search: `?filter=${lowerCaseInvoiceStatus}`,
      })
    }
  }

  const matchInvoiceDescriptionToType = (invoice: $TSFixMe) => {
    const invoiceType = invoiceTypes.find((type: $TSFixMe) => type.value === invoice.description)
    return invoiceType ? invoiceType.name : ''
  }

  const generateInvoiceStatusFilter = (status: string) => {
    switch (status) {
      case 'Out':
        return 'open'
      case 'Overdue':
        return 'overdue'
      case 'NotSet':
        return 'unallocated'
      case 'Short':
        return 'short'
      case 'Ready':
        return 'ready'
      default:
        return 'ready'
    }
  }

  return (
    activeInvoices.length > 0 && (
      <section className={styles.root}>
        <ul>
          {activeInvoices.map((inv: any, i: any) => {
            return (
              <li key={i} className={styles[`status-${inv.invoiceStatus.replace(' ', '')}`]}>
                <button
                  onClick={() =>
                    handleInvoiceSwitch(inv.invoiceId, matchInvoiceDescriptionToType(inv), inv.invoiceStatus)
                  }
                >
                  <span className={styles.description}>{matchInvoiceDescriptionToType(inv)}</span>
                  <span className={styles.amount}>
                    <CurrencyText>{inv.balance}</CurrencyText>
                  </span>
                </button>
              </li>
            )
          })}
        </ul>
      </section>
    )
  )
}

export default subscribe(
  [ActiveInvoicesProvider, InvoicesProvider, LayoutContext],
  (
    { fetchInvoiceTypes, invoiceTypes, activeInvoices }: any,
    { currentInvoice, closeInvoice, openInvoice }: any,
    { secondaryPanel }: any,
  ) => ({
    fetchInvoiceTypes,
    invoiceTypes,
    activeInvoices,
    currentInvoice,
    closeInvoice,
    openInvoice,
    secondaryPanel,
  }),
)(ActiveInvoices)
