import { map, mergeMap, pluck, tap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { tag } from 'rxjs-spy/operators/tag'
import { ENDPOINTS } from './constants'
import { ENDPOINTS as PARTY_ENDPOINTS } from '../party/constants'
import { reportingApiEvents } from '.'
import { downloadFileFromArrayBuffer, downloadFileFromBase64 } from 'utils/operators'
import { agencyApiSelectors } from '../agency'
import { userApiSelectors } from '../user'
import { walletApiSelectors } from '../wallet'
import { $TSFixMe } from 'types/ts-migrate'
import { Observable } from 'rxjs'
import { AnyAction } from 'redux'
import {
  IBaseReportRequest,
  IFranchisePerformanceRequest,
  IPropertyTaxStatementRequest,
  ITenancyScheduleRequest,
} from './interfaces'
import { transformAgencyPerformanceSummary } from './transformers'
import { multiUserOperators } from '../../multiUser/state'
import { downloadBase64 } from 'utils/fileUtils'
import { format } from 'date-fns'

export const fetchAccountsReceivable = (action$: any, state$: any, { get, catchRestError }: any): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.accountsReceivable_request),
    mergeMap((action: AnyAction) => {
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const currentDate = new Date()
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      return get(
        ENDPOINTS.ACCOUNTS_RECEIVABLE,
        state$,
        null,
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer(
          'application/ms-excel',
          `Accounts Receivable_${tradingOrEntity}_${format(currentDate, 'yyyy-MM-dd')}.xlsx`,
        ),
        map(reportingApiEvents.accountsReceivable_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/fetchAccountsReceivable'),
  )

export const fetchCommissionSplit = (
  action$: Observable<AnyAction>,
  state$: any,
  { get, catchRestError }: any,
): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.commissionSplit_request),
    mergeMap(action => {
      const payload: IBaseReportRequest = action.payload
      const { start = '', end = '', dataType } = payload
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      const preferredAsset = walletApiSelectors.getGlobalBalanceAsset(state$.value)(currentAgencyId)
      const requestType = dataType === 'excel' ? 'xlsx' : dataType
      const applicationType =
        dataType === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : dataType
      return get(`${ENDPOINTS.COMMISSION_SPLIT}/${requestType}`, state$, {
        start_date: payload.start,
        end_date: payload.end,
        asset: preferredAsset.name,
      }).pipe(
        pluck('response'),
        pluck('xlsxBase64'),
        downloadFileFromBase64(applicationType, `Agency Commission Report_${tradingOrEntity}_${start}_${end}`),
        map(reportingApiEvents.commissionSplit_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/fetchCommissionSplit'),
  )

export const rentRoll = (action$: Observable<AnyAction>, state$: any, { get, catchRestError }: any): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.rentRoll_request),
    mergeMap((action: AnyAction) => {
      const payload: IBaseReportRequest = action.payload
      const { start = '', end = '' } = payload
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      return get(
        ENDPOINTS.RENT_ROLL,
        state$,
        { start, finish: end },
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer('application/ms-excel', `Rent Roll_${tradingOrEntity}_${start}_${end}.xlsx`),
        map(reportingApiEvents.rentRoll_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/rentRoll'),
  )

export const cashFlow = (action$: Observable<AnyAction>, state$: any, { get, catchRestError }: any): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.cashFlow_request),
    mergeMap((action: AnyAction) => {
      const payload: IBaseReportRequest = action.payload
      const { start = '', end = '' } = payload
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      return get(
        ENDPOINTS.CASH_FLOW,
        state$,
        { start: start, finish: end },
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer('application/ms-excel', `Cash Flow_${tradingOrEntity}_${start}_${end}.xlsx`),
        map(reportingApiEvents.cashFlow_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/cashFlow'),
  )

export const deposits = (action$: Observable<AnyAction>, state$: any, { get, catchRestError }: any): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.deposits_request),
    mergeMap((action: AnyAction) => {
      const payload: IBaseReportRequest = action.payload
      const { start = '', end = '' } = payload
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      return get(
        `${ENDPOINTS.DEPOSITS}`,
        state$,
        { start: start, finish: end },
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer('application/ms-excel', `Deposit Report_${tradingOrEntity}_${start}_${end}.xlsx`),
        map(reportingApiEvents.deposits_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/deposits'),
  )

export const depositSummaryStats = (
  action$: Observable<AnyAction>,
  state$: any,
  { get, catchRestError }: any,
): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.depositSummaryStats_request),
    mergeMap((action: AnyAction) => {
      return get(ENDPOINTS.DEPOSIT_SUMMARY_STATS, state$).pipe(
        pluck('response'),
        map(reportingApiEvents.depositSummaryStats_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/depositSummaryStats'),
  )

export const tenancySchedule = (action$: Observable<AnyAction>, state$: any, { get, catchRestError }: any): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.tenancySchedule_request),
    mergeMap((action: AnyAction) => {
      const payload: ITenancyScheduleRequest = action.payload
      const { shouldIncludeDrafts } = payload
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      const currentDate = new Date()
      return get(
        ENDPOINTS.TENANCY_SCHEDULE,
        state$,
        { includeDrafts: shouldIncludeDrafts },
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer(
          'application/ms-excel',
          `Tenancy Schedule_${tradingOrEntity}_${format(currentDate, 'yyyy-MM-dd')}.xlsx`,
        ),
        map(reportingApiEvents.tenancySchedule_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/tenancySchedule'),
  )

export const agencyGroupCommissionSplit = (
  action$: Observable<AnyAction>,
  state$: any,
  { get, catchRestError }: any,
): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.agencyGroupCommissionSplit_request),
    mergeMap((action: AnyAction) => {
      const payload: IBaseReportRequest = action.payload
      const { start = '', end = '', dataType } = payload
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      return get(
        /** @todo the API only supports Rawson at the moment. Temporarily hard coding the agency group ID */
        `${ENDPOINTS.AGENCY_GROUP_COMMISSION_SPLIT}/${dataType}`,
        state$,
        { id: '754cceb6-f295-4d05-85c8-9d7ca4edeb1b', start_date: start, end_date: end },
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer(
          'application/ms-excel',
          `Agency Group Commission Split_${tradingOrEntity}_${start}_${end}.xlsx`,
        ),
        map(reportingApiEvents.agencyGroupCommissionSplit_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/agencyGroupCommissionSplit'),
  )

export const billingReport = (action$: Observable<AnyAction>, state$: any, { get, catchRestError }: any): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.billingReport_request),
    mergeMap((action: AnyAction) => {
      const payload: IBaseReportRequest = action.payload
      const { start = '', end = '', dataType } = payload
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      return get(
        `${ENDPOINTS.BILLING}/${dataType}`,
        state$,
        { start_date: start, end_date: end },
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer('application/ms-excel', `Billing Report_${tradingOrEntity}_${start}_${end}.xlsx`),
        map(reportingApiEvents.billingReport_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/billingReport'),
  )

export const tpnReport = (action$: Observable<AnyAction>, state$: any, { get, catchRestError }: $TSFixMe): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.tpnReport_request),
    mergeMap((action: AnyAction) => {
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      return get(
        ENDPOINTS.TPN_REPORT,
        state$,
        { start: action.payload.dateParams.start, finish: action.payload.dateParams.end },
        { 'Content-Type': 'text/csv' },
        { responseType: 'text' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer(
          'text/csv',
          `TPN Report_${tradingOrEntity}_${action.payload.dateParams.start}_${action.payload.dateParams.end}.csv`,
        ),
        map(reportingApiEvents.tpnReport_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/tpnReport'),
  )

export const cashDepositStatement = (
  action$: Observable<AnyAction>,
  state$: any,
  { get, catchRestError }: $TSFixMe,
): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.cashDepositStatement_request),
    mergeMap((action: AnyAction) => {
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      return get(ENDPOINTS.DEPOSIT_FEE_STATEMENT + '/' + action.payload.dataType, state$, {
        start_date: action.payload.start,
        end_date: action.payload.end,
      }).pipe(
        pluck('response'),
        map((res: { pdfBase64: string; csvBase64: string }) => {
          downloadBase64(
            action.payload.dataType === 'pdf' ? res.pdfBase64 : res.csvBase64,
            action.payload.dataType,
            `Cash Deposit Statement_${tradingOrEntity}_${action.payload.start}_${action.payload.end}.${action.payload.dataType}`,
          )
          return reportingApiEvents.cashDepositStatement_success({})
        }),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/cashDepositStatementCsv'),
  )

export const agencyPerformanceSummaryChart = (
  action$: Observable<AnyAction>,
  state$: any,
  { get, catchRestError }: $TSFixMe,
): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.agencyPerformanceSummaryChart_request),
    multiUserOperators.filterCurrentAgencyId(state$),
    mergeMap((action: $TSFixMe) => {
      return get(ENDPOINTS.AGENCY_SUMMARY_CHART, state$, action.payload).pipe(
        pluck('response'),
        map(res => reportingApiEvents.agencyPerformanceSummaryChart_success(transformAgencyPerformanceSummary(res))),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/agencyPerformanceSummaryChart'),
  )

export const fetchAgentCommissionReport = (
  action$: Observable<AnyAction>,
  state$: any,
  { get, catchRestError }: any,
): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.agentCommissionReport_request),
    mergeMap(action => {
      const payload: IBaseReportRequest = action.payload
      const { start = '', end = '', dataType } = payload
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      const preferredAsset = walletApiSelectors.getGlobalBalanceAsset(state$.value)(currentAgencyId)
      const requestType = dataType === 'excel' ? 'xlsx' : dataType
      const applicationType =
        dataType === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : dataType
      return get(`${ENDPOINTS.AGENT_COMMISSION_REPORT}/${requestType}`, state$, {
        start_date: payload.start,
        end_date: payload.end,
        asset: preferredAsset.name,
      }).pipe(
        pluck('response'),
        pluck('xlsxBase64'),
        downloadFileFromBase64(applicationType, `Agent Commission Report_${tradingOrEntity}_${start}_${end}`),
        map(reportingApiEvents.agentCommissionReport_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/fetchCommissionSplit'),
  )

export const getProperties = (
  action$: Observable<AnyAction>,
  state$: any,
  { get, catchRestError }: $TSFixMe,
): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.properties_request),
    mergeMap((action: AnyAction) => {
      return get(ENDPOINTS.PROPERTIES, state$).pipe(
        pluck('response'),
        map(reportingApiEvents.properties_success),
        catchRestError(action),
      )
    }),
    tag('reporting/epics/getProperties'),
  )

export const getOwners = (action$: Observable<AnyAction>, state$: any, { get, catchRestError }: $TSFixMe): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.quickSearch_request),
    mergeMap((action: AnyAction) =>
      get(PARTY_ENDPOINTS.QUICK_SEARCH, state$, action.payload).pipe(
        pluck('response'),
        map(results => reportingApiEvents.quickSearch_success({ results, params: action.payload })),
        catchRestError(action),
      ),
    ),
    tag('reporting/epics/getOwners'),
  )

export const propertyTaxStatement = (
  action$: Observable<AnyAction>,
  state$: any,
  { get, catchRestError }: any,
): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.propertyTaxStatement_request),
    mergeMap((action: AnyAction) => {
      const payload: IPropertyTaxStatementRequest = action.payload
      const { start = '', finish = '', ownerId, ownerName } = payload
      return get(ENDPOINTS.PROPERTY_TAX_STATEMENT, state$, {
        start_date: start,
        end_date: finish,
        landlord_id: ownerId,
      }).pipe(
        pluck('response'),
        map((res: { pdfBase64: string }) => {
          downloadBase64(res.pdfBase64, 'application/pdf', `Property Tax Statement_${ownerName}_${start}_${finish}.pdf`)
          return reportingApiEvents.propertyTaxStatement_success({})
        }),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/propertyTaxStatement'),
  )

export const franchisePerformance = (
  action$: Observable<AnyAction>,
  state$: any,
  { get, catchRestError }: any,
): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.franchisePerformance_request),
    mergeMap((action: AnyAction) => {
      const payload: IFranchisePerformanceRequest = action.payload
      const { start = '', finish = '', groupId } = payload
      return get(
        ENDPOINTS.FRANCHISE_PERFORMANCE + '/' + groupId,
        state$,
        { start, finish },
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer('application/ms-excel', `Rawson Performance Report_${start}_${finish}.xlsx`),
        map(reportingApiEvents.franchisePerformance_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/franchisePerformance'),
  )

export const activeLeases = (action$: Observable<AnyAction>, state$: any, { get, catchRestError }: any): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.activeLeases_request),
    mergeMap((action: AnyAction) => {
      const { start, finish } = action.payload
      return get(
        ENDPOINTS.ACTIVE_LEASES,
        state$,
        { start, finish },
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer('application/ms-excel', `reOS Active Customers_${start}_${finish}.xlsx`),
        map(reportingApiEvents.activeLeases_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/activeLeases'),
  )

export const availableFunds = (action$: Observable<AnyAction>, state$: any, { get, catchRestError }: any): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.availableFunds_request),
    mergeMap((action: AnyAction) => {
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      const currentDate = new Date()
      return get(
        ENDPOINTS.AVAILABLE_FUNDS,
        state$,
        null,
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer(
          'application/ms-excel',
          `Available Funds_${tradingOrEntity}_${format(currentDate, 'yyyy-MM-dd')}.xlsx`,
        ),
        map(reportingApiEvents.availableFunds_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/availableFunds'),
  )

export const walletNumbers = (action$: Observable<AnyAction>, state$: any, { get, catchRestError }: any): $TSFixMe =>
  action$.pipe(
    ofType(reportingApiEvents.wallets_request),
    mergeMap((action: AnyAction) => {
      const currentAgencyId = userApiSelectors.getCurrentAgencyId(state$.value)
      const tradingOrEntity = agencyApiSelectors.getAgencyTradingOrCompanyName(state$.value)(currentAgencyId)
      const currentDate = new Date()
      return get(
        ENDPOINTS.WALLETS,
        state$,
        null,
        { 'Content-Type': 'application/ms-excel' },
        { responseType: 'arraybuffer' },
      ).pipe(
        pluck('response'),
        downloadFileFromArrayBuffer(
          'application/ms-excel',
          `Wallet Numbers_${tradingOrEntity}_${format(currentDate, 'yyyy-MM-dd')}.xlsx`,
        ),
        map(reportingApiEvents.wallets_success),
        catchRestError(action),
      )
    }),
    tag('reportingApi/epics/wallets'),
  )
