import createSelector from 'selectorator'
import { path, pathOr, map, identity, filter, isEmpty, pipe, values } from 'ramda'
import { propertyApiSelectors } from '../../api/property'
import { NAMESPACE } from '../constants'
import { partyApiSelectors } from '../../api/party'
import { portfolioApiSelectors } from '../../api/portfolio'
import { invoiceApiSelectors } from '../../api/invoice'

/**
 * Selector Helpers
 */
const getSlice = (key: any) => createSelector([`${NAMESPACE}.${key}`], identity)

/**
 * Selectors
 */
export const getPortfolioState = createSelector([NAMESPACE], identity)
export const isSearchLoading = getSlice('isLoading.search')
export const depositTransferModal = getSlice('depositTransferModal')

/**
 * @todo implement client side search
 */
export const getPortfolioFilters = createSelector(
  [portfolioApiSelectors.getPortfolioSummariesFilteredByCurrentSegments],
  summaries => {
    const portfolios = map((summary: any) => {
      return {
        propertyId: path(['propertyId'], summary),
        portfolioId: path(['portfolioId'], summary),
        mainText: path(['mainText'], summary),
        secondaryText: path(['secondaryText'], summary),
        owner: summary?.primaryOwnerName,
        tenant: summary?.primaryTenantName,
        endDate: path(['leaseTerms', 'endDate'], summary),
        tags: path(['tags'], summary),
        status: path(['status'], summary),
        leaseType: pathOr('', ['tags', 'leaseType'], summary).replace('Contract', ''),
        ...summary,
      }
    })(summaries)

    const activePortfolios = filter((p: any) => p.status === 'active', portfolios)
    const expiringPortfolios = filter((p: any) => p.status === 'expiring', portfolios)
    const renewalPortfolios = filter((p: any) => p.status === 'renewal', portfolios)
    const draftPortfolios = filter((p: any) => p.status === 'draft', portfolios)
    const expiredPortfolios = filter((p: any) => p.status === 'expired', portfolios)
    const terminatedPortfolios = filter((p: any) => p.status === 'terminated', portfolios)
    const deletedPortfolios = filter(p => p.status === 'deleted', portfolios)

    const filteredPortfolios = filter((p: any) => p.status !== 'deleted', portfolios)

    return [
      {
        key: 'all',
        label: 'All',
        count: filteredPortfolios.length,
        portfolios: filteredPortfolios,
      },
      /** @todo I don't think this is correct. May need an API update for properties with draft leases */
      {
        key: 'draft',
        label: 'Draft',
        count: draftPortfolios.length,
        portfolios: draftPortfolios,
      },
      {
        key: 'active',
        label: 'Active',
        count: activePortfolios.length,
        portfolios: activePortfolios,
      },
      {
        key: 'expiring',
        label: 'Expiring',
        count: expiringPortfolios.length,
        portfolios: expiringPortfolios,
      },
      {
        key: 'renewals',
        label: 'Renewals',
        count: renewalPortfolios.length,
        portfolios: renewalPortfolios,
      },
      {
        key: 'expired',
        label: 'Expired',
        count: expiredPortfolios.length,
        portfolios: expiredPortfolios,
      },
      {
        key: 'terminated',
        label: 'Terminated',
        count: terminatedPortfolios.length,
        portfolios: terminatedPortfolios,
      },
      {
        key: 'deleted',
        label: 'Deleted',
        count: deletedPortfolios.length,
        portfolios: deletedPortfolios,
        hideCount: true,
      },
      /** @todo re-add when there's support archived portfolios */
      // {
      //   key: 'archive',
      //   label: 'Archive',
      //   count: portfolios.length,
      //   portfolios
      // }
    ]
  },
)

export const getPropertyFilters = createSelector([identity], state => {
  const propertySummaries = portfolioApiSelectors.getPropertySummaries(state)
  const portfolioSummaries = portfolioApiSelectors.getPortfolioSummaries(state)

  const properties = pipe(
    map((summary: any) => ({
      propertyId: path(['propertyId'], summary),
      portfolioId: path(['portfolioId'], summary),
      mainText: path(['mainText'], summary),
      secondaryText: path(['secondaryText'], summary),
      endDate: path(['endDate'], summary),
      tags: path(['tag'], summary),

      activeLeases: filter((p: any) => {
        return (
          p.propertyId === path(['propertyId'], summary) &&
          (portfolioApiSelectors.isActivePortfolio(state)(p.portfolioId, true) ||
            portfolioApiSelectors.isPortfolioExpiring(state)(p.portfolioId, true))
        )
      }, portfolioSummaries),
    })),
    values,
  )(propertySummaries)

  const occupiedProperties = filter((p: any) => !isEmpty(p.activeLeases), properties)
  const vacantProperties = filter((p: any) => isEmpty(p.activeLeases), properties)

  return [
    {
      key: 'all',
      label: 'All',
      count: properties.length,
      properties: properties,
    },
    {
      key: 'occupied',
      label: 'Occupied',
      count: occupiedProperties.length,
      properties: occupiedProperties,
    },
    {
      key: 'vacant',
      label: 'Vacant',
      count: vacantProperties.length,
      properties: vacantProperties,
    },
  ]
})

export const getPortfolioViewData = createSelector([identity], state => (id: any) => {
  const propertyId = portfolioApiSelectors.getPropertyIdByPortfolioId(state)(id)
  const property = propertyApiSelectors.getSummaryById(state)(propertyId)
  const getPrimaryOwnerId = portfolioApiSelectors.getPrimaryLandlordByPortfolioId(state)
  const getPrimaryTenantId = portfolioApiSelectors.getPrimaryTenantByPortfolioId(state)
  const ownerId = getPrimaryOwnerId(id)
  const partyType = partyApiSelectors.getPartyTypeById(state)(ownerId)
  const partyName =
    partyType === 'person'
      ? partyApiSelectors.getPartyNameById(state)(ownerId)
      : partyApiSelectors.getPartyTradingAsOrCompanyNameById(state)(ownerId)
  const idNumber = partyType === 'person' ? partyApiSelectors.getPersonsIdNumber(state)(ownerId) : null
  const passportNumber = partyType === 'person' ? partyApiSelectors.getPersonsPassportNumber(state)(ownerId) : null
  const { bank, accountNumber } = partyApiSelectors.getPartyBankDetails(state)(ownerId)
  const companyRegistration = partyApiSelectors.getPartyCompanyRegistration(state)(ownerId) || null
  const portfolios = portfolioApiSelectors.getPortfoliosByPropertyId(state)(propertyId)
  const propertyDescription = portfolioApiSelectors.getPortfolioMetaData(state)(id)

  return {
    title: propertyDescription
      ? `${propertyDescription}, ${pathOr('...', ['mainText'], property)}`
      : pathOr('...Loading', ['mainText'], property),
    subTitle: pathOr('...', ['secondaryText'], property),
    propertyId,
    partyType,
    owner: {
      name: partyName || '...',
      email: partyApiSelectors.getPartyEmailById(state)(ownerId) || '...',
      contactNumber: partyApiSelectors.getPartyTelNumberById(state)(ownerId) || '...',
      idNumber: idNumber,
      passportNumber: passportNumber,
      bank: bank || '...',
      accountNumber: accountNumber || '...',
      ownerId,
      companyRegistration: companyRegistration,
    },
    portfolios: portfolios.map((p: any) => ({
      id: pathOr('', ['id'], p),

      leaseDetails: {
        tenant: partyApiSelectors.getPartyNameById(state)(getPrimaryTenantId(id)),
        term: [path(['leaseTerms', 'startDate'], p), path(['leaseTerms', 'endDate'], p)],
        rental: pathOr(0, ['contractContainer', 'value', 'terms', 'monthlyRentAmount'], p),
        deposit: {
          key: pathOr([], ['invoiceTemplates'], p).find((t: any) => t.category === 'KeyDeposit'),
          damage: pathOr([], ['invoiceTemplates'], p).find((t: any) => t.category === 'DamageDeposit'),
          service: pathOr([], ['invoiceTemplates'], p).find((t: any) => t.category === 'ServiceDeposit'),
        },
        propertyAddress: propertyApiSelectors.getPropertyStreetAddress(state)(path(['propertyId'], p)),
        notes: pathOr('', ['settings', 'notes'], p),
        leaseType: pathOr('', ['tags', 'leaseType'], p).replace('Contract', ''),
        managementFeeSplits: pathOr([], ['commission', 'managementFee', 'splits'], p),
        procurementFeeSplits: pathOr([], ['commission', 'procurementFee', 'splits'], p),
      },

      smartInvoices: pathOr([], ['invoiceTemplates'], p)
        .filter((t: any) => t.interval !== 'OnceOff')
        .map((t: any) => ({
          label: invoiceApiSelectors.getInvoiceTypeNameByValue(state)(t.category),
          value: pathOr('Set on send', ['netAmount'], t),
        })),

      recentActivity: [],
    })),
    leaseSelectorOptions: portfolios.map((p: any) => {
      const id = path(['id'], p)
      const tenantId = getPrimaryTenantId(id)
      const ownerId = getPrimaryOwnerId(id)

      return {
        id,
        label: partyApiSelectors.getPartyNameById(state)(tenantId ?? ownerId),
        value: id,
        term: [path(['leaseTerms', 'startDate'], p), path(['leaseTerms', 'endDate'], p)],
        updatedAt: path(['updatedAt'], p),
      }
    }),
  }
})

export const getInitialValuesForEdit = createSelector([identity], state => (id: any) => ({
  propertyDescription: portfolioApiSelectors.getPortfolioMetaData(state)(id),
  buildingName: propertyApiSelectors.getBuildingNameById(state)(id),
  unitNumber: propertyApiSelectors.getUnitNumberById(state)(id),
  schemeNumber: propertyApiSelectors.getSchemeNumberById(state)(id),
  schemeName: propertyApiSelectors.getSchemeNameById(state)(id),
  streetAddress: propertyApiSelectors.getPropertyStreetAddress(state)(id),
  city: propertyApiSelectors.getPropertyCity(state)(id),
  province: propertyApiSelectors.getPropertyProvince(state)(id),
  postalCode: propertyApiSelectors.getPropertyPostalCode(state)(id),
  country: propertyApiSelectors.getPropertyCountry(state)(id),
}))

export const isNewPartyModalOpen = getSlice('isNewPartyModalOpen')
