import React from 'react'
import cx from 'classnames'
import Label from '../../../atoms/Label/Label'
import AddOn from '../../../atoms/Svgs/AddOn'
import Segments from '../../../atoms/Icons/Segments/Segments'
import styles from './FABs.module.scss'

type TProps = {
  className: string
  notificationCount: number
  onRobinClick: () => void
  onShortcutsClick: () => void
  onSegmentsClick: () => void
  isShortcutsDrawerOpen: boolean
  isSegmentsDrawerOpen: boolean
  isRobinDrawerOpen: boolean
  segmentFilterCount: number
  isReadOnly: boolean
}

const FABs = ({
  className,
  notificationCount = 0,
  onRobinClick,
  onShortcutsClick,
  onSegmentsClick,
  isShortcutsDrawerOpen,
  isSegmentsDrawerOpen,
  isRobinDrawerOpen,
  segmentFilterCount = 0,
  isReadOnly,
}: TProps) => {
  const classes = cx(styles.root, {
    [styles['shortcuts-drawer-open']]: isShortcutsDrawerOpen,
    [styles['segments-drawer-open']]: isSegmentsDrawerOpen,
    [styles['robin-drawer-open']]: isRobinDrawerOpen,
  })

  return (
    <div className={`${classes} ${className}`}>
      <button className={styles.segments} onClick={onSegmentsClick}>
        <Segments stroke="#fff" />
        {segmentFilterCount > 0 && (
          <div className={styles['segment-filters-count']}>
            <Label circular size="sm">
              {segmentFilterCount}
            </Label>
          </div>
        )}
      </button>
      <button className={styles.shortcuts} onClick={onShortcutsClick} disabled={isReadOnly}>
        <AddOn fill="#fff" />
      </button>
      <button className={styles.robin} onClick={onRobinClick}>
        reOS
      </button>
      <div className={styles['notifications-count']}>
        <Label circular size="sm">
          {notificationCount}
        </Label>
      </div>
    </div>
  )
}

export default FABs
