import React from 'react'
import { pipe, prop, reverse, sortBy } from 'ramda'
import { moduleContext } from 'react-contextual'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from 'constants/routes'
import { walletApiSelectors } from '../api/wallet'
import { layoutEvents } from '../Layout/state'
import { uiEvents } from '../ui'

const SuspenseAccountsProvider = moduleContext()(({ context, children, ...props }: any) => {
  const dispatch = useDispatch()

  const { match } = props
  const { params } = match
  const id = params?.id

  /** @todo get allocated and unallocated suspense accounts using selectors */
  const unallocatedReferences = useSelector(walletApiSelectors.getUnallocatedSuspenseAccounts)
  const allocatedReferences = useSelector(walletApiSelectors.getAllocatedSuspenseAccounts)

  const currentReference = useSelector(state => walletApiSelectors.getReferenceByTxId(state)(id))

  const filters = [
    {
      key: 'unallocated',
      label: 'Unallocated',
      // @ts-expect-error
      data: pipe(sortBy(prop('dateReceived')), reverse)(unallocatedReferences),
      count: unallocatedReferences.length,
    },
    {
      key: 'allocated',
      label: 'Allocated',
      // @ts-expect-error
      data: pipe(sortBy(prop('allocatedAt')), reverse)(allocatedReferences),
      count: allocatedReferences.length,
    },
  ]

  const suspenseAccountsStore = {
    match,
    filters,
    currentReference,
    isLoading: false,
    redirect: (path: any) => dispatch(uiEvents.redirect(path)),
    openReference: (id: any) => {
      dispatch(uiEvents.redirect(ROUTES.suspenseAccountView.replace(':id', id)))
      dispatch(layoutEvents.openPrimaryPanel('suspenseAccounts'))
      dispatch(layoutEvents.closeSidebar('global'))
    },
    closeReference: () => {
      dispatch(uiEvents.redirect(ROUTES.suspenseAccount))
      dispatch(layoutEvents.closePrimaryPanel('suspenseAccounts'))
      dispatch(layoutEvents.openSidebar('global'))
    },
    ...props,
  }
  return <context.Provider value={suspenseAccountsStore}>{children}</context.Provider>
})

export default SuspenseAccountsProvider
