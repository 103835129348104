import React, { useCallback, useEffect } from 'react'
import { moduleContext } from 'react-contextual'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { invoiceApiEvents, invoiceApiSelectors } from 'modules/api/invoice'
import { useEffectOnce } from 'react-use'
import { reconApiEvents, reconApiSelectors } from 'modules/api/recon'

const propTypes = {
  customerId: PropTypes.string,
  accountId: PropTypes.string,
}

const ActiveInvoicesProvider = moduleContext()(({ context, children, ...props }: any) => {
  const { customerId, accountId } = props
  const dispatch = useDispatch()

  const fetchInvoiceTypes = useCallback(() => dispatch(invoiceApiEvents.invoiceTypes_request()), [dispatch])

  useEffectOnce(() => {
    fetchInvoiceTypes()
  })

  useEffect(() => {
    dispatch(reconApiEvents.customerActiveInvoices_request({ customerId, accountId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, accountId])

  const activeInvoicesStore = {
    fetchInvoiceTypes,
    invoiceTypes: useSelector(state => invoiceApiSelectors.getInvoiceTypes(state)),
    activeInvoices: useSelector(state =>
      reconApiSelectors.getCustomerActiveInvoicesByAccount(state)(customerId, accountId),
    ),
  }

  return <context.Provider value={activeInvoicesStore}>{children}</context.Provider>
})

ActiveInvoicesProvider.propTypes = propTypes

export default ActiveInvoicesProvider
