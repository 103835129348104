import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import history from '../state/history'
import { ROUTES } from 'constants/routes'
import { InvoicesPageContainer, LoginPage, SettingsPageContainer, SignupPage } from './containers'
import {
  AppShell,
  ForgotPasswordPage,
  InvoicesPage,
  PrivateRoute,
  ResetPasswordPage,
  ScrollToTop,
  SettingsPage,
  VerifyEmail,
} from './components'
import { SearchFilterProvider } from './providers'
import { ContactsRoute, EditContactRoute, NewContactRoute } from '../modules/contacts'
import { PortfoliosRoute } from '../modules/portfolios/components'
import { DashboardRoute } from '../modules/dashboard'
import { AdhocInvoiceRoute } from '../modules/adhocInvoice'
import CommissionStatement from '../modules/accounting/components/CommissionStatement/CommissionStatement'
import DepositBankStatement from '../modules/accounting/components/DepositBankStatement/DepositBankStatement'
import { QueryParamProvider } from 'use-query-params'
import { BankStatement } from '../modules/accounting/components'
import { InvitationRoute, InviteUserRoute } from '../modules/multiUser'
import { BulkImportInvoicesRoute } from '../modules/bulkImportInvoices'
import { PropertiesRoute } from '../modules/properties/components'
import { SuspenseAccountsRoute } from '../modules/suspenseAccounts'
import { CommunicationsRoute } from '../modules/communication'
import { IntegrationsRoute } from '../modules/integrations'
import { ReportingRoute } from '../modules/reporting'
import { WalletTransferRoute } from '../modules/wallets/walletTransfer'
import { AgenciesRoute } from '../modules/agencies'
import { AdminReportingRoute } from 'modules/adminReporting'
import BulkImportContactsRoute from 'modules/bulkImportContacts/components/BulkImportContactsRoute'
import PaymentsPageContainer from './containers/Pages/PaymentsPageContainer'
import Payments from './components/pages/PaymentsPage/Payments'
import EnableUserRoute from 'modules/user/components/EnableUser/EnableUserRoute'

const InvoicesPageWrapper = (props: any): React.ReactElement => (
  <InvoicesPageContainer {...props}>
    <SearchFilterProvider location={props.location}>
      <InvoicesPage />
    </SearchFilterProvider>
  </InvoicesPageContainer>
)

InvoicesPageWrapper.propTypes = {
  location: PropTypes.object,
}

const PaymentsPageWrapper = (props: any): React.ReactElement => (
  <PaymentsPageContainer {...props}>
    <SearchFilterProvider location={props.location}>
      <Payments />
    </SearchFilterProvider>
  </PaymentsPageContainer>
)

PaymentsPageWrapper.propTypes = {
  location: PropTypes.object,
}

const SettingsPageWrapper = (props: any): React.ReactElement => (
  <SettingsPageContainer {...props}>
    <SettingsPage />
  </SettingsPageContainer>
)

export const Routes = (): React.ReactElement => {
  return (
    <ConnectedRouter history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <AppShell>
          <ScrollToTop>
            <Switch>
              <Route path={ROUTES.login} component={LoginPage} />
              <Route path={ROUTES.signup} component={SignupPage} />
              <Route path={ROUTES.invitation} component={InvitationRoute} />
              <Route path={ROUTES.forgotPassword} component={ForgotPasswordPage} />
              <Route path={ROUTES.resetPassword} component={ResetPasswordPage} />
              <Route path={ROUTES.verifyEmail} component={VerifyEmail} />
              <Route exact path={ROUTES.base} component={LoginPage} />
              <PrivateRoute strict exact path={ROUTES.dashboard} component={DashboardRoute} />
              <PrivateRoute
                path={[
                  ROUTES.portfolioStatement,
                  ROUTES.portfolioView,
                  ROUTES.portfolios,
                  ROUTES.portfolioEdit,
                  ROUTES.editProperty,
                ]}
                component={PortfoliosRoute}
              />
              <PrivateRoute path={[ROUTES.properties, ROUTES.editProperty]} component={PropertiesRoute} />
              <PrivateRoute path={ROUTES.commissionStatement} component={CommissionStatement} />
              <PrivateRoute path={ROUTES.bankStatement} component={BankStatement} />
              <PrivateRoute path={ROUTES.depositBankStatement} component={DepositBankStatement} />
              <PrivateRoute path={ROUTES.adhocInvoice} component={AdhocInvoiceRoute} />
              <PrivateRoute path={ROUTES.invoiceView} component={InvoicesPageWrapper} />
              <PrivateRoute path={ROUTES.invoices} component={InvoicesPageWrapper} />
              <PrivateRoute path={ROUTES.payments} component={PaymentsPageWrapper} />
              <PrivateRoute
                strict
                exact
                path={[ROUTES.customerDepositStatement, ROUTES.contactStatement, ROUTES.contactView, ROUTES.contacts]}
                component={ContactsRoute}
              />
              <PrivateRoute path={ROUTES.newContact} component={NewContactRoute} />
              <PrivateRoute path={ROUTES.editContact} component={EditContactRoute} />
              <PrivateRoute path={ROUTES.settings} component={SettingsPageWrapper} />
              <PrivateRoute path={ROUTES.inviteUser} component={InviteUserRoute} />
              <PrivateRoute
                path={[ROUTES.suspenseAccountView, ROUTES.suspenseAccount]}
                component={SuspenseAccountsRoute}
              />
              <PrivateRoute path={ROUTES.adminReporting} component={AdminReportingRoute} />
              <PrivateRoute path={[ROUTES.walletTransfer]} component={WalletTransferRoute} />
              <PrivateRoute
                strict
                exact
                path={[ROUTES.importInvoices, ROUTES.importInvoiceView]}
                component={BulkImportInvoicesRoute}
              />
              <PrivateRoute path={ROUTES.importContacts} component={BulkImportContactsRoute} />
              <PrivateRoute path={[ROUTES.agencies]} component={AgenciesRoute} />
              <PrivateRoute path={ROUTES.communications} component={CommunicationsRoute} />
              <PrivateRoute path={[ROUTES.integrationRedirect, ROUTES.integrations]} component={IntegrationsRoute} />
              <PrivateRoute path={ROUTES.reporting} component={ReportingRoute} />
              <PrivateRoute path={ROUTES.enableUser} component={EnableUserRoute} />
            </Switch>
          </ScrollToTop>
        </AppShell>
      </QueryParamProvider>
    </ConnectedRouter>
  )
}
