import React, { useState, useEffect, useMemo } from 'react'
import { subscribe } from 'react-contextual'
import { addMonths, format, getUnixTime, subMonths } from 'date-fns'
import { get, map } from 'lodash-es'
import { stringUtils } from '../../../../../../utils'
import { InvoicesProvider, SearchFilterProvider } from '../../../../../providers'
import InvoiceIcon from '../../../../../components/atoms/Svgs/Invoice'
import { AggregateTable, Header, CurrencyText, NoContent, DateRangePicker } from '../../../..'
import styles from './HistoryTable.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { pipe, map as rMap, reverse } from 'ramda'
import { sortByProps } from 'utils/array'
import SvgInvoiceFill from 'views/components/atoms/Icons/Doc/InvoiceFill'

const HistoryTable = ({
  historicInvoices = [],
  archivedPeriod,
  openInvoice,
  searchQuery,
  fetchHistoricInvoices,
  activeFilterIndex,
  currentInvoice,
}: any): React.ReactElement => {
  const [data, setData] = useState<$TSFixMe[]>([])
  const [startDate, setStartDate] = useState(
    archivedPeriod.startDate ? new Date(archivedPeriod.startDate) : subMonths(new Date(), 3),
  )
  const [endDate, setEndDate] = useState(
    archivedPeriod.endDate ? new Date(archivedPeriod.endDate) : addMonths(new Date(), 1),
  )

  useEffect(() => {
    const defaultSortedData: $TSFixMe[] = pipe(
      rMap((inv: any) => ({
        dueDateSeconds: format(new Date(inv.dueDate), 't'),
        ...inv,
      })),
      (list: any) => sortByProps(['dueDateSeconds', 'invoiceTo', 'property'], list),
    )(historicInvoices)

    setData(
      reverse(defaultSortedData).map((invoice: $TSFixMe) => ({
        ...invoice,
        meta: {
          invoiceNumber: invoice.invoiceNumber.toString(),
          invoiceTo: invoice.customerName,
          property: invoice.property,
          type: invoice.invoiceType,
          dueDate: invoice.dueDate ? getUnixTime(new Date(invoice.dueDate)).toString() : '',
          amount: invoice.grossAmount.toString(),
        },
      })),
    )
  }, [historicInvoices, startDate, endDate])

  const filteredInvoices = useMemo(() => {
    return data.filter(inv => stringUtils.jsonStringSearch(searchQuery, inv))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const columns = [
    {
      Header: 'NO.',
      accessor: 'invoiceNumber',
      style: {
        maxWidth: '100px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Invoice to',
      accessor: 'customerName',
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Property',
      accessor: 'property',
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Invoice Type',
      accessor: 'invoiceType',
      style: {
        maxWidth: '150px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Due date',
      accessor: 'dueDate',
      style: {
        maxWidth: '100px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
    {
      Header: 'Invoice Amount',
      accessor: 'grossAmount',
      style: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '20px',
        maxWidth: '120px',
      },
      sort: {
        enabled: true,
        order: false,
      },
    },
  ]

  const currentInvoiceId = currentInvoice?.id

  const invoiceData = useMemo(() => {
    const buildInvoiceObj = (inv: any): $TSFixMe => {
      const hasCreditNotes = inv.creditNotes.length > 0
      const amountMinusCreditNotes = hasCreditNotes
        ? inv.grossAmount - inv.creditNotes.reduce((acc: any, cn: any) => acc + cn.amount, 0)
        : inv.grossAmount

      return {
        id: inv.id,
        highlighted: currentInvoiceId === inv.id,
        meta: inv.meta, // used for sorting
        invoiceNumber: `INV-${inv.invoiceNumber as string}`,
        customerName: inv.customerName,
        property: inv.property,
        invoiceType: inv.invoiceType,
        dueDate: format(new Date(inv.dueDate), 'dd MMM yyyy'),
        grossAmount: inv.grossAmount ? <CurrencyText>{amountMinusCreditNotes}</CurrencyText> : <em>No amount set</em>,
      }
    }

    return searchQuery ? map(filteredInvoices, buildInvoiceObj) : map(data, buildInvoiceObj)
  }, [data, filteredInvoices, searchQuery, currentInvoiceId])

  return useMemo(
    () => (
      <div className={styles.root}>
        <div className={styles['header-wrapper']}>
          <Header icon={<SvgInvoiceFill />} text="History" />
          <span className={styles['datepicker-wrapper']}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={(date: any, isStartDate: any) => {
                if (date) {
                  if (isStartDate) {
                    setStartDate(date)
                    fetchHistoricInvoices(format(date, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'))
                  } else {
                    setEndDate(date)
                    fetchHistoricInvoices(format(startDate, 'yyyy-MM-dd'), format(date, 'yyyy-MM-dd'))
                  }
                }
              }}
              inline={false}
            />
          </span>
        </div>

        {invoiceData.length > 0 ? (
          <AggregateTable columns={columns} data={invoiceData} onRowClick={data => openInvoice(data.id, 'history')} />
        ) : (
          <NoContent heading="You don't have any historic invoices yet." />
        )}
      </div>
    ),
    [openInvoice, columns, invoiceData, startDate, endDate, fetchHistoricInvoices],
  )
}

export default subscribe(
  [InvoicesProvider, SearchFilterProvider],
  (
    { historicInvoices, archivedPeriod, openInvoice, fetchHistoricInvoices }: any,
    { searchQuery, activeFilterIndex }: any,
  ) => ({
    historicInvoices,
    archivedPeriod,
    openInvoice,
    fetchHistoricInvoices,
    searchQuery,
    activeFilterIndex,
  }),
)(HistoryTable)
