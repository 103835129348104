import { APP_URL } from '../../../constants/general'

export const NAMESPACE = 'api.property'

const baseUrl = `${APP_URL as string}/properties`

export const ENDPOINTS = {
  BASE: baseUrl,
  ENTITY: `${baseUrl}/:id/entity`,
  LIGHTSTONE_ENTITY: `${baseUrl}/lightstone/:lightstoneId/entity`,
  UPDATE: `${baseUrl}/:id`,
  SEARCH: `${baseUrl}/search`,
  COMBINED_SEARCH: `${baseUrl}/combined_search`,
  PLACE: `${APP_URL as string}/google_places/:placeId/entity`,
  PORTFOLIOS: `${baseUrl}/:id/portfolios`,
}

export const DEBOUCE_TIME = 1000
