import { connect } from 'react-redux'
import { PropertyForm } from '../../components'
import { chatbotEvents, chatbotSelectors } from '../../../modules/ui/ChatBot'
import { getPropertyInitialValues, propertyDetailsDialog } from 'modules/ui/ChatBot/Dialogs/PropertyDetails'
import { portfolioApiSelectors } from '../../../modules/api/portfolio'
import { leaseTypeSelectionDialog } from 'modules/ui/ChatBot/Dialogs/LeaseTypeSelection'
import { userApiSelectors } from '../../../modules/api/user'
import { $TSFixMe } from 'types/ts-migrate'

const mapStateToProps = (state: any) => {
  const currentPortfolioId = chatbotSelectors.getCurrentPortfolioId(state)
  return {
    currentPortfolioId,
    initialValues: getPropertyInitialValues(state),
    isActivePortfolio: portfolioApiSelectors.isActivePortfolio(state)(currentPortfolioId),
    isReadonly: userApiSelectors.isReadOnlyRole(state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearSelection: () => dispatch(propertyDetailsDialog.proceed((e: any) => e.clearSelection())),
    handleConfirmation: (values: $TSFixMe) => {
      dispatch(chatbotEvents.propertyConfirmationRequested(values))
    },
    handleManagedSelected: () =>
      dispatch(leaseTypeSelectionDialog.proceed((events: $TSFixMe) => events.managedLeaseSelected())),
    handleUnmanagedSelected: () =>
      dispatch(leaseTypeSelectionDialog.proceed((events: $TSFixMe) => events.unmanagedLeaseSelected())),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyForm)
