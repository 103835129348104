import React from 'react'
import { ofType } from 'redux-observable'
import { delay, filter, map, pluck } from 'rxjs/operators'
import { tag } from 'rxjs-spy/operators/tag'
import { events } from './'
import { portfolioApiEvents } from '../../api/portfolio'
import { appRefreshApiEvents } from '../../appRefresh'
import { agencyApiEvents } from '../../api/agency'
import Button from '../../../views/components/atoms/Button/Button'
import { notificationSelectors } from '..'
import { findIndex, pathOr } from 'ramda'
import { restfulErrorEvent } from '../../../utils/restful'
import { reconApiEvents } from '../../api/recon'
import { apiStateSelectors } from '../../api/apiState'
import { walletApiEvents } from '../../api/wallet'
import { invoiceApiEvents } from '../../api/invoice'
import { bankingApiEvents } from '../../api/banking'
import { communicationsApiEvents } from '../../communication/state'
import { StatusCodes } from 'http-status-codes'
import { userApiEvents } from '../../api/user'
import { $TSFixMe } from 'types/ts-migrate'
import { reportingApiEvents } from 'modules/api/reporting'
import { partyApiSelectors } from 'modules/api/party'

const notificationEpicFactory =
  ({ name, action, type, heading, message, autoDismiss, children }: any) =>
  (action$: any) =>
    action$.pipe(
      ofType(action),
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
      map(() => events.addNotification({ type, heading, message, children }, { autoDismiss })),
      tag(`module/epics/${name}`),
    )

export const autoDismissNotification = (action$: any, state$: any) =>
  action$.pipe(
    ofType(events.addNotification),
    pluck('meta'),
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    filter(meta => meta.autoDismiss),
    delay(5000),
    map(() => {
      const notifications = notificationSelectors.getNotifications(state$.value)
      const index = findIndex((n: any) => n.autoDismiss, notifications)

      return events.clearNotification(index)
    }),
    tag('module/epics/autoDismissNotification'),
  )

export const renewalSuccess = notificationEpicFactory({
  name: 'renewalSuccess',
  action: portfolioApiEvents.renewal_success,
  type: 'success',
  heading: 'Renewal successful',
})

export const cancelRenewal = notificationEpicFactory({
  name: 'renewalSuccess',
  action: portfolioApiEvents.cancelRenewal_success,
  type: 'success',
  heading: 'Renewal cancelled',
})

export const versionMismatched = notificationEpicFactory({
  name: 'versionMismatched',
  action: appRefreshApiEvents.versionMismatched,
  type: 'info',
  heading: 'An update is available!',
  message: 'A new version of reOS has been released, click Update below to ensure you are up to date.',
  autoDismiss: false,
  children: (
    <Button
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; primary: true; size: str... Remove this comment to see the full error message
      primary
      size="sm"
      onClick={() => window.location.reload()}
    >
      Update now!
    </Button>
  ),
})

export const partyInviteSuccess = notificationEpicFactory({
  name: 'partyInviteSuccess',
  action: agencyApiEvents.sendPartyInvite_success,
  type: 'success',
  heading: 'Invitation has been sent',
})

export const inviteSuccess = notificationEpicFactory({
  name: 'inviteSuccess',
  action: agencyApiEvents.invite_success,
  type: 'success',
  heading: 'Invitation has been sent',
})

export const resendVerificationEmailSuccess = notificationEpicFactory({
  name: 'resendVerificationEmailSuccess',
  action: userApiEvents.resendEmailVerification_success,
  type: 'success',
  heading: 'Email verification sent',
})

export const existingInvite = notificationEpicFactory({
  name: 'existingInvite',
  action: agencyApiEvents.existingInviteNotification,
  type: 'error',
  heading: 'Invitation not sent',
  message: 'An invitation has already been sent to someone with this email address.',
})

export const failedInvite = notificationEpicFactory({
  name: 'failedInvite',
  action: agencyApiEvents.failedInviteNotification,
  type: 'error',
  heading: 'Invitation failed to sent',
})

export const addToGroupSuccess = notificationEpicFactory({
  name: 'addToGroupSuccess',
  action: agencyApiEvents.addToGroup_success,
  type: 'success',
  heading: 'Agency added to group',
  message: 'Reload is required to see updated list of agencies and groups.',
  autoDismiss: false,
  children: (
    <Button
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; primary: true; size: str... Remove this comment to see the full error message
      primary
      size="sm"
      onClick={() => window.location.reload()}
    >
      Reload
    </Button>
  ),
})

export const removeFromGroupSuccess = notificationEpicFactory({
  name: 'removeFromGroupSuccess',
  action: agencyApiEvents.removeFromGroup_success,
  type: 'success',
  heading: 'Agency removed from group',
  message: 'Reload is required to see updated list of agencies and groups.',
  autoDismiss: false,
  children: (
    <Button
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; primary: true; size: str... Remove this comment to see the full error message
      primary
      size="sm"
      onClick={() => window.location.reload()}
    >
      Reload
    </Button>
  ),
})

/**
 * Invoice notifications
 */
export const invoiceCreatedSuccess = notificationEpicFactory({
  name: 'invoiceCreatedSuccess',
  action: invoiceApiEvents.createOnceOffInvoice_success,
  type: 'success',
  heading: 'Invoice created',
})

/**
 * Recon notifications
 */

const filterErrorInitAction = (initAction: any) => (event: any) =>
  event?.meta?.initAction.type === initAction.toString()

export const failedBeneficiaryUpdate = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(reconApiEvents.updateBenficiaries_request)),
    map(event => {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      const payloadKey = event?.meta?.initAction?.payload
      const generalErrors = apiStateSelectors.getGeneralFormErrorsByEvent(state$.value)(
        reconApiEvents.updateBenficiaries_request(payloadKey),
      )
      return events.addNotification(
        {
          type: 'error',
          heading: 'Beneficaries update failed',
          children: (
            <>
              {generalErrors.map((e: any, i: any) => (
                <p key={i}>{e}</p>
              ))}
            </>
          ),
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/failedBeneficiaryUpdate'),
  )

export const failedApprovePayment = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(reconApiEvents.payment_request)),
    map(event => {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      const payloadKey = event?.meta?.initAction?.payload
      const generalErrors = apiStateSelectors.getGeneralFormErrorsByEvent(state$.value)(
        reconApiEvents.payment_request(payloadKey),
      )
      return events.addNotification(
        {
          type: 'error',
          heading: 'Payment approval failed',
          children: (
            <>
              {generalErrors.map((e: any, i: any) => (
                <p key={`fap-${i}`}>{e}</p>
              ))}
            </>
          ),
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/failedApprovePayment'),
  )

export const failedCreditNote = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(reconApiEvents.creditNote_request)),
    map(event => {
      const generalErrors = apiStateSelectors.getGeneralFormErrorsByEvent(state$.value)(
        reconApiEvents.creditNote_request,
      )
      return events.addNotification(
        {
          type: 'error',
          heading: 'Credit note failed',
          children: (
            <>
              {generalErrors.map((e: any, i: any) => (
                <p key={`fcn-${i}`}>{e}</p>
              ))}
            </>
          ),
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/failedCreditNote'),
  )

export const invoiceNudgeSuccess = (action$: any, state$: any) =>
  action$.pipe(
    ofType(reconApiEvents.invoicesNudge_success),
    map(event => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
      const payloadKey = event.payload.nudgeResult
      if (payloadKey && payloadKey.length === 1) {
        return events.addNotification(
          {
            type: 'success',
            heading: 'Invoice nudged',
            message: '',
          },
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
          { autoDismiss: true },
        )
      } else {
        const notifications = notificationSelectors.getNotifications(state$.value)
        const index = findIndex((n: any) => n.autoDismiss, notifications)

        return events.clearNotification(index)
      }
    }),
    tag('module/epics/invoiceNudgeSuccess'),
  )

export const invoiceNudgeFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(reconApiEvents.invoicesNudge_request)),
    map(event => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'meta' does not exist on type '{}'.
      const payloadKey = event.meta.initAction.payload
      if (payloadKey && payloadKey.invoiceIds.length === 1) {
        return events.addNotification(
          {
            type: 'error',
            heading: 'Invoice nudge failed',
            children: <p>You can only nudge an invoice every 24 hours.</p>,
          },
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
          { autoDismiss: false },
        )
      } else {
        const notifications = notificationSelectors.getNotifications(state$.value)
        const index = findIndex((n: any) => n.autoDismiss, notifications)

        return events.clearNotification(index)
      }
    }),
    tag('module/epics/invoiceNudgeFailed'),
  )

/**
 * Invoices
 */
export const failedSendInvoice = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(invoiceApiEvents.sendInvoices_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Send invoice failed',
          children: <p>Something went wrong, please reload and try again.</p>,
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/failedSendInvoice'),
  )

/** @todo fix this logic, it's dispatching for all invoice sends including adhoc invoices */
// export const failedBulkSendInvoices = (action$: $TSFixMe, state$: $TSFixMe) =>
//   action$.pipe(
//     ofType(invoiceApiEvents.sendInvoices_success),
//     pluck('payload'),
//     map((payload: $TSFixMe) => {
//       return events.addNotification(
//         {
//           type: 'error',
//           heading: 'Some bulk sends failed',
//           children: 'Invoices that failed to send are highlighted in red.',
//         },
//         // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
//         {
//           autoDismiss: false,
//         },
//       )
//     }),
//     tag('module/epics/failedBulkSendInvoices'),
//   )

export const failedDeleteInvoice = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(invoiceApiEvents.deleteInvoices_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Delete invoice failed',
          children: <p>Something went wrong, please reload and try again.</p>,
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/failedDeleteInvoice'),
  )

/**
 * Payments
 */
export const reversePaymentsFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(walletApiEvents.reversePayments_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Error reversing payments',
          children: <p>Something went wrong, please reload and try again.</p>,
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/reversePaymentsFailed'),
  )

export const demandPaymentsFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(walletApiEvents.demandPayments_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Error sending payments',
          children: <p>Something went wrong, please reload and try again.</p>,
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/demandPaymentsFailed'),
  )

export const saveBulkInvoices = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(invoiceApiEvents.saveBulkInvoices_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Error saving bulk import data',
          children: <p>Something went wrong, please try again.</p>,
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/saveBulkInvoices'),
  )

export const reversePaymentsSuccess = (action$: any, state$: any) =>
  action$.pipe(
    ofType(walletApiEvents.reversePayments_success),
    map(event => {
      return events.addNotification(
        {
          type: 'success',
          heading: 'Payments reversed',
          message: '',
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: true },
      )
    }),
    tag('module/epics/reversePaymentsSuccess'),
  )

export const demandPaymentsSuccess = (action$: any, state$: any) =>
  action$.pipe(
    ofType(walletApiEvents.demandPayments_success),
    map(event => {
      return events.addNotification(
        {
          type: 'success',
          heading: 'Payments sent',
          message: '',
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: true },
      )
    }),
    tag('module/epics/demandPaymentsSuccess'),
  )

export const approvePaymentsFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(bankingApiEvents.partiesErrors_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Error approving payments',
          children: <p>Please resolve all beneficiaries banking details issues and try again.</p>,
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/approvePaymentsFailed'),
  )

// Suspense Account notifications
export const suspenseAccountAllocationSuccess = notificationEpicFactory({
  name: 'suspenseAccountAllocationSuccess',
  action: walletApiEvents.allocateSuspenseAccount_success,
  type: 'success',
  heading: 'Allocation successful',
})

// Bulk Wallet Transfer notifications
export const bulkWalletTransferFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(walletApiEvents.bulkWalletTransfer_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Bulk Wallet Transfer failed',
          children: <p>Something went wrong, please try again.</p>,
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/bulkWalletTransferFailed'),
  )

export const bulkWalletTransferSuccess = notificationEpicFactory({
  name: 'bulkWalletTransferSuccess',
  action: walletApiEvents.bulkWalletTransfer_success,
  type: 'success',
  heading: 'Bulk Wallet Transfer successful',
})

// Commission withdrawal via the Robin Drawer
export const commissionWalletWithdrawalSuccess = (action$: any, state$: any) =>
  action$.pipe(
    ofType(walletApiEvents.payout_success),
    map(event => {
      return events.addNotification(
        {
          type: 'success',
          heading: 'Commission withdrawal successful',
          message: '',
        },
        // @ts-expect-error
        { autoDismiss: true },
      )
    }),
    tag('module/epics/commissionWalletWithdrawalSuccess'),
  )

export const commissionWalletWithdrawalFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(walletApiEvents.payout_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Commission withdrawal failed',
          children: <p>Something went wrong, please try again.</p>,
        },
        // @ts-expect-error
        { autoDismiss: false },
      )
    }),
    tag('module/epics/commissionWalletWithdrawalFailed'),
  )

/**
 * Email notifications
 */

export const resendSuccess = notificationEpicFactory({
  name: 'resendSuccess',
  action: communicationsApiEvents.resend_success,
  type: 'success',
  heading: 'Email successfully resent',
})

export const resentFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(communicationsApiEvents.resend_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Resending Email Failed',
          children: (
            <p>
              {pathOr('Please try resending the email again.', ['payload', 'response', 'errors', 0, 'message'], event)}
            </p>
          ),
        },
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
        { autoDismiss: false },
      )
    }),
    tag('module/epics/resentFailed'),
  )

export const addInvoiceTemplateFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(portfolioApiEvents.addInvoiceTemplate_request)),
    filter(({ payload }) => payload?.status === StatusCodes.CONFLICT),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Invoice template creation failed',
          children: <p>There was a conflict saving invoice templates, please reload and try again</p>,
        },
        // @ts-expect-error
        { autoDismiss: false },
      )
    }),
    tag('module/epics/addInvoiceTemplateFailed'),
  )

export const amendContractFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(portfolioApiEvents.amendRentAndFees_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Failed to save',
          children: (
            <p>
              {pathOr(
                'Something went wrong. Please try again or contact support.',
                ['payload', 'response', 'errors', 0, 'message'],
                event,
              )}
            </p>
          ),
        },
        // @ts-expect-error
        { autoDismiss: false },
      )
    }),
    tag('module/epics/amendContractFailed'),
  )

export const enableUserFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(userApiEvents.enableUser_request)),
    map(event => {
      return events.addNotification(
        {
          type: 'error',
          heading: 'Failed to enable user',
          children: (
            <p>
              {pathOr(
                'Something went wrong. Please try again or contact support.',
                ['payload', 'response', 'errors', 0, 'message'],
                event,
              )}
            </p>
          ),
        },
        // @ts-expect-error
        { autoDismiss: false },
      )
    }),
    tag('module/epics/enableUserFailed'),
  )

export const downloadPropertyTaxStatementFailed = (action$: any, state$: any) =>
  action$.pipe(
    ofType(restfulErrorEvent),
    filter(filterErrorInitAction(reportingApiEvents.propertyTaxStatement_request)),
    map(event => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type '{}'.
      const ownerName = event.meta.initAction.payload.ownerName
      return events.addNotification(
        {
          type: 'error',
          heading: `No Taxable Income for ${ownerName} for the selected date range.`,
          message: '',
        },
        // @ts-expect-error
        { autoDismiss: false },
      )
    }),
    tag('module/epics/downloadPropertyTaxStatementFailed'),
  )
