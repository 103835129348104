import { connect } from 'react-redux'
import { hacks } from '../../../utils'
import { propertySearchEvents, propertySearchSelectors } from '../../../modules/ui/PropertySearch'
import { propertyApiEvents, propertyApiSelectors } from '../../../modules/api/property'
import { propertyDetailsDialog } from 'modules/ui/ChatBot/Dialogs/PropertyDetails'
import { Search } from '../../components'
import debounce from 'lodash-es/debounce'
import { $TSFixMe } from 'types/ts-migrate'
import { apiStateSelectors } from 'modules/api/apiState'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: any, { value = '', isReadonly }: $TSFixMe) => {
  const isLoading = apiStateSelectors.isLoading(state)([propertyApiEvents.search_request])
  const name = hacks.generateNameToFoolChromeAutofill('propertySearch-')
  const searchTerm = propertySearchSelectors.getCurrentSearchQuery(state)
  const results = propertyApiSelectors.getSearchResultsByQuery(state)(searchTerm)
  const alternativeResults = propertyApiSelectors.getCombinedSearchResultsByQuery(state)(searchTerm)
  const sortedResults = propertyApiSelectors.getSearchResultsSortedByMainTextUnitNumber(state)(searchTerm)
  const placeholder = 'e.g. 7 Main Road'
  const label = 'Search Property Address'

  return {
    isLoading,
    name,
    resultSets: [results],
    alternativeResultSets: [alternativeResults],
    sortedResultSets: [sortedResults],
    placeholder,
    label,
    value,
    disabled: isReadonly,
    showAlternativeSearchTrigger: true,
    showExistingPropertyTags: true,
    showTertiaryText: true,
    showHelperText: true,
  }
}

const debouncedPropertySearch = debounce((dispatch: any, value: any) => {
  dispatch(propertySearchEvents.propertySearchQuery(value))
}, 500)

const debouncedCombinedPropertySearch = debounce((dispatch: any, value: any) => {
  dispatch(propertySearchEvents.propertyCombinedSearchQuery(value))
}, 1000)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch, { onChange = () => {}, onResultSelect, value }: any) => ({
  value,

  onSearchChange: (e: any) => {
    onChange(e)
    debouncedPropertySearch(dispatch, e.target.value)
  },

  onAlternativeSearchClick: (value: any) => {
    debouncedCombinedPropertySearch(dispatch, value)
  },

  onResultSelect: (e: any, { result }: any) => {
    if (typeof onResultSelect === 'function') {
      onResultSelect(result)
    } else {
      const { id, placeId, lightstoneId } = result

      if (id !== undefined) {
        dispatch(propertyApiEvents.property_request({ id }))
        dispatch(propertyDetailsDialog.proceed((events: any) => events.propertySelected(id)))
      } else if (lightstoneId) {
        dispatch(propertyApiEvents.lightstoneProperty_request({ lightstoneId }))
        dispatch(propertyDetailsDialog.proceed((events: any) => events.lightstonePropertySelected(lightstoneId)))
      } else if (placeId !== undefined) {
        dispatch(propertyApiEvents.place_request({ placeId }))
        dispatch(propertyDetailsDialog.proceed((events: any) => events.googlePlaceSelected(placeId)))
      }
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)
