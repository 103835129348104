import React from 'react'
import { animated, useSpring } from 'react-spring'
import AddIcon from '../../../atoms/Svgs/Add'
import InvoiceIcon from '../../../atoms/Svgs/Invoice'
import ContactsWhiteIcon from '../../../atoms/Svgs/Contacts'
import ContactSettingsFill from '../../../atoms/Icons/User/ContactSettingsFill'
import ImportIcon from '../../../atoms/Icons/Doc/Csv'
import SegmentsIcon from '../../../atoms/Icons/Segments/Segments'
import CloseIcon from '../../../atoms/Svgs/Close'
import { animationConfig } from '../../../../constants'
import Shortcut from './Shortcut/Shortcut'
import styles from './ShortcutsDrawer.module.scss'
import { ROUTES } from '../../../../../constants/routes'
import { useSelector } from 'react-redux'
import { userApiSelectors } from 'modules/api/user'

type TProps = {
  open: boolean
  onClose: () => void
  userRole: string
  redirect: (path: string) => void
  agencyCreated: boolean
}

const ShortcutsDrawer = ({ open, onClose, userRole, redirect, agencyCreated }: TProps) => {
  const isValidSupportStaff = useSelector(userApiSelectors.isValidSupportStaff)
  const shortcuts = [
    {
      icon: <AddIcon />,
      title: 'Create a New Lease',
      subtitle: "We'll make sure it runs like clockwork",
      onClick: () => redirect('/leases/new/edit'),
      visible: true,
    },
    {
      icon: <InvoiceIcon />,
      title: 'Create an Ad hoc Invoice',
      subtitle: 'Send or save a once-off invoice',
      onClick: () => redirect(ROUTES.adhocInvoice),
      visible: agencyCreated,
    },
    {
      icon: <ContactsWhiteIcon />,
      title: 'Add a Contact',
      subtitle: 'Easily add a tenant, owner or supplier',
      onClick: () => redirect(ROUTES.newContact),
      visible: true,
    },
    {
      icon: <ContactSettingsFill fill="#fff" />,
      title: 'Invite a User',
      subtitle: 'Add someone to help manage your book',
      onClick: () => redirect(ROUTES.inviteUser),
      visible: userRole === 'Owner' && agencyCreated,
    },
    {
      icon: <SegmentsIcon stroke="#fff" />,
      title: 'Add a New Segment',
      subtitle: 'Helps you keep your leases organised',
      onClick: () => redirect(ROUTES.segmentView.replace(':id', 'new')),
      visible: userRole === 'Owner' && agencyCreated,
    },
    {
      icon: <ImportIcon stroke="#fff" />,
      title: 'Bulk import invoices',
      subtitle: 'Import invoices from CSV file',
      onClick: () => redirect(ROUTES.importInvoices),
      visible: isValidSupportStaff,
    },
    {
      icon: <ImportIcon stroke="#fff" />,
      title: 'Bulk import contacts',
      subtitle: 'Import contacts from CSV file',
      onClick: () => redirect(ROUTES.importContacts),
      visible: isValidSupportStaff,
    },
    {
      icon: <AddIcon />,
      title: 'Enable User',
      subtitle: 'Enable a user to access the system',
      onClick: () => redirect(ROUTES.enableUser),
      visible: isValidSupportStaff,
    },
    // {
    //   icon: <PropertyIcon />,
    //   title: 'Add a Property',
    //   subtitle: 'Quickly add a property to your book',
    //   onClick: () => dispatch(redirect('/invoice/new'))
    // }
  ].map(shortcut => ({
    ...shortcut,
    onClick: () => {
      onClose()
      shortcut.onClick()
    },
  }))

  const style = useSpring({
    reverse: !open,
    config: animationConfig,
    to: { transform: 'translateX(0)' },
    from: { transform: 'translateX(110%)' },
  })

  return (
    <animated.div id="rbn-shortcuts" className={styles.root} style={style}>
      <button className={styles.close} onClick={onClose}>
        <CloseIcon />
      </button>
      <h1>Shortcuts</h1>
      <ul className={styles.shortcuts}>
        {shortcuts
          .filter(s => s.visible)
          .map((s, i) => (
            <li key={i}>
              <Shortcut icon={s.icon} title={s.title} subtitle={s.subtitle} onClick={s.onClick} />
            </li>
          ))}
      </ul>
    </animated.div>
  )
}

export default ShortcutsDrawer
