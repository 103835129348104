import React from 'react'
import { get } from 'lodash-es'
import ContextMenu from '../../../molecules/ContextMenu/ContextMenu'
import styles from './InvoiceCard.module.scss'

type TProps = {
  invoice: {}
  onClose: () => void
  onContextMenuItemSelect?: () => void
  contextMenuOptions: { label: string; value?: string; isHidden: boolean; onSelect: () => void }[]
  headerBefore?: React.ReactNode
  children: React.ReactNode
}

const InvoiceCard = ({ invoice, onClose, contextMenuOptions, headerBefore, children }: TProps) => {
  const invoiceNumber = get(invoice, 'number', get(invoice, 'invoiceNumber', ''))
  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <div className={styles.title}>
          {headerBefore && <div>{headerBefore}</div>}
          <h3>{invoiceNumber}</h3>
        </div>
        <div className={styles.controls}>
          <button className={styles.close} onClick={onClose}>
            Close
          </button>
          {contextMenuOptions && (
            <div className={styles.menu}>
              <ContextMenu items={contextMenuOptions} position="left" showInput={false} />
            </div>
          )}
        </div>
      </header>
      <div>{children}</div>
    </div>
  )
}

export default InvoiceCard
