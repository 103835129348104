import { createSelector } from 'reselect'
import { find, get, sumBy } from 'lodash-es'
import { pipe, path, reject, map, isEmpty, pathOr, pathEq, identity } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import { adhocOnlyInvoiceTypes } from './constants'

const state = (state: any) => state

const invoiceState = (state: any) => state.api.invoice

export const getInvoices = createSelector(invoiceState, s => get(s, 'invoices', []))

export const getInvoiceTypes = createSelector(invoiceState, s => get(s, 'invoiceTypes', []))

export const getInvoiceTypesForAdhocInvoices = createSelector(getInvoiceTypes, identity)

export const getInvoiceTypesForNonAdhocInvoices = createSelector(getInvoiceTypes, invoiceTypes =>
  invoiceTypes.filter((it: { value: string }) => !adhocOnlyInvoiceTypes.includes(it.value)),
)

export const getFilterQueryInvoices = createSelector(invoiceState, s => s.byFilterQuery)

/**
 * Draft Invoices
 */
export const getDraftInvoices = createSelector(invoiceState, s =>
  s.invoices.filter((i: any) => i.tags.status === 'Draft'),
)

export const getRemovedDraftInvoices = createSelector(invoiceState, s =>
  s.invoices.filter((i: any) => i.tags.status === 'Removed Draft'),
)

export const getDraftInvoicesCount = createSelector(getDraftInvoices, draft => draft.length)

export const getDraftInvoicesByLightStatus = createSelector(
  getDraftInvoices,
  draft => (lightStatus: any, portfolioId: any) => {
    let filteredInvoices = draft.filter((inv: any) => inv.tags.lightStatus === lightStatus)
    if (portfolioId) {
      filteredInvoices = filteredInvoices.filter((inv: any) => inv.portfolioId === portfolioId)
    }
    return filteredInvoices
  },
)

export const getRemovedDraftInvoicesByLightStatus = createSelector(
  getRemovedDraftInvoices,
  draft => (lightStatus: any, portfolioId: any) => {
    let filteredInvoices = draft.filter((inv: any) => inv.tags.lightStatus === lightStatus)
    if (portfolioId) {
      filteredInvoices = filteredInvoices.filter((inv: any) => inv.portfolioId === portfolioId)
    }
    return filteredInvoices
  },
)

export const getInvoiceById = createSelector(
  getInvoices,
  invoices => (id: any) => find(invoices, (invoice: any) => invoice.id === id),
)

export const isDraftRemoved = createSelector(
  state,
  s => (id: any) => pathEq(['tags', 'status'], 'Removed Draft', getInvoiceById(s)(id)),
)

export const getInvoiceParties = createSelector(state, s => (id: any) => {
  const invoice = getInvoiceById(s)(id)
  const partyId = get(invoice, 'customer.partyId', false)
  const invoicePartyIds: any[] = []
  partyId && invoicePartyIds.push(partyId)
  if (invoice) {
    const partyIds: $TSFixMe = pipe(
      path(['beneficiaries']),
      /** @todo create makeshift enum/const thang for party beneficiary types */
      // @ts-expect-error
      map(reject((b: any) => b.type !== 'PartyBeneficiary')),
      reject(isEmpty),
      pipe(map(path(['beneficiary', 'value', 'partyId']))),
    )(invoice)
    partyIds.forEach((id: any) => invoicePartyIds.push(id))
  }
  return invoicePartyIds
})

export const getInvoiceEasyPayBeneficiaryReferences = createSelector(state, s => (id: any) => {
  const invoice = getInvoiceById(s)(id)
  if (!invoice) return []
  const easyPayBeneficiaries: $TSFixMe = pipe(
    path(['beneficiaries']),
    /** @todo create makeshift enum/const thang for party beneficiary types */
    // @ts-expect-error
    map(reject((b: any) => b.type !== 'EasyPayBeneficiary')),
    reject(isEmpty),
  )(invoice)
  return pipe(map(path(['beneficiary', 'value', 'easyPayReference'])))(easyPayBeneficiaries)
})

export const getFilteredInvoices = createSelector(getInvoices, invoices => (query: any) => {
  return invoices
    .map((invoice: any) => {
      const { customer, invoiceType } = invoice
      const searchString = customer.name + invoiceType
      if (searchString.toLowerCase().includes(query)) {
        return invoice
      } else {
        return undefined
      }
    })
    .filter((i: any) => i !== undefined)
})

export const getInvoiceTypeByValue = createSelector(getInvoiceTypes, types => (value: any) => {
  return find(types, (t: any) => t.value === value) || false
})

export const getInvoiceTypeNameByValue = createSelector(state, s => (value: any) => {
  const invoiceType = getInvoiceTypeByValue(s)(value)
  return get(invoiceType, 'name', '')
})

export const getPropertyName = createSelector([state, getInvoices], (s, invoices) => (id: any) => {
  const invoice = getInvoiceById(s)(id)
  return get(invoice, 'property', '')
})

/**
 * Notifications
 */
export const getNotifications = createSelector(invoiceState, invoiceState => invoiceState.notifications)

export const getNotificationByName = createSelector(
  getNotifications,
  notifications => (name: any) => notifications.find((n: any) => n.name === name),
)

export const getNotificationCountByName = createSelector(state, state => (name: any) => {
  const notification = getNotificationByName(state)(name)
  return get(notification, 'count', 0)
})

export const getNotificationCountTotal = createSelector(getNotifications, notifications =>
  sumBy(notifications, (n: any) => n.count),
)

export const getDraftInvoicesForPortfolio = createSelector(
  invoiceState,
  s => (id: any) => pathOr([], ['draftInvoicesById', id], s),
)
