import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { is, prop } from 'ramda'
import { apiStateSelectors } from '../../../api/apiState'
import { reportingApiEvents } from 'modules/api/reporting'
import { userApiSelectors } from 'modules/api/user'
import { format, startOfMonth, endOfMonth, subMonths } from 'date-fns'
import { DateRangePicker, DimmerLoader, Header, Modal, Table, Select } from '../../../../views/components'
import ReportingIcon from '../../../../views/components/atoms/Icons/Descriptive/ReportsFill'
import DownloadIcon from '../../../../views/components/atoms/Icons/Doc/DocumentDownload'
import PdfIcon from '../../../../views/components/atoms/Icons/Doc/PdfAlt'
import styles from './AdminReportingTable.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { useEffectOnce } from 'react-use'
import { agencyApiEvents } from 'modules/api/agency'

const AdminReportingTable = () => {
  const dispatch = useDispatch()
  const dateFormat = 'yyyy-MM-dd'

  const [selectedReport, setSelectedReport] = useState<$TSFixMe>(null)

  const isValidSupportStaff = useSelector(userApiSelectors.isValidSupportStaff)

  useEffectOnce(() => {
    dispatch(agencyApiEvents.agencyListing_request())
  })

  const downloadReport = useCallback(
    action => {
      dispatch(action)
    },
    [dispatch],
  )

  const reports = [
    {
      id: 'franchisePerformance',
      name: 'Rawson Performance Report',
      dateParams: {
        start: format(startOfMonth(subMonths(Date.now(), 1)), dateFormat),
        finish: format(endOfMonth(Date.now()), dateFormat),
      },
      groupParams: {
        groupId: '754cceb6-f295-4d05-85c8-9d7ca4edeb1b',
      },
      action: reportingApiEvents.franchisePerformance_request,
      actionArgs: { dataType: 'excel' },
      isVisible: isValidSupportStaff,
    },
    {
      id: 'activeLeases',
      name: 'reOS Active Customers',
      dateParams: {
        start: format(startOfMonth(subMonths(Date.now(), 1)), dateFormat),
        finish: format(endOfMonth(Date.now()), dateFormat),
      },
      action: reportingApiEvents.activeLeases_request,
      actionArgs: { dataType: 'excel' },
      isVisible: isValidSupportStaff,
    },
    {
      id: 'walletNumbers',
      name: 'Wallet Numbers',
      dateParams: null,
      action: reportingApiEvents.wallets_request,
      actionArgs: { dataType: 'excel' },
      isVisible: isValidSupportStaff,
    },
  ].filter(item => !!item?.isVisible)

  const loadingEvents = reports.map(prop('action'))
  const isLoading = useSelector(state => apiStateSelectors.isLoading(state)(loadingEvents))

  return (
    <div className={styles.root}>
      <Header icon={<ReportingIcon />} text="Reporting" />
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell head>Report</Table.Cell>
            <Table.Cell head>&nbsp;</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {reports.map((report, i) => (
            <Table.Row key={`report-row-${i}`}>
              <Table.Cell>{report.name}</Table.Cell>
              <Table.Cell className={styles['action-cell']}>
                <button type="button" onClick={() => setSelectedReport(report)}>
                  <DownloadIcon />
                </button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Modal isOpen={selectedReport?.id} padding="sm" showClose={true} onClose={() => setSelectedReport(null)}>
        <Modal.Header title={selectedReport?.name} />
        <Modal.Body
          actions={
            isLoading
              ? []
              : selectedReport?.id === 'franchisePerformance'
              ? [
                  {
                    label: 'Download',
                    onClick: () => {
                      downloadReport(
                        selectedReport?.action({
                          ...selectedReport.actionArgs,
                          ...selectedReport.dateParams,
                          ...selectedReport.groupParams,
                        }),
                      )
                    },
                  },
                ]
              : [
                  {
                    label: 'Download',
                    onClick: () => {
                      downloadReport(
                        selectedReport?.action({
                          ...selectedReport.actionArgs,
                          ...selectedReport.dateParams,
                        }),
                      )
                    },
                  },
                ]
          }
        >
          <DimmerLoader loading={isLoading} fadeStyle="flat" bgColor="white">
            <>
              {selectedReport?.dateParams?.start && selectedReport?.dateParams?.finish ? (
                <>
                  {!selectedReport?.propertyParams && (
                    <p>Select a date range and click the button below to download an Excel version of this report.</p>
                  )}
                  {selectedReport?.info && selectedReport.info}
                  <DateRangePicker
                    startLabel={selectedReport?.propertyParams ? 'Select a start date' : ''}
                    endLabel={selectedReport?.propertyParams ? 'Select a end date' : ''}
                    startDate={selectedReport.dateParams.start}
                    endDate={selectedReport.dateParams.finish}
                    onChange={(date: $TSFixMe, isStartDate: boolean) => {
                      isStartDate
                        ? setSelectedReport({
                            ...selectedReport,
                            dateParams: {
                              ...selectedReport.dateParams,
                              start: format(new Date(date), dateFormat),
                            },
                          })
                        : setSelectedReport({
                            ...selectedReport,
                            dateParams: {
                              ...selectedReport.dateParams,
                              finish: format(new Date(date), dateFormat),
                            },
                          })
                    }}
                    inline={false}
                  />
                </>
              ) : (
                <p>Click the button below to download an Excel version of this report.</p>
              )}
            </>
          </DimmerLoader>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AdminReportingTable
