import React, { useEffect, useMemo, useState } from 'react'
import { AggregateTable, CurrencyText, Header, Loader } from 'views/components'
// import PropTypes from 'prop-types'
import styles from './SuspenseAccountsTable.module.scss'
import SettingIcon from 'components/atoms/Icons/Controls/Settings'
import { pipe, reverse, map as rMap } from 'ramda'
import { formatStandard } from 'utils/date'
import { subscribe } from 'react-contextual'
import { SearchFilterProvider } from 'views/providers'
import SuspenseAccountsProvider from '../../SuspenseAccountsProvider'
import { stringUtils } from '../../../../utils'
import { useSelector } from 'react-redux'
import { apiStateSelectors } from '../../../api/apiState'
import { walletApiEvents } from '../../../api/wallet'
import { $TSFixMe } from 'types/ts-migrate'
import { format } from 'date-fns'
import { sortByProps } from 'utils/array'
import { get } from 'lodash-es'

const SuspenseAccountsTable = ({
  searchQuery,
  activeFilterIndex,
  filters,
  openReference,
  currentReference,
}: any): React.ReactElement => {
  const [data, setData] = useState<$TSFixMe[]>([])
  const filter = get(filters, `[${[activeFilterIndex]}]`, {
    data: [],
  })

  useEffect(() => {
    const orderedTableData = pipe(
      rMap((account: any) => ({
        dateReceived: format(new Date(account.dateReceived), 't'),
        ...account,
      })),
      (list: any) => sortByProps(['dateReceived', 'referenceUsed', 'amount'], list),
    )(filter.data)

    setData(
      reverse(orderedTableData).map((account: $TSFixMe) => ({
        ...account,
        meta: {
          dateReceived: account.dateReceived,
          allocatedAt: account.allocatedAt,
          referenceUsed: account.referenceUsed,
          amount: account.amount.toString(),
        },
      })),
    )
  }, [filter.data])

  const refCol = {
    Header: 'Reference used',
    accessor: 'referenceUsed',
    style: {
      maxWidth: '250px',
    },
    sort: {
      enabled: true,
      order: false,
    },
  }

  const allocationCol = {
    Header: 'Allocation Details',
    accessor: 'allocatedToRef',
    style: {
      maxWidth: '250px',
    },
  }

  const dateCol = {
    Header: 'Date',
    accessor: 'dateReceived',
    style: {
      maxWidth: '250px',
    },
    sort: {
      enabled: true,
      order: false,
    },
  }

  const allocatedAtCol = {
    Header: 'Date',
    accessor: 'allocatedAt',
    style: {
      maxWidth: '250px',
    },
    sort: {
      enabled: true,
      order: false,
    },
  }

  const amountCol = {
    Header: 'Amount',
    accessor: 'amount',
    style: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '20px',
      maxWidth: '220px',
    },
    sort: {
      enabled: true,
      order: false,
    },
  }

  // Previous const columns = [{}]
  const columns =
    filter?.key === 'unallocated' ? [refCol, dateCol, amountCol] : [refCol, allocationCol, allocatedAtCol, amountCol]

  const allocatedRow = (ref: any): $TSFixMe => {
    return {
      ...ref,
      referenceUsed: (
        <span>
          <strong>{ref.referenceUsed}</strong>
          <small>Transaction ID: {ref.txId.substr(0, 9)}</small>
        </span>
      ),
      allocatedAt: (
        <div>
          <strong>Allocated: {formatStandard(ref.allocatedAt)} </strong>
          <br />
          <small>Recieved: {formatStandard(ref.dateReceived)}</small>
        </div>
      ),
      amount: <CurrencyText>{ref.amount}</CurrencyText>,
      allocatedToRef: (
        <div>
          <strong>To: {ref.allocatedToRef} </strong> <br />
          <small>By: {ref.allocatedBy} </small>
        </div>
      ),
      highlighted: currentReference?.txId === ref.txId,
    }
  }

  const isLoading = useSelector(state =>
    apiStateSelectors.isLoading(state)([
      walletApiEvents.unallocatedSuspenseAccountss_request,
      walletApiEvents.allocatedSuspenseAccounts_request,
    ]),
  )

  const unAllocatedRow = (ref: any): $TSFixMe => {
    return {
      ...ref,
      referenceUsed: (
        <span>
          <strong>{ref.referenceUsed}</strong>
          <small>Transaction ID: {ref.txId.substr(0, 9)}</small>
        </span>
      ),
      dateReceived: formatStandard(ref.dateReceived),
      amount: <CurrencyText>{ref.amount}</CurrencyText>,
      highlighted: currentReference?.txId === ref.txId,
    }
  }

  const buildRow = (ref: any): $TSFixMe => (filter?.key === 'unallocated' ? unAllocatedRow(ref) : allocatedRow(ref))

  const filteredData = useMemo(() => {
    return data.filter((inv: any) => stringUtils.jsonStringSearch(searchQuery, inv))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const tableData = searchQuery ? rMap(buildRow, filteredData) : rMap(buildRow, data)

  return (
    <div className={styles.root}>
      <Header icon={<SettingIcon />} text="Suspense Account" />
      {isLoading ? (
        <Loader />
      ) : (
        <AggregateTable
          rowHeight={80}
          columns={columns}
          data={tableData}
          interactive={true}
          onRowClick={row => openReference(row.txId)}
        />
      )}
    </div>
  )
}

SuspenseAccountsTable.propTypes = {}

export default subscribe(
  [SearchFilterProvider, SuspenseAccountsProvider],
  ({ searchQuery, activeFilterIndex }: any, { filters, openReference, currentReference }: any) => ({
    searchQuery,
    activeFilterIndex,
    filters,
    openReference,
  }),
)(SuspenseAccountsTable)
